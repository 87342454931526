import React, { useState, useEffect } from 'react';
import HeaderComponent from './header.component';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Icon
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import CreateIcon from '@material-ui/icons/Create';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ForwardIcon from '@material-ui/icons/Forward';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PrintDisabledIcon from '@material-ui/icons/PrintDisabled';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import FooterComponent from './footer.component';
import { analytics } from '../firebase';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '8vh',
    backgroundColor: fade(theme.palette.primary.main, 0.15)
  },
  paper1: {
    width: '90%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: fade(theme.palette.primary.main,0.75)
  },
  paper: {
    width: '90%',
    margin: theme.spacing(2),
    padding: theme.spacing(5)
  },
  descContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    flex: 2
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5vw',
    marginRight: '5vw',
    flex: 1
  },
  icons: {
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
    fontSize: theme.spacing(10)
  },
  contact: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: '8vw'
  },
  para: {
    fontSize: '3vw'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    alignSelf: 'center'
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1)
  },
  footer: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerLinks: {
    textDecoration: 'none',
    color: 'white',
    margin: theme.spacing(1)
  },
  rights: {
    margin: theme.spacing(1)
  },
  header1: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    letterSpacing: '0.1em'
  },
  subheader: {
    width: '100%',
    textAlign: 'center',
    color: "#FFFFFF",
    marginTop: theme.spacing(2),
    textShadow: '1px 1px lightgrey'
  },
  header2: {
    color: theme.palette.primary.main,
    alignSelf: 'flex-start'
  }
}))

const HomePage = props => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ confirmMessage, setConfirmMessage ] = useState('');
  const [ purpose, setPurpose ] = useState("");

  analytics.logEvent('homepage_visit');

  useEffect(() => {
    document.getElementById('inquiry').style.display = 'initial';
  },[])

  return (
    <div className={classes.root}>
      <Grid
        container
        direction='column'
        justify="center"
        alignItems="center"
      >
        <HeaderComponent title="Home" />

        <Paper className={classes.paper1} elevation={5}>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h2"
              className={classes.header1}
            >
              Digitize and Automate the workflow of all your approval forms.
            </Typography>

            <Typography
              variant="h4"
              className={classes.subheader}
            >
              Designed to improve internal approval forms processing.
            </Typography>
          </Grid>
        </Paper>

        <Paper
          className={classes.paper}
          elevation={5}
        >

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <div className={classes.descContainer}>
              <Typography variant="h2"
                className={classes.header2}
              >Customize Your Forms</Typography>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Easily create customized forms with our form builder.
                </Typography>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Assign approvers to your forms.
                </Typography>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Decide who has access to the forms.
                </Typography>
              </ListItem>
            </div>

            <div className={classes.iconContainer}>
              <CreateIcon className={classes.icons} />
              <DescriptionIcon className={classes.icons}/>
            </div>

          </Grid>

        </Paper>

        <Paper className={classes.paper} elevation={5}>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >

            <div className={classes.descContainer}>

              <Typography variant="h2"
                className={classes.header2}
              >Simplified Approval Process</Typography>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Add approvers to forms based on the order of your approval workflow.
                </Typography>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Approvers receive an email when it is their turn to take action on a request.
                </Typography>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Requester receives real-time updates when actions are taken by approvers.
                </Typography>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Manual approver entry is always an option.
                </Typography>
              </ListItem>

            </div>

            <div className={classes.iconContainer}>
              <ForwardIcon className={classes.icons} />
              <ThumbUpIcon className={classes.icons} />
            </div>

          </Grid>

        </Paper>

        <Paper className={classes.paper} elevation={5}>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >

            <div className={classes.descContainer} >

              <Typography variant="h2"
                className={classes.header2}
              >Save Time and Money</Typography>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Access and Review all available forms.
                </Typography>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  All of your requests are in one place and can be searchable for review.
                </Typography>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Let approvers and requesters do the tracking.
                </Typography>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Minimize printing cost.
                </Typography>
              </ListItem>

            </div>

            <div className={classes.iconContainer}>
              <PrintDisabledIcon className={classes.icons} />
              <ScheduleIcon className={classes.icons} />
              <AttachMoneyIcon className={classes.icons} />
            </div>

          </Grid>

        </Paper>

        <Paper className={classes.paper} elevation={5}>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >

            <div className={classes.descContainer}>

              <Typography variant="h2"
                className={classes.header2}
              >Secure Forms</Typography>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  Access is based on your domain and users you upload.
                </Typography>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  You decide the groups that have access to your forms.
                </Typography>
              </ListItem>

              <ListItem button>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="h5">
                  You decide your approvers.
                </Typography>
              </ListItem>

            </div>

            <div className={classes.iconContainer}>
              <VpnKeyIcon className={classes.icons} />
              <EnhancedEncryptionIcon className={classes.icons} />
              <VerifiedUserIcon className={classes.icons} />
            </div>

          </Grid>

        </Paper>

      </Grid>
      <FooterComponent />
    </div>
  )
}

export default HomePage
