import React, { useEffect, useState, useContext } from 'react';
import {
  CircularProgress,
  Button,
  Select,
  MenuItem,
  TextField,
  AppBar,
  Tabs,
  Tab,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MainContext from '../../state/main.context';
import { firebaseApp } from '../../../firebase';
import BackDrop from '../../backdrop.component';
import CreateFormComponent from './create.form.component';
import AssignFormsComponent from './assign.forms.component';
import AdminSubmitFormsComponent from '../adminSubmit.forms.component';
import IncompleteFormsComponent from './incomplete.forms.component';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formBtns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }

}))

const GroupFormsComponent = props => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(false);
  const [ tab, setValue ] = useState(2);

  return (
    <div className={classes.root}>

      <Paper className={classes.formBtns} square>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          onChange={(event,newValue) => setValue(newValue) }
        >
          <Tab label="Configure Form" />
          <Tab label="Create Form" />
          <Tab label="Submit Form" />
          <Tab label="Incomplete Forms" />
        </Tabs>
      </Paper>

      {
        tab === 0 && (
          <AssignFormsComponent />
        )
      }


      {
        tab === 1 && (
          <CreateFormComponent />
        )
      }


      {
        (tab === 2 && mainState.authUser) && (
          <AdminSubmitFormsComponent
            user={mainState.authUser}
          />
        )
      }

      {
        (tab === 3 && mainState.authUser) && (
          <IncompleteFormsComponent
            user={mainState.authUser}
          />
        )
      }

      <BackDrop loading={loading} />
    </div>
  )
}

export default GroupFormsComponent
