import React, { useEffect, useContext, useState } from 'react';
import {
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import MainContext from './state/main.context';
import { SignOut } from './utilities/firebase.actions';

const useStyles = makeStyles( theme => ({
  footer: {
    bottom: '0px',
    width: '100%',
    padding: theme.spacing(5),
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  footerTop: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerLinks: {
    textDecoration: 'none',
    color: 'white',
    margin: theme.spacing(1)
  },
  rights: {
    margin: theme.spacing(1)
  },
  containers: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(3),
    maxWidth: 500,
    minWidth: 400
  },
  footerBottom: {
    textAlign: 'center'
  }
}))


const FooterComponent = ({ title }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper className={classes.footer} elevation={5}>

      <div className={classes.footerTop}>

        <div className={classes.containers}>
          <Typography>Approve iT, LLC</Typography>
          <Typography>P.O. Box 1102</Typography>
          <Typography>Streamwood, IL 60107</Typography>
        </div>

        <div className={classes.containers}>

          <Typography
            style={{cursor: 'pointer'}}
            onClick={() => history.push('/legal/privacy')}
          >Privacy Policy</Typography>


          <Typography
            style={{cursor: 'pointer'}}
            onClick={() => history.push('/legal/terms')}
          >Terms and Conditions</Typography>

          <Typography
            style={{cursor: 'pointer'}}
            onClick={() => history.push('/contact-us')}
          >Contact Us</Typography>

        </div>

      </div>

      <div className={classes.footerBottom}>
        <Typography className={classes.rights}>All Rights Reserved to Approve iT, LLC 2020 { new Date().getUTCFullYear() > 2020 ? " - " + new Date().getUTCFullYear() : null}</Typography>
      </div>


    </Paper>
  )
}

export default FooterComponent;
