import React, { useEffect, useState, useContext } from 'react';
import { firebaseApp } from '../../firebase';

import MainContext from '../state/main.context';
import BackDrop from '../backdrop.component';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import MaterialTable from "material-table";
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const OrgsComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(true);
  const [ orgs, setOrgs ] = useState([]);

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    let domainsListener = firebaseApp.firestore().collection('domains')
      .onSnapshot(snapshot => {
        let arr = [];
        if(snapshot.size > 0) {
          snapshot.forEach(snap => arr.push(snap.data()))
        }
        setOrgs(arr);
        setLoading(false);
      });

  },[])

  const updateDomainStatus = async (domainData) => {
    let domainRef = firebaseApp.firestore().collection('domains').doc(domainData.domain)
    let res = await domainRef.update({
      active:!domainData.active
    });
    console.dir(res);
  }

  const handleSubscriptionChange = async (domainData) => {
    let domainRef = firebaseApp.firestore().collection('domains').doc(domainData.domain)
    let res = await domainRef.update({
      subscription: domainData.subscription === 'premium' ? 'basic' : 'premium'
    });
    console.dir(res);
  }

  const columns = [
    {
      title: 'Organization',
      field: 'orgName',
      render: rowData => <p>{ rowData.orgName.toUpperCase() }</p>
    },
    {
      title: 'Domain',
      field: 'domain',
      render: rowData => <p>{ rowData.domain.toUpperCase() }</p>
    },{
      title: "Active",
      render: rowData => {
        return (
          <Switch
            color='secondary'
            checked={rowData.active}
            onClick={e => updateDomainStatus(rowData)}
          />
        )
      }
    },{
      title: 'Basic',
      render: rowData => (
        <Radio
          checked={rowData.subscription === "basic"}
          onChange={() => handleSubscriptionChange(rowData)}
        />
      )
    },{
      title: 'Premium',
      render: rowData => (
        <Radio
          checked={rowData.subscription === "premium"}
          onChange={() => handleSubscriptionChange(rowData)}
        />
      )
    }
  ];

  return (
    <div className={classes.root}>
      <h1> Organizations List</h1>

      <MaterialTable
        title="Organizations"
        columns={columns}
        data={orgs}
        style={{
          width: window.innerWidth * 0.6
        }}
        pageSize={10}
      />

      <BackDrop loading={loading} />
    </div>
  )
}

export default OrgsComponent
