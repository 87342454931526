import React, { useEffect, useState, useContext } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

const ErroDialog = ({ message, toOpen }) => {

  const [ loading, setLoading ] = useState(false);
  const [ open, setOpen ] = useState(toOpen);

  useEffect(() => {
    if(toOpen) {
      setOpen(toOpen);
    }
  },[ toOpen ])

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">ERROR</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { message }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ErroDialog
