import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Select,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  InputLabel,
  Switch,
  IconButton
} from '@material-ui/core';
import {
  ArrowDropUp,
  ArrowDropDown
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2)
  },
  divCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    width: '100%'
    // margin: theme.spacing(2)
  },
  form:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  item: {
    margin: theme.spacing(1)
  },
  removeBtn: {
    margin: theme.spacing(0.5)
  },
  actionBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  }

}))

const ExpenseInput = ({ elem, addTitle, removeInput, setRequired, moveItem }) => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ quantity, setQuantity ] = useState(0);
  const [ cost, setCost ] = useState(0);
  const [ total, setTotal ] = useState(cost*quantity);

  return (
    <div className={classes.mainDiv}>
      <div className={classes.divCont}>
        <TextField
          onChange={e => addTitle(elem._id, e.target.value)}
          type="text"
          label="Enter Title/Question"
          variant='outlined'
          defaultValue={elem.title}
        />
        <form className={classes.form} onSubmit={e => e.preventDefault()}>
          <TextField
            type='number'
            label='Quantity'
            className={classes.item}
          />

          <FormLabel
          className={classes.item}>X</FormLabel>

          <TextField
            type='number'
            label='Cost'
            className={classes.item}
          />

          <FormLabel
          className={classes.item}>=</FormLabel>
          <FormLabel
          className={classes.item}>{ total }</FormLabel>
        </form>
        <FormControlLabel
          control={
            <Switch
              checked={elem.required}
              onChange={e => setRequired(elem._id,!elem.required)}
            />
          }
          label="Required"
        />
      </div>

      <div className={classes.actionBtns}>

        <IconButton
          variant='contained'
          className={classes.removeBtn}
          onClick={() => moveItem(elem._id,'up')}
        >
          <ArrowDropUp />
        </IconButton>
        <Button
          variant='contained'
          color='secondary'
          className={classes.removeBtn}
          onClick={() => removeInput(elem._id)}
        >
        Remove
        </Button>
        <IconButton
          variant='contained'
          className={classes.removeBtn}
          onClick={() => moveItem(elem._id,'down')}
        >
          <ArrowDropDown />
        </IconButton>

      </div>
    </div>
  )
}

export default ExpenseInput;
