import React, { useState, useContext } from 'react';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { firebaseApp } from '../../firebase';

import MainContext from '../state/main.context';
import BackDrop from '../backdrop.component';

const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
  },
  paper: {
    marginBottom: theme.spacing(5),
    padding: '20px'
  },
  button: {
    margin: theme.spacing(1),
    width: '45vw',
    alignSelf: 'center'
  }
}))

const GroupFormComponent = props => {
  const context = useContext(MainContext);
  const classes = useStyles();
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showError, setShowError ] = useState(false);
  const [ response, setResponse ] = useState('');
  const [ showDialog, setShowDialog ] = useState(false);
  const [ dialogTitle, setDialogTitle ] = useState('');
  const [ dialogMessage, setDialogMessage ] = useState('');

  const initShowResponse = (resType) => {
    switch(resType) {
      case 'success':

        setShowSuccess(true);

        setTimeout(() => {
          setShowSuccess(false);
        },3000);

        break;

      case 'error':

        setShowError(true);

        setTimeout(() => {
          setShowError(false);
        },3000);

        break;

        default:
          return;
    }
  }

  const submit = (e) => {
    e.preventDefault();
    e.persist();

    let target = e.target;
    let groupName = (target[0].value).toLowerCase();
    let domain = mainState.authUser.email.split('@')[1];

    mainDispatch({ type: 'setLoading', payload: true });
    setLoading(true);

    firebaseApp.firestore().collection('domains').doc(domain)
      .collection('groups').doc(groupName)
      .set({
      createdAt: new Date().getTime(),
      groupName: groupName,
      domain: domain,
      admins:[],
      users:[],
      status: 'active'
    })
    .then(() => {
      mainDispatch({ type: 'setLoading', payload: false });
      setLoading(false);
    })
    .catch(err => {
      setResponse(err.message);
      mainDispatch({ type: 'setLoading', payload: false });
      setLoading(false);
      initShowResponse('error');
    })

    e.target.reset();

  }

  const handleClose = () => {
    setShowDialog(false);
    setDialogMessage('');
    setDialogTitle('');
  }

  return (
    <div>

      <div className={classes.formContainer}>
        <Paper className={classes.paper}>
        <Typography className={classes.title}>
          Add New Group/School
        </Typography>
        <form onSubmit={ (event)=> submit(event) } className={classes.form}>

          <TextField
            id='group_domain'
            label='Group/School Name'
            type='text'
            margin='normal'
            required={true}
          />

          <Button
            type='submit'
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={mainState.isLoading}
          >
            Submit
          </Button>
          {
            showError ?
              <p style={{
                textAlign: 'center',
                color: 'red'
              }}>{response}</p>
            : null
          }
          {
            showSuccess ?
              <p style={{
                textAlign: 'center',
                color: 'green'
              }}>{response}</p>
            : null
          }
        </form>
        </Paper>
      </div>

      <Dialog onClose={handleClose} open={showDialog} className={classes.dialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText >
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className={classes.dialogBtn}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <BackDrop loading={loading} />
    </div>
  )
}

export default GroupFormComponent
