import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Typography,
  Paper,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormGroup,
  Divider,
  Modal
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from "material-table";
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import moment from 'moment';

import CustomHeader from '../header.component';
import BackDrop from '../backdrop.component';
import ConfirmDialog from '../shared_components/confirm.dialog.component';
import ErrorDialog from '../shared_components/error.dialog.component';
import EditSubmittedFormsComponent from './edit.submitted.forms.component';
import LoginNoRedirect from '../login_no_redirect.component';
import Snackbar from '@material-ui/core/Snackbar';

import { firebaseApp } from '../../firebase';
import MainContext from '../state/main.context';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10vh',
    paddingBottom: '15vh'
  },
  printRef: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    border: '1px solid grey',
    padding: theme.spacing(2)
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  forms: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '10vh'
  },
  form1: {
    flexGrow:1,
    border: '1px solid grey',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    // minWidth: '300px',
    maxWidth: '700px',
    margin: theme.spacing(1)
  },
  form2: {
    flexGrow:1,
    border: '1px solid grey',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    // minWidth: '300px',
    maxWidth: '700px',
    margin: theme.spacing(1)
  },
  label: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  selectItem: {
    padding: theme.spacing(1)
  },
  textAreaItem: {
    padding: theme.spacing(1)
  },
  formItem: {
    margin: theme.spacing(2)
  },
  approveBtn: {
    marginBottom: theme.spacing(1)
  },
  formResponse: {
    paddingLeft: theme.spacing(2)
  },
  adminInput: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1)
  },
  submitBtn: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.main,
    color: '#ffffff'
  },
  submitBtnAll: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff'
  },
  textField: {
    marginBottom: theme.spacing(2.5)
  },
  paper: {
    marginTop: theme.spacing(2),
    width: '90%',
    padding: theme.spacing(3)
  },
  formPaperRecipient: {
    display: 'flex',
    width: '80%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  recipientForm: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    border: '1px solid black'
  },
  textCentered: {
    textAlign: 'center'
  },
  recipientBtn: {
    margin: theme.spacing(1)
  },
  item: {
    width: '80%'
  },
  adminInfo: {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    margin: theme.spacing(2),
    padding: theme.spacing(1)
  },
  editModal: {
    margin: '0 auto',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
}))

const ApprovalPageComponent = props => {
  const printRef = useRef();
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState } = context;

  let { domain, formRequest, uid } = params;

  const [ loading, setLoading ] = useState(true);
  const [ requestData, setRequestData ] = useState(null);
  const [ confirmMessage, setConfirmMessage] = useState('');
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ showError, setShowError ] = useState(false);
  const [ manualApproval, setManualApproval ] = useState(false);
  const [ nextRecipientRequired, setNextRecipientRequired ] = useState(false);
  const [ validRecipient, setValidRecipient ] = useState(false);
  const [ invalidRecipient, setInvalidRecipient ] = useState(false);
  const [ recipient, setRecipient ] = useState('')
  const [ editSubmission, setEdit ] = useState(false);
  const [ po, setPO ] = useState('');
  const [ account_number, setAccountNumber ] = useState('');
  const [ notes, setNotes ] = useState('');
  const [ open, setOpen ] = useState(false);

  useEffect(() => {

    if(!mainState.authUser) {
      console.log('NO USER.');
      setLoading(false);
      return;
    }

    const requestListener = firebaseApp.firestore()
    .collection('domains').doc(mainState.domain)
    .collection('formRequests').doc(formRequest)
    .onSnapshot(snapshot => {
      if(!snapshot.exists) {
        setLoading(false);
        alert('Form Request was not found.');
        return;
      }
      let data = snapshot.data();
      setRequestData(data);
      if(data.manualApproval) {
        setNextRecipientRequired(true);
      }
      setLoading(false);
    }, error => {
      console.dir(error);
      setLoading(false);
      alert('Request not found.');
    })

    return () => requestListener();

  },[ mainState.authUser ]);

  const initShowConfirm = () => {
    setShowConfirm(true);
    setTimeout(() => setShowConfirm(false),1000);
  }

  const initShowError = () => {
    setShowError(true);
    setTimeout(() => setShowError(false),1000);
  }

  const checkAuthUser = async () => {
    let currentUser = firebaseApp.auth().currentUser;
    console.dir(currentUser);
  }

  const RenderRequestData = () => (
    <div className={classes.paper}>
      <Typography
        style={{
          cursor: 'pointer'
        }}
        color='error'
        align='left'
        onClick={() => setEdit(true)}
      >
        Edit
      </Typography>
      <Typography variant='h4' style={{ textAlign: 'center' }}>
        {requestData.formTitle}
      </Typography>

      <Typography variant='h5' style={{ textAlign: 'center' }}>
        by {requestData.requester.displayName}
      </Typography>

      <div
        className={classes.formItem}
      >
        <Typography variant="h6">Request Date</Typography>
        <Typography
          className={classes.formResponse}
        >{moment(new Date(requestData.requestDate)).format("dddd, MMMM Do YYYY, h:mm a")}</Typography>

      </div>

      {
        requestData.form.map(item => {
          switch(item.type) {
            case 'textarea':
            case 'text':
            case 'number':
            case 'radio':
            case 'select': {
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >{item.response}</Typography>

                </div>
              )
            }

            case 'file':
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <a href={item.response.url} target ="_blank">
                    <Typography
                      className={classes.formResponse}
                    >
                    FILE
                    </Typography>
                  </a>

                </div>
              )

            case 'checkbox': {
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >
                    {
                      Object.keys(item.response).filter(key => item.response[key]).join(', ')
                    }
                  </Typography>

                </div>
              )
            }

            case 'date':{
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >Start: {moment(new Date(item.start)).format("dddd, MMM Do YYYY, h:mm a")}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >End: {moment(new Date(item.end)).format("dddd, MMM Do YYYY, h:mm a")}</Typography>

                </div>
              )
            }

            case 'address':{
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >{item.response.location}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >{item.response.street}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >{item.response.city}, {item.response.state} {item.response.zip}</Typography>

                </div>
              )
            }

            case 'expense':{
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >Quantity: {item.response.quantity}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >Cost: ${item.response.cost}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >Total: ${item.response.total}</Typography>

                </div>
              )
            }
          }

        })

      }

      <Divider />

      {
        (requestData.hasOwnProperty('administrativeInfo') &&
          Object.keys(requestData.administrativeInfo).length > 0) && (
            <div className={classes.adminInfo}>
            {
              Object.keys(requestData.administrativeInfo).map(key => {

                return (
                  <Typography key={key}>
                    { key.toUpperCase()}: {requestData.administrativeInfo[key] }
                  </Typography>
                )

              })
            }
            </div>

          )
      }

    </div>
  )

  const initGetRecipient = async (e) => {

    e.preventDefault();
    e.persist();

    if(!recipient) {
      return;
    }

    let options = {
      method: 'Post',
      headers: {
        Authorization: uid
      },
      body: JSON.stringify({
        email: recipient,
        domain: domain,
        formRequest: formRequest
      })
    }

    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/getUserInfo";
    setLoading(true);

    try {

      let initGetUserInfo = await fetch(url,options);

      if(initGetUserInfo.ok) {

        setValidRecipient(true);
        setLoading(false);

        return;
      }

      setErrorMessage('There was an error retrieving user information.  Please check that you entered a valid email address or contact support.');
      setInvalidRecipient(true);
      setLoading(false);
      initShowError();
    } catch (e) {

      console.log('ERROR',e);

      setErrorMessage('There was an error retrieving user information.  Please check that you entered a valid email address or contact support.');
      setInvalidRecipient(true);
      setLoading(false);
      initShowError();
    }
  }

  const initSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    //  APPROVER UID SHOULD MATCH PARAMS UID
    if(uid !== mainState.authUser.uid) {
      alert("You do not have approval permission for this request.");
      return;
    }

    let approval = e.target[0].value;
    let comments = e.target[1].value;

    let formRequestRef = firebaseApp.firestore()
    .collection('domains').doc(mainState.domain)
    .collection('formRequests').doc(formRequest);

    setLoading(true);

    try {

      await firebaseApp.firestore().runTransaction(async (t) => {

        const doc = await t.get(formRequestRef);

        let approvers = doc.data().approvers;

        approvers.some(approver => {
          if(approver.uid === mainState.authUser.uid) {
            approver.actionDate = new Date().getTime();
            approver.comment = comments;
            approver.status = approval.toLowerCase();
            return true;
          }
          return false;
        })

        t.update(formRequestRef,{ approvers: approvers });

      });
      setLoading(false);
      setOpen(true);
      console.log('Approval Complete.');
      e.target.reset();
    } catch (e) {
      setLoading(false);
      console.dir(e);
      e.target.reset();
      alert('There was an error.  Please try again later.');
    }

  }

  const resetAdminForm = () => {
    document.getElementById('po').value = "";
    document.getElementById('notes').value = "";
    document.getElementById('account-number').value = "";
  }

  const submitAdministrativeData = async (key) => {

    //  APPROVER UID SHOULD MATCH PARAMS UID
    if(uid !== mainState.authUser.uid) {
      alert("You do not have approval permission for this request.");
      return;
    }

    setLoading(true);

    let val = '';

    switch(key) {
      case 'po':
        val = po;
        break;
      case 'account_number':
        val = account_number;
        break;
      case 'notes':
        val = notes;
        break;
      default:
    }

    if(!val) {
      setLoading(false);
      alert('You must enter a value.');
      return;
    }


    let formRequestRef = firebaseApp.firestore()
    .collection('domains').doc(mainState.domain)
    .collection('formRequests').doc(formRequest);

    try {

      await formRequestRef.update({
        [`administrativeInfo.${key}`]: val
      })

      setLoading(false);
      resetAdminForm();
      setOpen(true);
    } catch (e) {
      setLoading(false);
      console.dir(e);
      alert('There was an error.  Please try again later.');
    }



  }

  const submitAdministrativeDataAll = async () => {

    //  APPROVER UID SHOULD MATCH PARAMS UID
    if(uid !== mainState.authUser.uid) {
      alert("You do not have approval permission for this request.");
      return;
    }

    if(!po || !account_number || !notes) {
      alert("One or more fields are empty.  Please update individually instead.");
      return;
    }

    setLoading(true);

    let formRequestRef = firebaseApp.firestore()
    .collection('domains').doc(mainState.domain)
    .collection('formRequests').doc(formRequest);

    try {

      await formRequestRef.update({
        administrativeInfo: {
          po: po,
          account_number: account_number,
          notes: notes
        }
      })

      setLoading(false);
      resetAdminForm();
      setOpen(true);
    } catch (e) {
      setLoading(false);
      console.dir(e);
      alert('There was an error.  Please try again later.');
    }



  }

  const editSuccessfull = () => {
    setEdit(false);
    setLoading(true);
  }

  const columns = [
    {
      title: 'Admin',
      field: 'displayName'
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData => <p>{ rowData.status.toUpperCase() }</p>
    },
    {
      title: 'Comments',
      field: 'comment',
      render: rowData => <p>{

        (rowData.hasOwnProperty('comment')) ?
          rowData.comment.toUpperCase()
        : ''

      }</p>
    },
    {
      title: 'Action Date',
      field: 'actionDate',
      render: rowData => <p>{
        (rowData.hasOwnProperty('actionDate') && rowData.actionDate) ?
            moment(rowData.actionDate).format("M-D-YY h:mm a")
          : ''
      }</p>
    }

  ]

  if(!mainState.authUser) {
    return(
      <LoginNoRedirect />
    )
  }

  return (
    <div className={classes.root} ref={printRef}>
      <CustomHeader title='Request Approval' />

    <div
      className={classes.printRef}

    >

    <ReactToPrint
      trigger={() =>
        <PrintIcon
          style={{
            marginTop: '25px',
            right: '25px',
            cursor: 'pointer'
          }}
        />
      }
      content={ () => printRef.current }
    />

    {
      requestData && (
        <RenderRequestData />
      )
    }

    <MaterialTable
      title="Request Status"
      data={requestData ? requestData.approvers : []}
      columns={columns}
      style={{
        width: '90%',
        alignSelf: 'center'
      }}
      pageSize={requestData ? requestData.approvers.length : 5}
      options={{
        search: false,
        showFirstLastPageButtons: false,
        paging: false
      }}
    />

    {
      ( requestData && requestData.manualApproval && nextRecipientRequired) && (
        <Paper className={classes.formPaperRecipient}>
          <Typography className={classes.textCentered}>
            <span style={{color: 'red'}}>IMPORTANT</span>:  Please enter the email of the person that is in charge of the next step of the approval process.  If there is none, then click on the Not Needed button.
          </Typography>
          <form
            onSubmit={initGetRecipient}
            className={classes.recipientForm}
          >

            <TextField
              autoFocus
              label="Enter Email of Next Approver"
              defaultValue={recipient}
              variant="outlined"
              className={classes.item}
              onChange={e => setRecipient(e.target.value) }
              error={invalidRecipient ? true : false}
              helperText={invalidRecipient ? "Email is not valid." : validRecipient ? "Email is valid." : ''}
              required
            >
            </TextField>
            <Button
              color='primary'
              className={classes.recipientBtn}
              type='submit'
            >
              Submit Approver
            </Button>

            <Button
              color='secondary'
              className={classes.recipientBtn}
              onClick={() => {
                setNextRecipientRequired(false);
              }}
            >
              Not Needed
            </Button>

          </form>
        </Paper>
      )
    }

    <div className={classes.forms}>

      <form
        className={classes.form1}
        onSubmit={initSubmit}
      >
        <label htmlFor='decision' className={classes.label}>Select Decision</label>
        <select
          id='decision' className={classes.selectItem}
          disabled={nextRecipientRequired && !validRecipient}
          required
        >
          <option value=''>Select Option</option>
          <option>Approve</option>
          <option>Deny</option>
        </select>

        <br />

        <label htmlFor='comment' className={classes.label}>
          Please provide a comment especially if you are denying the request.
        </label>
        <textarea
          id='comment'
          placeholder='Enter Comment Here'
          rows={4}
          width='30vw'
          className={classes.textAreaItem}
          disabled={nextRecipientRequired && !validRecipient}
        ></textarea>

        <button
          className={classes.submitBtn}
          disabled={nextRecipientRequired && !validRecipient}
        >Submit Decision</button>
      </form>

      <div
        className={classes.form2}
      >
        <Typography className={classes.formTitle}>
          Internal Administrative Information (optional)
        </Typography>

        <label htmlFor='po' className={classes.label}>
          P.O. Number
        </label>
        <input
          className={classes.adminInput}
          type='text' id='po' placeholder='PO#'
          onChange={(e) => setPO(e.target.value) }
        />

        <button
          className={classes.submitBtn}
          onClick={() => submitAdministrativeData('po')}
        >Add/Update PO</button>

        <label htmlFor='account-number' className={classes.label}>
          Account Number
        </label>
        <input
          className={classes.adminInput}
          type='text' id='account-number' placeholder='Acct#'
          onChange={(e) => setAccountNumber(e.target.value) }
        />

        <button
          className={classes.submitBtn}
          onClick={() => submitAdministrativeData('account_number')}
        >Add/Update Account #</button>

        <label htmlFor='notes' className={classes.label}>
          Notes
        </label>
        <textarea
          id='notes'
          placeholder='Enter Notes Here'
          rows={2}
          width='30vw'
          onChange={(e) => setNotes(e.target.value) }
          className={classes.textAreaItem}
        ></textarea>

        <button
          className={classes.submitBtn}
          onClick={() => submitAdministrativeData('notes')}
        >Add/Update Notes</button>

        <button
          className={classes.submitBtnAll}
          onClick={() => submitAdministrativeDataAll()}
        >Add/Update All</button>
      </div>

    </div>

    <Modal
      open={editSubmission}
      onClose={() => console.log('must cancel through form.')}
    >
      <div className={classes.editModal}>
      <Button
        onClick={() => setEdit(false)}
      >
        Cancel
      </Button>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <EditSubmittedFormsComponent
          uid={uid}
          domain={domain}
          formRequestId={formRequest}
          requestData={requestData}
          closeModal={setEdit}
          editSuccessfull={editSuccessfull}
        />
      </MuiPickersUtilsProvider>

      </div>

    </Modal>

    <Snackbar
       className={classes.snackbar}
       anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'left',
       }}
       open={open}
       onClose={() => {
         setOpen(false);
       }}
       autoHideDuration={5000}
       message="Update Successfull"
     />
    <ErrorDialog message={errorMessage} toOpen={showError} />
    <ConfirmDialog message={confirmMessage} toOpen={showConfirm} />
    <BackDrop loading={loading} />
    </div>

    </div>
  )
}

export default ApprovalPageComponent
