import React, { useEffect, useState, useContext } from 'react';
import {
  CircularProgress,
  Button,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Typography,
  Modal,
  Paper,
  IconButton,
  Divider,
  Switch,
  FormGroup
} from '@material-ui/core';
import {
  ArrowDropUp,
  ArrowDropDown
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { firebaseApp } from '../../../firebase';
import MainContext from '../../state/main.context';
import BackDrop from '../../backdrop.component';

const useStyles = makeStyles(theme => ({
  modalContent: {
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#ffffff'
  },
  textCentered: {
    textAlign: 'center'
  },
  formsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  adminsContainer: {
    flex: 4,
    border: '1px solid grey',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  finalRecipients: {
    flex: 2,
    border: '1px solid grey',
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  finalDesc: {
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2)
  },
  textField: {
    width: '80%',
  },
  textField2: {
    width: '80%',
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  addBtn: {
    width: '80%'
  },
  approvers: {
    width: '50%',
    alignSelf: 'center'
  },
  approverContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: theme.spacing(1)
  },
  removeBtn: {
    padding: theme.spacing(0.25)
  },
  actionBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(3)
  },
  notFound: {
    color: 'red',
    width: "60%",
    alignSelf: 'center',
    marginTop: theme.spacing(2)
  },
  boldUnderlined: {
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  saveBtn: {
    alignSelf: 'center'
  }
}))

const ConfigGroupComponent = ({group,form}) => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState } = context;
  const [ loading, setLoading ] = useState(true);
  const [ approvers, setApprovers ] = useState([]);
  const [ finalRecipients, setFinalRecipients ] = useState([]);
  const [ processing, setProcessing ] = useState(false);
  const [ notFound, setNotFound ] = useState(false);
  const [ formConfig, setFormConfig ] = useState(null);
  const [ manualApproval, setManualApproval ] = useState(false);

  useEffect(() => {
    getFormConfig();
  },[])

  if(!group || !form) {
    return (
      <Paper className={classes.modalContent}>
        <Typography
          variant='h2'
          className={classes.textCentered}
        >Group/Form Not Selected</Typography>

      </Paper>
    )
  }

  //  GET OR CREATE INITIAL FORM CONFIG
  const getFormConfig = () => {
    firebaseApp.firestore().collection('domains').doc(group.domain)
      .collection('groups').doc(group.groupName)
      .collection('forms').doc(form._id).get()
      .then(snapshot => {
        if(!snapshot.exists) {
          console.log('Form does not exist in group ' + group.groupName);
          //  CREATE INITIAL FORM CONFIG
          initFormConfig();
          return;
        }
        let tempData = snapshot.data();
        console.dir(tempData);
        setFormConfig(tempData);
        setApprovers([...tempData.approvers]);
        setFinalRecipients([...tempData.finalRecipients]);
        setManualApproval(tempData.manualApproval || false);
        setLoading(false);

      })
      .catch(err => {
        console.log('ERROR',err);
        setLoading(false);
      })
  }

  const initFormConfig = () => {

    firebaseApp.firestore().collection('forms').doc(form._id)
    .get().then(snapshot => {
      if(!snapshot.exists) {
        console.log('Form does not exist');
        setLoading(false);
        return;
      }
      //  CREATE FORM CONFIG OBJECT
      let tempConfig = {
        form: snapshot.data().form,
        title: snapshot.data().title,
        _id: form._id,
        configured: false,
        approvers: [],
        manualApproval: false,
        finalRecipients: []
      }

      //  SAVE INITIAL FORM CONFIG
      firebaseApp.firestore().collection('domains').doc(group.domain)
        .collection('groups').doc(group.groupName)
        .collection('forms').doc(form._id).set(tempConfig)
        .then(saveData => {
          setFormConfig(tempConfig);
          setApprovers([...tempConfig.approvers]);
          setFinalRecipients([...tempConfig.finalRecipients]);
          setLoading(false);
        })
        .catch(err => {
          console.log('ERROR',err);
          setLoading(false);
        })

    })
    .catch(err => {
      console.log('ERROR',err);
      setLoading(false);
    })
  }

  const initSetNotFound = () => {
    setNotFound(true);
    setTimeout(() => setNotFound(false), 10000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    setProcessing(true);
    let entries = (e.target[0].value).trim().split(',');
    var emails = entries.map(entry => entry.trim().toLowerCase()).filter(each => each);
    let domain = mainState.authUser.email.split('@')[1];

    //  REMOVE DUPLICATED ENTRIES
    approvers.forEach(approver => {
      if(emails.includes(approver.email)) {
        emails.splice(emails.indexOf(approver.email),1);
      }
    })

    if(emails.length <= 0) {
      setProcessing(false);
      return;
    }

    firebaseApp.firestore().collection('domains').doc(domain)
      .collection('users').where('email','in',emails).get()
      .then(snapShot => {
        let arr = [];
        let snapSize = snapShot.size;
        if(snapSize > 0) {
          snapShot.forEach(snap => {
            if(snap.data().role !== 'user') {
              let tempUser = snap.data();
              let user = {
                displayName: tempUser.displayName,
                email: tempUser.email,
                uid: tempUser.uid
              }
              arr.push(user);
            }
          })
          console.log(arr);
        }

        setApprovers([...approvers,...arr]);
        saveConfig([...approvers,...arr]);
        setProcessing(false);
        e.target.reset();
        //  INFORM USER IF ANY OF THE EMAILS WERE NOT FOUND
        if(arr.length < emails.length) {
          setTimeout(() => initSetNotFound(), 500);
        }
      })
      .catch(err => {
        console.log('ERROR',err);
        setProcessing(false);
        e.target.reset();
      })

  }

  const handleFinalRecipients = e => {
    e.preventDefault();
    e.persist();

    setLoading(true);

    let recips = (e.target[0].value).trim().split(',');
    var emails = recips.map(entry => entry.trim().toLowerCase()).filter(each => each);

    console.dir(emails);

    setFinalRecipients([ ...emails ]);

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('groups').doc(group.groupName)
      .collection('forms').doc(form._id).update({
        finalRecipients: emails
      })
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        setLoading(false);
      })

  }

  const initEnableManual = () => {

    setLoading(true);

    //  UPDATE MANUAL APPROVAL SETTINGS
    firebaseApp.firestore().collection('domains').doc(group.domain)
      .collection('groups').doc(group.groupName)
      .collection('forms').doc(form._id)
      .update({
        manualApproval: !manualApproval,
        configured: !manualApproval === true ? true : false
      })
      .then(() => {
        getFormConfig();
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      })
  }

  const saveConfig = (newApprovers=approvers) => {
    setProcessing(true);
    console.log('newApprovers',newApprovers);
    firebaseApp.firestore().collection('domains').doc(group.domain)
      .collection('groups').doc(group.groupName)
      .collection('forms').doc(form._id).update({
        approvers: newApprovers,
        configured: true
      })
      .then(() => {
        setProcessing(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        setProcessing(false);
      })
  }

  const moveItem = (direction,email) => {
    let tempApprovers = approvers;
    let size = tempApprovers.length;
    tempApprovers.some((user,i) => {
      if(user.email === email) {
        let currentLoc = i;
        let before = currentLoc - 1;
        let after = currentLoc + 1;

        console.log(currentLoc);

        switch(direction) {
          case 'up':
            //  CHECK IF ALREADY FIRST ITEM
            if(currentLoc === 0) {
              return;
            }

            let beforeItem = tempApprovers[before];
            tempApprovers[currentLoc] = beforeItem;
            tempApprovers[before] = user;
            break;

          case 'down':
            //  CHECK IF ALREADY LAST ITEM
            if(currentLoc === size -1) {
              return;
            }

            let afterItem = tempApprovers[after];
            tempApprovers[currentLoc] = afterItem;
            tempApprovers[after] = user;
            break;

          default:
            return;
        }


        return true;
      }
      return false;
    });

    setApprovers([...tempApprovers]);
    setTimeout(() => saveConfig(tempApprovers), 500);
  }

  const removeItem = email => {
    let tempApprovers = approvers;
    tempApprovers = tempApprovers.filter(user => user.email !== email);
    setApprovers(tempApprovers);
    setTimeout(() => saveConfig(tempApprovers), 500);
  }

  const Approvers = () => (
    <div className={classes.approvers}>
      <h3 style={{ textAlign: 'center' }}>Approvers</h3>
      <p>
        The list of approvers is the order that the approval process will be handled <span className={classes.boldUnderlined}>from top to bottom</span>.  To change the order, use the arrows to move approvers up and/or down.
      </p>
    {
      approvers.map(approver =>
        <div className={classes.approverContainer} key={approver.email}>

          <div className={classes.actionBtns}>

            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem('up',approver.email)}
            >
              <ArrowDropUp />
            </IconButton>
            <Button
              color='secondary'
              className={classes.removeBtn}
              onClick={() => removeItem(approver.email)}
            >
            Remove
            </Button>
            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem('down',approver.email)}
            >
              <ArrowDropDown />
            </IconButton>

          </div>

          <Typography>{approver.displayName}</Typography>

        </div>
      )
    }
    </div>
  )

  return (
    <Paper className={classes.modalContent}>

      <Typography variant='h4' className={classes.textCentered}>
        { form.title.toUpperCase() } APPROVAL
      </Typography>

      <Typography variant='h4' className={classes.textCentered}>
        CONFIGURATION FOR { group.groupName.toUpperCase() }
      </Typography>

      <div className={classes.formsContainer}>

        <div className={classes.adminsContainer}>

          <form onSubmit={handleSubmit} className={classes.form}>

            <TextField
              required
              label="Enter Email(s) for Admin Approver(s)"
              placeholder='Enter Email'
              variant="outlined"
              className={classes.textField2}
              helperText="NOTE:  For multiple emails, separate by comma."
              disabled={manualApproval}
            />
            <Button
              disabled={manualApproval}
              className={classes.addBtn}
              variant='contained'
              type='submit'
            >
              Add Admin(s)
            </Button>
          </form>

          { //  ONLY RENDER SWITCH IF THERE ARE NO APPROVERS
            approvers.length <= 0 && (
              <div>
              <FormGroup className={classes.form} row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={manualApproval}
                      onChange={initEnableManual}
                    />
                  }
                  label="Enable Manual Admin Entry"
                />
              </FormGroup>
              <Typography className={classes.textCentered}>
                NOTE:  Enabling entry for admins will force users to enter the email address of admin who should receive the form request.
              </Typography>
              </div>
            )
          }

          <Divider />

            {
              notFound ?
                <div className={classes.notFound}>
                  <Typography
                    // className={classes.notFound}
                    paragraph={true}
                  >
                    NOTICE:  One or more of the email addresses that you entered is not available.  It may be due to one or more of the following:
                  </Typography>
                  <Typography
                    // className={classes.notFound}
                    paragraph={true}
                  >
                    1.  User does not exist.
                  </Typography>
                  <Typography
                    // className={classes.notFound}
                    paragraph={true}
                  >
                    2.  User account is not classified as admin.
                  </Typography>
                </div>
              : null
            }

            {
                approvers.length > 0 && (
                  <Approvers />
                )
            }

          </div>

        <div className={classes.finalRecipients}>

          <form
            onSubmit={handleFinalRecipients}
            className={classes.form}
          >
            <Typography style={{ margin: '10px'}}>
              Final Recipients
            </Typography>

            <TextField
              className={classes.textField}
              label="Enter emails separated by comma."
              defaultValue={finalRecipients.join(',')}
              multiline
              rows={3}
              onChange={(e) => {
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Button
              color='primary'
              type='submit'
            >
              Update
            </Button>
          </form>

          <Typography className={classes.finalDesc}>
            The final recipients are users that will receive a copy of the email when this form is completely approved.  This is useful if you have secretaries or departments such as transportation or accounting that might need to take action based on the approval.
          </Typography>

        </div>

      </div>

      <BackDrop loading={processing || loading} />
    </Paper>
  )
}

export default ConfigGroupComponent
