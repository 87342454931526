import React, { useState } from 'react';
import {
  TextField,
  FormLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  divCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(2)
  },
  form:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  item: {
    margin: theme.spacing(1)
  },
  removeBtn: {
    marginLeft: theme.spacing(1)
  },
  label: {
    color: 'black'
  }

}))

const ExpenseHandler = ({ elem, handleResponse, toggleTyping }) => {
  const classes = useStyles();
  const [ obj, updateObj ] = useState(elem.response);

  const initUpdate = (key,val) => {
    let tempObj = obj;
    tempObj[key] = Number(val);
    tempObj.total = (Number(tempObj.quantity) * Number(tempObj.cost)).toFixed(2);
    updateObj(tempObj);
    handleResponse('expense',elem._id,tempObj);
  }

  const handleTextField = _.debounce((key,val) => {
    initUpdate(key,val);
  },500)

  return (
    <div className={classes.divCont}>

      <FormLabel className={classes.label}>
        {elem.title}
        { elem.required ? " *" : null }
      </FormLabel>

      <div className={classes.form} >
        <TextField
          type='number'
          label='Quantity'
          required={ elem.required }
          className={classes.item}
          defaultValue={Number(elem.response.quantity)}
          onChange={(e) => {
            toggleTyping();
            handleTextField('quantity',e.target.value)
          }}
        />

        <FormLabel
        className={classes.item}>X</FormLabel>

        <TextField
          type='number'
          inputProps={{
            step: "0.01"
          }}
          step={0.01}
          label='Cost'
          required={ elem.required }
          className={classes.item}
          defaultValue={Number(elem.response.cost).toFixed(2)}
          onChange={(e) => {
            toggleTyping();
            handleTextField('cost',e.target.value)
          }}
        />

        <FormLabel
        className={classes.item}>=</FormLabel>
        <FormLabel
        className={classes.item}>${ elem.response.total || 0.00 }</FormLabel>
      </div>
    </div>
  )
}

export default ExpenseHandler;
