import React, { useEffect, useState, useContext } from 'react';
import {
  CircularProgress,
  Button,
  Select,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import MainContext from '../../state/main.context';
import { firebaseApp } from '../../../firebase';
import GroupFormsComponent from './group.forms.component';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    // width: '30vw',
    marginLeft: theme.spacing(2)
  },
  configure: {
    display: 'flex',
    width: '80vw',
    alignItems: 'center'
  }
}))

const FormsConfigComponent = props => {

  const context = useContext(MainContext);
  let { mainState } = context
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ domain, setDomain ] = useState(null);
  const [ group, setGroup ] = useState(true);

  useEffect(() => {
    //  GET ALL SCHOOLS THAT THE ADMIN IS IN CHARGE OF
    if(!mainState.authUser) {
      return;
    }

  },[])

  useEffect(() => {
  },[ mainState, group ])

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.dir(e.target);

    e.target.childNodes.forEach(item => console.dir(item));

  }


  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <GroupFormsComponent />
      </MuiPickersUtilsProvider>
    </div>
  )

}

export default FormsConfigComponent
