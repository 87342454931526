import React, { useState, useEffect, useContext } from 'react';
import {
  Calendar,
  momentLocalizer
} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import MainContext from '../state/main.context';
import { firebaseApp } from '../../firebase';
import BackDrop from '../backdrop.component';

const localizer = momentLocalizer(moment)

/*
  {
    title: string,
    start: Date,
    end: Date,
    allDay: bool,
    resource?:  TRANSPORTATION USED
  }
*/


const CalendarComponent = props => {

  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(true);
  const [ domain, setDomain ] = useState(null);
  const [ groups, setGroups ] = useState([]);
  const [ requests, setRequests ] = useState([]);

  useEffect(() => {
    if(mainState.authUser){
      initComponent();
    }
  },[ mainState ])

  //  GET ALL REQUESTS WHEN DOMAIN EXISTS
  useEffect(() => {
    if(groups.length > 0) {

      const requestsListener = firebaseApp.firestore().collection('domains').doc(domain)
        .collection('formRequests').where('group','in',groups).onSnapshot(querySnap => {
         if(querySnap.size > 0) {
           let arr = [];
           querySnap.forEach(snap => {
             let tempData = snap.data();

             tempData.form.some(item => {
               if(item.type === 'date') {
                 let tempObj = {};
                 tempObj.start = new Date(item.start);
                 tempObj.end = new Date(item.end);
                 tempObj.allDay = item.allDay;
                 tempObj.title = tempData.formTitle + ' - ' + tempData.requester.displayName;
                 tempObj.request = tempData;
                 arr.push(tempObj);
                 return true;
               }
               return false;
             })

           });

           console.dir(arr);

           if(arr.length > 0) {
             setRequests([...arr]);
           }

         }
      })

      return;
    }

    setLoading(false);

  }, [ groups ]);

  useEffect(() => {
    if(requests.length > 0){
      console.dir(requests);
    }
  }, [ requests ]);

  const initComponent = () => {

    setDomain(mainState.authUser.email.split('@')[1]);

    //  SET GROUPS
    if(mainState.hasOwnProperty('groups') && mainState.groups.length > 0){
      let tempGroups = mainState.groups.reduce((acc,curr) => {
        console.log('curr: ', curr);
        console.log('acc',acc);
        acc.push(curr.groupName);
        console.log('acc after',acc);
        return acc;
      },[]);
      setGroups([...tempGroups]);

      return;
    }

    setLoading(false);

  }

  const handleSelection = e => {
    console.dir(e);
  }

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={requests}
        startAccessor="start"
        endAccessor="end"
        style={{
          marginTop: '10vh',
          marginLeft: '5vw',
          width: '80vw',
          height: '80vh'
        }}
        onSelectEvent={handleSelection}
      />

      <BackDrop loading={loading} />
    </div>
  )
}

export default CalendarComponent
