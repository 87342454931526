import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { firebaseApp, provider } from '../firebase';

import CustomHeader from './header.component';
import BackDrop from './backdrop.component';
import ConfirmDialog from './shared_components/confirm.dialog.component';
import ErrorDialog from './shared_components/error.dialog.component';
import PricingInquiryComponent from './inquiry_forms/pricing_inquiry.component';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '1.5em',
    margin: theme.spacing(2),
    textAlign: 'center'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
  },
  paper: {
    marginTop: '10vh',
    padding: '20px'
  },
  button: {
    margin: theme.spacing(1),
    width: '45vw',
    alignSelf: 'center'
  },
  dialog: {
    padding: theme.spacing(3)
  },
  dialogMessage: {
    margin: theme.spacing(2)
  },
  dialogBtn: {
    margin: theme.spacing(2)
  },
  googleBtn: {
    height: '100px',
    margin: theme.spacing(5),
    cursor: 'pointer'
  }
}))

const PricingComponent = props => {
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();
  let { email } = params;

  const [ loading, setLoading ] = useState(false);
  const [ pass1, setPass1 ] = useState(null);
  const [ pass2, setPass2 ] = useState(null);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showDialog, setShowDialog ] = useState(false);
  const [ dialogTitle, setDialogTitle ] = useState('Hello');
  const [ dialogMessage, setDialogMessage ] = useState('This is a message.');
  const [ showError, setShowError ] = useState(false);
  const [ response, setResponse ] = useState('');

  useEffect(() => {},[showDialog])

  useEffect(() => {
    document.getElementById('inquiry').style.display = 'none';
  },[])

  return (
    <div>

    <CustomHeader title='Pricing' />


    <PricingInquiryComponent />


    <BackDrop loading={loading} />
    <ConfirmDialog message={dialogMessage} toOpen={showSuccess} />
    <ErrorDialog message={dialogMessage} toOpen={showError} />
    </div>
  )
}

export default PricingComponent;
