import React, { useEffect, useState, useContext } from 'react';
import {
  CircularProgress,
  Button,
  Select,
  MenuItem,
  TextField,
  Paper,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MainContext from '../state/main.context';
import { firebaseApp } from '../../firebase';
import BackDrop from '../backdrop.component';
import SubmitFormsComponent from './submit.forms.component';
import IncompleteFormsComponent from './incomplete.forms.component';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '15vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formBtns: {
    display: 'flex',
    flexDirection: 'row'
  }

}))

const GroupFormsComponent = props => {
  let { userData } = props;
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(false);
  const [ tab, setValue ] = useState(0);

  return (
    <div className={classes.root}>

      <Paper className={classes.formBtns} square>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          onChange={(event,newValue) => setValue(newValue) }
        >
          <Tab label="Submit Form" />
          <Tab label="Incomplete Forms" />
        </Tabs>
      </Paper>

      {
        (tab === 0 && mainState.authUser) && (
          <SubmitFormsComponent
            user={userData}
          />
        )
      }

      {
        tab === 1 && (
          <IncompleteFormsComponent
            user={userData}
          />
        )
      }


      <BackDrop loading={loading} />
    </div>
  )
}

export default GroupFormsComponent
