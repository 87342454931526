import React, { useEffect, useState, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Paper,
  Modal,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import { firebaseApp } from '../../firebase';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable from 'material-table';
import InputHandler from './user_forms/inputs.handler.component';
import ErrorDialog from '../shared_components/error.dialog.component';
import ConfirmDialog from '../shared_components/confirm.dialog.component';
import BackDrop from '../backdrop.component';
import MainContext from '../state/main.context';


const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '5vh',
    width: '80vw',
    alignSelf: 'center'
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px'
  },
  formListItem: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(2)
  },
  accordionSummary: {
    backgroundColor: fade(theme.palette.primary.main, 0.55)
  },
  accordionHeading: {
    fontWeight: 'bold',
    color: '#ffffff'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  }

}))

const FormModel = ({

  formSelected,
  form,
  handleSubmit,
  handleResponse,
  setUploadingFile,
  cancelForm

}) => {

  const classes = useStyles();

  const [ typing, setTyping ] = useState(false);

  const initToggleTyping = () => {
    setTyping(true);
    setTimeout(() => setTyping(false),1000);
  }


  if(!formSelected) {
    return (
      <Typography>Please select a group and then a form.</Typography>
    )
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
      onReset={cancelForm}
    >
      <Typography className={classes.formTitle}>{formSelected.title}</Typography>
      {
        form.map((elem,i) =>
          <InputHandler
            key={elem._id}
            elem={elem}
            handleResponse={handleResponse}
            toggleTyping={initToggleTyping}
            setUploadingFile={setUploadingFile}
          />
        )
      }

      <Button
        className={classes.submitBtn}
        color='primary'
        variant="contained"
        type='submit'
        disabled={typing}
      >
        Submit
      </Button>
      <Button
        className={classes.submitBtn}
        color='secondary'
        variant="contained"
        type='reset'
      >
        Cancel
      </Button>
    </form>
  )
}

const DefaultFormsComponent = ({ user }) => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState } = context;
  const [ loading, setLoading ] = useState(true);
  const [ allForms, setAllForms ] = useState({});

  useEffect(() => {

    if(!mainState.groups || mainState.groups.length <= 0) {
      setLoading(false);
      return;
    }

    getAllForms();

  },[mainState.groups]);

  const getAllForms = async () => {
    setLoading(true);
    
    let promises = [];

    let tempGroups = mainState.groups;

    tempGroups.forEach(async (group) => {
      promises.push(
        firebaseApp.firestore().collection('domains').doc(mainState.domain)
          .collection('groups').doc(group)
          .collection('forms').get()
      )
    })

    let allPromised = await Promise.all(promises);

    let formsObj = {};

    allPromised.forEach(result => {
      if(!result.empty) {
        result.docs.forEach(doc => {
          let group = doc.ref.path.split('/')[3];
          if(formsObj.hasOwnProperty(group)) {
            formsObj[group].forms.push(doc.data());
          } else {
            formsObj[group] = {
              forms: []
            }

            formsObj[group].forms.push(doc.data())
          }
        })
      }
    });  //  FOR EACH RESULT

    setAllForms(formsObj);

    setLoading(false);

  }

  return (
    <div className={classes.root}>

    <Typography
      variant='h4'
      component="h1"
      className={classes.title}
    >Available Forms</Typography>

    {
      (Object.keys(allForms).length > 0) && (
          <div>
          {
              Object.keys(allForms).map((key,i) => {

                let group = key;

                return (
                  <Accordion key={i}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon style={{backgroundColor: 'white'}} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      >
                      <Typography
                        className={classes.accordionHeading}
                      >{key.toUpperCase()}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      {
                        allForms[key].forms.map(form => {
                          let formId = form._id;
                          return (
                            <Typography
                              key={formId}
                              className={classes.formListItem}
                              onClick={() => {
                                let url = `https://approveit.app/form/${group}/${formId}`;
                                window.open(url);
                              }}
                            >{form.title}</Typography>
                          )
                        })
                      }
                    </AccordionDetails>
                  </Accordion>
                )
              })
          }
          </div>
      )
    }

      <BackDrop loading={loading} />
    </div>
  )
}

export default DefaultFormsComponent
