import React, { useEffect, useState, useContext } from 'react';
import {
  CircularProgress,
  Button,
  Backdrop,
  Paper,
  Select,
  MenuItem,
  Modal,
  TextField,
  FormLabel,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Switch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MainContext from '../../state/main.context';

import InputsComponent from '../../shared_components/form_components/inputs.component';
import InputsPreviews from '../../shared_components/form_components/inputs.preview.component';
import BackDrop from '../../backdrop.component';

import { firebaseApp } from '../../../firebase';

const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: '20vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formOptions: {
    position: 'fixed',
    left: 0,
    top: '20vh',
    width: '15vw',
    border: '1px solid lightgrey',
    borderRadius: '5px',
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1
  },
  formBuilderContainer: {
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '15vw',
  },
  formOption: {
    margin: theme.spacing(1),
    width: '80%'
  },
  forms: {
    display: 'flex',
    width: '70vw',
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: theme.spacing(5),
    margin: theme.spacing(1),
    flexDirection: 'column',
    flexGrow: 9
  },
  formBuilder: {
    display: 'flex',
    border: '1px solid lightgrey',
    padding: theme.spacing(5),
    margin: theme.spacing(1),
    flexDirection: 'column'
  },
  leftForm: {
    display: 'flex',
    flexGrow: 1
  },
  formModel: {
    display: 'flex',
    width: '70vw',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    boxShadow: '5px 5px 5px 5px lightgrey',
    padding: theme.spacing(5),
    paddingTop: theme.spacing(2)
  },
  modelCont: {
    overflow: 'auto',
    backgroundColor: '#fff',
    boxShadow: '5px 5px 5px 5px lightgrey',
    margin: '0 auto'
  },
  formModelModal: {
    padding: theme.spacing(5),
    display: 'flex',
    width: '80vw',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: '10px'
  },
  addFormLabel: {
    color: 'black',
    margin: theme.spacing(1)
  },
  previewTitle: {
    display: 'flex',
    justifyContent: 'center'
  },
  required: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(3)
  },
  modal: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5)
  }

}))

const CreateFormComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState } = context;
  const [ loading, setLoading ] = useState(false);
  const [ formTitle, setFormTitle ] = useState('');
  const [ formDesc, setDesc ] = useState('');
  const [ formBody, setBody ] = useState([]);
  const [ dialogMessage, setMessage ] = useState('');
  const [ showDialog, setDialog ] = useState(false);
  const [ finalize, setFinalize ] = useState(false);
  const [ preview, setPreview ] = useState(false);
  const [ template, setTemplate ] = useState(true);
  const [ showRes, setShowRes ] = useState(false);
  const [ response, setResponse ] = useState('');
  const [ saving, setSaving ] = useState(false);
  const [ processing, setProcessing ] = useState(false);

  //  TEMP FORM LISTENER
  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }
    const tempFormListener = firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
    .onSnapshot(snapshot => {
      if(!snapshot.exists) {
        console.log('TEMP DOC DOES NOT EXIST.');
        firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
          .set({
            title: '',
            form:[]
          })
          .then(() => console.log('CREATED A DOC REFERENCE.'))
          .catch(err => console.log('DID NOT WORK',err));
        return;
      }
      console.log('FORM UPDATED');
      let tempData = snapshot.data();
      let title = tempData.title || '';
      let form = tempData.form || [];
      let template = tempData.template || false;
      let desc = tempData.desc || '';
      setFormTitle(title);
      setTemplate(template);
      setDesc(desc);
      setBody(form);
    })

    return () => tempFormListener();
  }, []);

  //  KEY PRESS LISTENER
  useEffect(() => {
    const keyPressListener = document.addEventListener('keydown',(event) => {
      const keyMap = [13,32,8,16,38,40,37,39,20,9,93,18,17,91,]
      if(keyMap.includes(event.which)) {
        return;
      }
      setSaving(true);
    })

    return () => document.removeEventListener('keydown',() => console.log('Keypress Listener Removed...'))
  },[])

  useEffect(() => {}, [ formTitle ]);

  const tempSave = form => {
    firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
      .update({
        title: formTitle,
        form:form
      })
      .then(res => {
        setSaving(false);
      })
      .catch(err => {
        let regex = /No document to update/g;
        let found = err.message.match(regex);
        setSaving(false);
        if(found.length > 0) {

          firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
            .set({
              title:formTitle,
              form:form
            })
            .then(() => console.log('CREATED A DOC REFERENCE.'))
            .catch(err => console.log('DID NOT WORK',err));

        }
      })
  }

  const tempSaveTitle = title => {
    firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
      .update({
        title: title
      })
      .then(res => {
        console.log('res',res);
        setSaving(false);
      })
      .catch(err => {
        let regex = /No document to update/g;
        let found = err.message.match(regex);
        setSaving(false);
        if(found.length > 0) {

          firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
            .set({
              title:title
            })
            .then(() => console.log('CREATED A DOC REFERENCE.'))
            .catch(err => console.log('DID NOT WORK',err));

        }
      })
  }

  const tempSaveDesc = desc => {
    firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
      .update({
        desc: desc
      })
      .then(res => {
        console.log('res',res);
        setSaving(false);
      })
      .catch(err => {
        let regex = /No document to update/g;
        let found = err.message.match(regex);
        setSaving(false);
        if(found.length > 0) {

          firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
            .set({
              desc: desc
            })
            .then(() => console.log('CREATED A DOC REFERENCE.'))
            .catch(err => console.log('DID NOT WORK',err));

        }
      })
  }

  const tempSaveTemplate = bool => {
    firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
      .update({
        template: bool
      })
      .then(res => {
        console.log('res',res);
        setSaving(false);
      })
      .catch(err => {
        let regex = /No document to update/g;
        let found = err.message.match(regex);
        setSaving(false);
        if(found.length > 0) {

          firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
            .set({
              template: bool
            })
            .then(() => console.log('CREATED A DOC REFERENCE.'))
            .catch(err => console.log('DID NOT WORK',err));

        }
      })
  }

  const resetTempForm = form => {
    firebaseApp.firestore().collection('tempForms').doc(mainState.authUser.uid)
      .update({
        title: '',
        form:[],
        desc: '',
        template: false
      })
      .then(res => {
        setBody([]);
        setDesc('');
        setFormTitle('');
      })
      .catch(err => {
        console.log('ERROR',err);
        setBody([]);
        setDesc('');
        setFormTitle('');
      })
  }

  const addInput = inputType => {
    let tempArr = [];
    switch (inputType) {

      case 'file':
        tempArr.push({
          _id: Math.random()
            .toString(36)
            .substr(2, 10),
          type: inputType,
          title: "",
          required: false
        });
        setBody([...formBody, ...tempArr]);
        tempSave([...formBody, ...tempArr]);

        break;

      case 'address':
        tempArr.push({
          _id: Math.random()
            .toString(36)
            .substr(2, 10),
          type: inputType,
          title: "",
          address: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          required: false
        });
        setBody([...formBody, ...tempArr]);
        tempSave([...formBody, ...tempArr]);
        break;

      case 'expense':
        tempArr.push({
          _id: Math.random()
            .toString(36)
            .substr(2, 10),
          type: inputType,
          title: "",
          quantity: 0,
          cost: 0,
          total: 0,
          required: false
        });
        setBody([...formBody, ...tempArr]);
        tempSave([...formBody, ...tempArr]);
        break;

      case "number":
      case "text":
      case "textarea":
        tempArr.push({
          _id: Math.random()
            .toString(36)
            .substr(2, 10),
          type: inputType,
          title: "",
          required: false
        });
        setBody([...formBody, ...tempArr]);
        tempSave([...formBody, ...tempArr]);
        break;

      case "checkbox":
        tempArr.push({
          _id: Math.random()
            .toString(36)
            .substr(2, 10),
          type: inputType,
          title: "",
          options: [],
          selected: [],
          required: false
        });
        setBody([...formBody, ...tempArr]);
        tempSave([...formBody, ...tempArr]);
        break;

      case "select":
      case "radio":
        tempArr.push({
          _id: Math.random()
            .toString(36)
            .substr(2, 10),
          type: inputType,
          title: "",
          options: [],
          selected: "",
          required: false
        });
        setBody([...formBody, ...tempArr]);
        tempSave([...formBody, ...tempArr]);
        break;

      case 'date':
        tempArr.push({
          _id: Math.random()
            .toString(36)
            .substr(2, 10),
          type: inputType,
          title: "",
          start: null,
          end: null,
          allDay: false,
          required: false
        });
        setBody([...formBody, ...tempArr]);
        tempSave([...formBody, ...tempArr]);
        break;

      default:
        return;
    }
  };

  const initSetFormTitle = _.debounce((title) => {
    if(title) {
      setFormTitle(title);
      tempSaveTitle(title);
      return;
    }
    setSaving(false);
  },1000)

  const initAddDesc = _.debounce((desc) => {
    if(desc) {
      setDesc(desc);
      tempSaveDesc(desc);
      return;
    }
    setSaving(false);
  },1500)

  const initSetTemplate = (bool) => {
    setTemplate(bool);
    tempSaveTemplate(bool);
  }

  const addTitle = _.debounce((id, title) => {
    let elements = formBody;
    elements.some(elem => {
      if (elem._id === id) {
        elem.title = title;
        return true;
      }
      return false;
    });
    setBody([...elements]);
    tempSave([...elements]);
  },1000);

  const addOptions = _.debounce((id, options) => {
    let elements = formBody;
    let tempOptions = options.split(",");
    elements.some(elem => {
      if (elem._id === id) {
        elem.options = tempOptions;
        return true;
      }
      return false;
    });
    setBody([...elements]);
    tempSave([...elements]);
  },1500);

  const setRequired = (id, action) => {
    let elements = formBody;
    elements.some(elem => {
      if (elem._id === id) {
        elem.required = action;
        return true;
      }
      return false;
    });
    setBody([...elements]);
    tempSave([...elements]);
  };

  const removeInput = (id) => {
    let elements = formBody.filter(elem => elem._id !== id);
    setBody([...elements]);
    tempSave([...elements]);
  };

  const moveItem = (id,action) => {
    let tempBody = formBody;
    let lastPos = tempBody.length - 1;
    let currentItem, currentLoc, itemBefore, itemAfter;

    tempBody.some((item,i) => {
      if(item._id === id) {

        currentItem = item;
        currentLoc = i;
        itemBefore = tempBody[i - 1] || null;
        itemAfter = tempBody[i + 1] || null;
        return true;
      }
      return false;
    })

    switch(action) {
      case 'up':
        if(currentLoc !== 0) {
          tempBody[currentLoc - 1] = currentItem;
          tempBody[currentLoc] = itemBefore;
        }
        break;

      case 'down':
        if(currentLoc !== lastPos) {
          tempBody[currentLoc + 1] = currentItem;
          tempBody[currentLoc] = itemAfter;
        }
        break;
    }

    setBody([...tempBody]);
    tempSave([...tempBody]);
  };

  const alertFormIncomplete = (inputType) => {

    switch(inputType) {

      case 'formTitle':
        setMessage('Please enter a form title.');
        setDialog(true);
        break;

      case 'title':
        setMessage('Missing title on one or more of the form items.');
        setDialog(true);
        break;

      case 'options':
        setMessage('Please make sure that checkbox, radio and/or select items have options.');
        setDialog(true);
        break;

      default:
        setMessage('There is an issue with the form.  Please check that you have everything completed correctly.');
        setDialog(true);
    }

  }

  const initSaveForm = () => {
    if(!formTitle) {
      alertFormIncomplete('formTitle');
      return;
    }

    let formComplete = true;
    //  CHECK IF ANY REQUIRED ITEMS ARE MISSING
    formBody.some(item => {
      let itemType = item.type;
      if(!item.title) {
        alertFormIncomplete('title');
        formComplete = false;
        return true;
      }
      switch(itemType) {

        case 'checkbox':
        case 'radio':
        case 'select':
          if(item.options.length <= 0) {
            alertFormIncomplete('options')
            formComplete = false;
            return true;
          }
          break;
      }
      return false;
    })

    if(!formComplete) {
      console.log('Form is not complete.');
      return;
    }

    setFinalize(true);

  }

  const saveForm = () => {

    let domain = mainState.authUser.email.split('@')[1]

    setFinalize(false);

    setProcessing(true);

    let formToSave = {
      title: formTitle,
      template: template,
      domain: domain,
      desc: formDesc,
      form: formBody,
      useCount: 0
    }


    return firebaseApp.firestore().collection('forms').add(formToSave)
      .then(doc => {

        let formRef = {
          _id: doc.id,
          title: formTitle,
          desc: formDesc
        }

        return firebaseApp.firestore().collection('domains').doc(domain)
          .collection('forms').add(formRef)
          .then(() => {
            setProcessing(false);
            resetTempForm()
            alert('Form successfully created.');
          })
          .catch(err => {
            console.dir(err)
            setProcessing(false);
            alert(err.message);
            return;
          })

      })
      .catch(err => {
        console.dir(err)
        setProcessing(false);
        alert(err.message);
        return;
      })

  }

  const closeResponse = () => {
    setShowRes(false);
    setResponse('');
  }

  return (
    <div className={classes.root}>

      <div className={classes.formOptions}>
        <FormLabel className={classes.addFormLabel}>Add Form Items</FormLabel>
        <Button
          className={classes.formOption}
          variant="contained"
          disableElevation
          onClick={() => addInput('text')}
        >
          Text Field
        </Button>

        <Button
          className={classes.formOption}
          variant="contained"
          disableElevation
          onClick={() => addInput('textarea')}
        >
          Paragraph Field
        </Button>

        <Button
          className={classes.formOption}
          variant="contained"
          disableElevation
          onClick={() => addInput('number')}
        >
          Number Field
        </Button>

        <Button
          className={classes.formOption}
          variant="contained"
          disableElevation
          onClick={() => addInput('select')}
        >
          DropDown Menu
        </Button>

        <Button
          className={classes.formOption}
          variant="contained"
          disableElevation
          onClick={() => addInput('radio')}
        >
          Radio Button
        </Button>

        <Button
          className={classes.formOption}
          variant="contained"
          disableElevation
          onClick={() => addInput('checkbox')}
        >
          Checkboxes
        </Button>

        <FormLabel className={classes.addFormLabel}>Premium Items</FormLabel>

        <Button
          className={classes.formOption}
          variant="contained"
          disableElevation
          onClick={() => addInput('expense')}
          disabled={mainState.subscription !== "premium"}
        >
          Expense Field
        </Button>

        <Button
          className={classes.formOption}
          variant="contained"
          disableElevation
          onClick={() => addInput('date')}
          disabled={mainState.subscription !== "premium"}
        >
          Date Field
        </Button>

        <Button
          className={classes.formOption}
          variant="contained"
          disableElevation
          onClick={() => addInput('address')}
          disabled={mainState.subscription !== "premium"}
        >
          Address Field
        </Button>

        <Button
          className={classes.formOption}
          variant="contained"
          disableElevation
          onClick={() => addInput('file')}
          disabled={mainState.subscription !== "premium"}
        >
          Upload File
        </Button>
      </div>

      <div className={classes.formBuilderContainer}>

        <div className={classes.forms}>

        {
          saving ?

            <p style={{ textAlign: 'center' }}>Saving progress...</p>

          : formBody.length > 0 ?

            <p style={{ textAlign: 'center' }}>Progress Saved</p>

          : null
        }

        <div className={classes.formBuilder}>

          <div className={classes.previewTitle}>

            {
              formBody.length > 0 && (
                <Button
                  color='primary'
                  onClick={ initSaveForm }
                >
                  Save Form
                </Button>
              )
            }

            <h2 style={{ marginRight: '10px'}}>Form Builder</h2>

            {
              formBody.length > 0 && (
                <Button
                  color='primary'
                  onClick={() => setPreview(true) }
                >
                  Preview
                </Button>
              )
            }

          </div>

          {
            formBody.length > 0 && (

              <TextField
                onChange={e => initSetFormTitle(e.target.value)}
                type="text"
                defaultValue={ formTitle }
                label='Enter Form Title'
                variant='outlined'
              />

            )
          }

          {
            formBody.length > 0 && (
              formBody.map(elem =>
                <InputsComponent
                  elem={elem}
                  key={elem._id}
                  addTitle={addTitle}
                  addOptions={addOptions}
                  removeInput={removeInput}
                  setRequired={setRequired}
                  moveItem={moveItem}
                />
              )
            )
          }

        </div>

        <hr />

        {/*  FORM AUTO PREVIEW NOT NECESSARY
        <div className={classes.previewTitle}>
        <h2 style={{ marginRight: '10px'}}>Form Preview</h2>
        {
          formBody.length > 0 && (
            <Button
              color='primary'
              onClick={ initSaveForm }
            >
              Save Form
            </Button>
          )
        }

        </div>

        <div className={classes.formModel}>

          <h4>{ formTitle ? formTitle : '' }</h4>

          {
            formBody.length > 0 && (
              formBody.map(elem =>
                <InputsPreviews key={elem._id} elem={elem} />
              )
            )
          }

          {
            formBody.length > 0 && (
              <FormLabel className={classes.required}>* Required</FormLabel>
            )
          }

        </div>
        */}

        </div>

      </div>

      <Dialog
        open={showDialog}
      >
        <DialogTitle>FORM ERROR</DialogTitle>
        <DialogContent>
          <DialogContentText >
            { dialogMessage }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showRes}
      >
        <DialogTitle>RESULT</DialogTitle>
        <DialogContent>
          <DialogContentText >
            { response }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeResponse}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={finalize}
      >
        <DialogTitle>FINALIZE FORM INFO</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Please enter a description about this form.
          </DialogContentText>
          <TextField
            margin='dense'
            label='Enter Description'
            multiline
            rows={3}
            fullWidth
            onChange={e => initAddDesc(e.target.value)}
            defaultValue={formDesc}
          />
        </DialogContent>

        <DialogContent>
          <DialogContentText>
            By allowing this form to be a template, other groups will be able to use this form as base for a form they are creating.
          </DialogContentText>
          <FormControlLabel
            control={
              <Switch
                checked={template}
                onChange={e => initSetTemplate(!template)}
              />
            }
            label="Create as Template"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setFinalize(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={saveForm} color="primary"
            disabled={!formDesc}
          >
            Finalize and Submit Form
          </Button>
        </DialogActions>

      </Dialog>

      <Modal
        className={classes.modal}
        open={preview}
        onClick={() => console.log('hello') }
        BackdropProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: 'white',
            padding: '10vw'
          }
        }}
      >
        <div className={classes.modelCont}>
          <Button
            color='secondary'
            onClick={() => setPreview(false) }
            style={{
              position: 'fixed',
              alignSelf: 'flex-end'
            }}
          >
            Close
          </Button>
          <Paper className={classes.formModelModal}>

            <h4>{ formTitle ? formTitle : '' }</h4>

            {
              formBody.length > 0 && (
                formBody.map(elem =>
                  <InputsPreviews key={elem._id} elem={elem} />
                )
              )
            }

            {
              formBody.length > 0 && (
                <FormLabel className={classes.required}>* Required</FormLabel>
              )
            }

          </Paper>
        </div>
      </Modal>

      <BackDrop loading={processing} />
    </div>
  )
}

export default CreateFormComponent;
