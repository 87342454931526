import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Paper,
  Modal,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Divider
} from '@material-ui/core';
import { firebaseApp } from '../../firebase';
import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InputHandler from './user_forms/inputs.handler.component';
import ErrorDialog from '../shared_components/error.dialog.component';
import ConfirmDialog from '../shared_components/confirm.dialog.component';
import BackDrop from '../backdrop.component';
import moment from 'moment';
const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '5vh',
    width: '80vw',
    alignSelf: 'center'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150
  },
  formPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginBottom: '10vh'
  },
  formPaperRecipient: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  recipientForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  optionsMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%'
  },
  formTitle: {
    textAlign: 'center',
    margin: theme.spacing(1)
  },
  submitBtn: {
    margin: theme.spacing(2),
    width: '60%'
  },
  item: {
    width: '80%'
  },
  textCentered: {
    textAlign: 'center'
  },
  recipientBtn: {
    margin: theme.spacing(1)
  }

}))

const FormModel = ({
  formSelected,
  form,
  handleSubmit,
  handleResponse,
  setUploadingFile,
  cancelForm,
  updateTempForm
}) => {

  const classes = useStyles();

  const [ typing, setTyping ] = useState(false);

  const initToggleTyping = () => {
    setTyping(true);
    setTimeout(() => setTyping(false),1000);
  }


  if(!formSelected) {
    return (
      <Typography>Select an incomplete form from the table.</Typography>
    )
  }

  return (
    <form
      className={classes.form}
      onSubmit={event => {
        updateTempForm.cancel();
        handleSubmit(event);
      }}
    >
      <Typography className={classes.formTitle}>{formSelected.title}</Typography>
      {
        form.map((elem,i) =>
          <InputHandler
            key={elem._id}
            elem={elem}
            handleResponse={handleResponse}
            toggleTyping={initToggleTyping}
            setUploadingFile={setUploadingFile}
          />
        )
      }

      <Button
        className={classes.submitBtn}
        color='primary'
        variant="contained"
        type='submit'
        disabled={typing}
      >
        Submit
      </Button>
    </form>
  )
}

const IncompleteFormsComponent = ({ user }) => {

  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const [ groupSelected, setGroupSelected ] = useState('');
  const [ groupForms, setGroupForms ] = useState(null);
  const [ formSelected, setFormSelected ] = useState(null);
  const [ form, setForm ] = useState([]);
  const [ formTitle, setFormTitle ] = useState('');
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ confirmMessage, setConfirmMessage ] = useState('');
  const [ submittingForm, setSubmittingForm ] = useState(false);
  const [ recipient, setRecipient ] = useState('');
  const [ invalidRecipient, setInvalidRecipient ] = useState(false);
  const [ validRecipient, setRecipientValid ] = useState(false);
  const [ uploadingFile, setUploadingFile ] = useState(false);
  const [ tempFormRef, setTempFormRef ] = useState(null);
  const [ savingProgress, setSavingProgress ] = useState(false);
  const [ incompleteForms, setIncompleteForms ] = useState([]);

  useEffect(() => {
    //  GET USER'S INCOMPLETE FORMS

    const incompleteFormsListener = firebaseApp.firestore().collection('incompleteForms')
      .doc(user.uid).collection('tempForms').onSnapshot(querySnap => {

        if(querySnap.docs.length > 0) {
            let tempDocs = []
            querySnap.docs.forEach(doc => {
              let tempDoc = doc.data();
              tempDoc.formData.tempRefId = doc.id;

              tempDocs.push(tempDoc);
            })

            setIncompleteForms([...tempDocs]);

            return;
        }

        setIncompleteForms([]);

      });

      return () => incompleteFormsListener();

  },[]);

  const promptMissingRequired = () => {
    setErrorMessage('One or more required(*) form items are missing.  Please ensure that you complete the form thoroughly.');
    setShowError(true);
    setTimeout(()=> {
      setShowError(false);
    }, 2000)
  }

  const initConfirm = () => {
    setShowConfirm(true);
    setTimeout(() => {
      setShowConfirm(false);
      resetForm();
    },1000)
  }

  const initError = () => {
    setShowError(true);
    setTimeout(() => setShowError(false),1000)
  }

  const resetForm = () => {
    setForm([]);
    setFormTitle('');
    setFormSelected(null);
  }

  const cancelForm = () => {
    //  DELETE ANY FILES ASSOCIATED WITH THIS FORM
    form.forEach(item => {
      if(item.type === 'file' && item.response) {
        deleteFile(item.response.fullPath);
      }
    });
    deleteTempForm();
  }

  const deleteFile = (path) => {
    let fileRef = firebaseApp.storage().ref().child(path);
    fileRef.delete()
    .then(() => {
      console.log("Form cancelled and associated file successfully deleted.");
    })
    .catch(err => console.dir(err));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    //  IF APPROVER IS REQUIRED AND NO APPROVER
    //  PROMPT USER TO ENTER APPROVER
    if(formSelected.manualApproval && !formSelected.hasOwnProperty('approvers') || formSelected.manualApproval && formSelected.approvers.length <= 0) {
      setErrorMessage('An approver\'s email is required.  Please enter a valid email before you can submit the request.');
      setLoading(false);
      initError();
      return;
    }

    //  CHECK IF ANY OF THE ITEM IS REQUIRED.
    let isRequiredMissing = false;

    form.some(item => {
      if(item.required) {
        switch(item.type) {
          case 'text':
          case 'number':
          case 'textarea':
          case 'radio':
          case 'select':
            if(!item.response) {
              isRequiredMissing = true;
              return true;
            }
            break;

          case 'file':
            if(!item.response.url) {
              isRequiredMissing = true;
            }
            break;

          case 'checkbox':
            let checkArr = Object.keys(item.response).filter(key => item.response[key]);
            if(checkArr.length > 0) {
              isRequiredMissing = true;
              return true;
            }
            break;

          case 'address':
            let addressArr = Object.keys(item.response).filter(key => !item.response[key]);
            if(addressArr.length > 0) {
              isRequiredMissing = true;
              return true;
            }
            break;

          case 'date':
            let arr = Object.keys(item.response).filter(key => !item.response[key]);
            if(arr.length > 0) {
              isRequiredMissing = true;
              return true;
            }
            break;

        }
      }
      return false;
    });

    if(isRequiredMissing) {
      setLoading(false);
      promptMissingRequired();
      return;
    }

    setSubmittingForm(true);

    let toSave = {};
    toSave.approvers = formSelected.approvers;
    toSave.approvers.forEach(approver => {
      approver.status = 'action required'
      approver.comment = '';
      approver.actionDate= null;
    });
    toSave.approverUIDs = toSave.approvers.map(approver => approver.uid);
    toSave.domain = user.domain;
    toSave.manualApproval = formSelected.manualApproval;
    toSave.formTitle = formSelected.title;
    toSave.form = form;
    toSave.group = groupSelected;
    toSave.requesterUid = user.uid;
    toSave.requester = {
      displayName: user.displayName,
      uid: user.uid,
      email: user.email
    }

    let options = {
      method: 'Post',
      headers: {
        Authorization: user.uid
      },
      body: JSON.stringify(toSave)
    }

    // let url = "/approve-it-5c4fc/us-central1/submitRequest";
    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/submitRequest"

    try {

      let initSubmitForm = await fetch(url,options);
      let textRes = await initSubmitForm.text();

      if(initSubmitForm.ok) {
        setConfirmMessage(textRes);
        setSubmittingForm(false);
        initConfirm();
        deleteTempForm();
        return;
      }

      setErrorMessage(textRes);
      setSubmittingForm(false);
      initError();

    } catch (e) {
      console.log('ERROR',e);
      setErrorMessage('THERE WAS AN ERROR SUBMITTING YOUR REQUEST.  PLEASE TRY AGAIN LATER.');
      setSubmittingForm(false);
      initError();
    }

  }

  const deleteTempForm = () => {
    firebaseApp.firestore().collection('incompleteForms')
      .doc(user.uid).collection('tempForms').doc(formSelected.tempRefId)
      .delete()
      .then(() => {
        console.log('Successfully deleted form.');
        resetForm();
      })
      .catch(err => {
        console.log('Error updating tempForm.');
        console.dir(err);
      })
  }

  const cancelFormBtn = rowData => {
    //  DELETE ANY FILES ASSOCIATED WITH THIS FORM
    rowData.formData.form.forEach(item => {
      if(item.type === 'file' && item.response) {
        deleteFile(item.response.fullPath);
      }
    });
    deleteTempFormBtn(rowData.formData.tempRefId);
  }

  const deleteTempFormBtn = formId => {
    firebaseApp.firestore().collection('incompleteForms')
      .doc(user.uid).collection('tempForms').doc(formId)
      .delete()
      .then(() => {
        console.log('Successfully deleted form.');
        resetForm();
      })
      .catch(err => {
        console.log('Error updating tempForm.');
        console.dir(err);
      })
  }

  const handleResponse = (elemType,id,res) => {

    let tempForm = form;

    switch(elemType) {

      case 'file':

        tempForm.some(item => {
          if(id == item._id) {
            item.response = res;
          }
          return false;
        });

        break;

      case 'textarea':
      case 'text':
      case 'number':
      case 'radio':
      case 'select':

      tempForm.some(item => {
        if(id == item._id) {
          item.response = res;
        }
        return false;
      });

        break;

      case 'file':

        tempForm.some(item => {
          if(id == item._id) {
            item.response = res;
            return true;
          }

          return false;
        });

        break;

      case 'checkbox':
        tempForm.some(item => {
          if(id == item._id) {
            let checkObj = item.response;
            checkObj[res.key] = res.val;
            return true;
          }

          return false;
        })
        break;

      case 'date':
        tempForm.some(item => {
          if(id == item._id) {
            item.response = {...res};
            if(res.hasOwnProperty('start')) {
              item.start = res.start;
            }
            if(res.hasOwnProperty('end')) {
              item.end = res.end;
            }
            return true;
          }

          return false;
        })
        break;
      case 'address':
      case 'expense':
        tempForm.some(item => {
          if(id == item._id) {
            item.response = {...res};
            return true;
          }

          return false;
        })
        break;

    }
    setForm([...tempForm]);
    setSavingProgress(true);
    updateTempForm();
  }

  const updateTempForm = _.debounce(() => {
    firebaseApp.firestore().collection('incompleteForms')
      .doc(user.uid).collection('tempForms').doc(formSelected.tempRefId)
      .update({
        "formData.form": form
      })
      .then(() => {
        console.log('Successfully updated temporary form.');
        setSavingProgress(false);
      })
      .catch(err => {
        setSavingProgress(false)
        console.log('Error updating tempForm.');
        console.dir(err);
      })
  },2500)

  const handleFormSelection = rowData => {
    setFormTitle(rowData.formData.title);
    setFormSelected(rowData.formData);
    setForm(rowData.formData.form);
    setGroupSelected(rowData.group);
  }

  const initGetRecipient = e => {

    e.preventDefault();
    e.persist();

    let email = e.target[0].value;

    if(!email) {
      return;
    }

    email = email.toLocaleLowerCase();

    let domain = email.split('@')[1];

    firebaseApp.firestore().collection('domains').doc(domain)
      .collection('users').doc(email)
      .get().then(docSnap => {
        if(!docSnap.exists){
          //  PROMPT USER
          setErrorMessage('Unable to find user.  Please check that you entered the correct email address.');
          setLoading(false);
          setInvalidRecipient(true);
          setRecipientValid(false);
          initError();
          return;
        }
        let approver = docSnap.data();
        let tempFormSelected = formSelected;
        let tempApprover = {
          actionDate: '',
          comment: '',
          displayName: approver.displayName,
          email: approver.email,
          status: 'action required',
          uid: approver.uid
        }
        tempFormSelected.approvers = [ tempApprover ];

        tempFormSelected.approverUIDs = [
          approver.uid
        ];

        setFormSelected(tempFormSelected);
        setLoading(false);
        setInvalidRecipient(false);
        setRecipientValid(true);
        updateManualRecipient(tempApprover);
      })
      .catch(err => {
        console.dir(err);
        setLoading(false);
      });
  }

  const updateManualRecipient = (recipient) => {
    firebaseApp.firestore().collection('incompleteForms')
      .doc(user.uid).collection('tempForms').doc(formSelected.tempRefId)
      .update({
        "formData.approvers": [ recipient ]
      })
      .then(() => {
        console.log('Successfully updated temporary form.');
        setSavingProgress(false);
      })
      .catch(err => {
        setSavingProgress(false)
        console.log('Error updating tempForm.');
        console.dir(err);
      })
  }

  const columns = [
    {
      title: 'Date',
      render: rowData => <Typography className={classes.dateLabel}>{moment(new Date(rowData.timestamp)).format("MMM D, YYYY")}</Typography>
    },
    {
      title: 'Title',
      render: rowData => <Typography>{rowData.formData.title}</Typography>
    },
    {
      title: 'Location',
      render: rowData => <Typography>{rowData.group.toUpperCase()}</Typography>
    }
  ]

  return (
    <div className={classes.root}>
      <MaterialTable
        columns={columns}
        data={incompleteForms}
        title="Incomplete Forms"
        actions={[
          rowData => ({
            icon: () => <EditIcon
              color="action"
            />,
            tooltip: 'Edit & Submit',
            onClick: (event, rowData) => handleFormSelection(rowData)
          }),
          rowData => ({
            icon: () => <DeleteIcon
              color="error"
            />,
            tooltip: 'Delete Form',
            onClick: (event, rowData) => {
              let confirm = window.confirm("Delete incomplete form?");
              if(confirm) {
                cancelFormBtn(rowData)
              }
            }
          })
        ]}
      />

      <Divider />

      {
        (formSelected && formSelected.manualApproval) && (
          <Paper className={classes.formPaperRecipient}>
            <Typography className={classes.textCentered}>
              <span style={{color: 'red'}}>IMPORTANT</span>:  Please enter the email of person that is responsible for approving this form.  If you do not know, please contact your administrator.
            </Typography>
            <form onSubmit={initGetRecipient}
              className={classes.recipientForm}
            >

              <TextField
                autoFocus
                label="Enter Email of Recipient"
                defaultValue={recipient}
                variant="outlined"
                className={classes.item}
                onChange={e => setRecipient(e.target.value) }
                // onBlur={initGetRecipient}
                error={invalidRecipient ? true : false}
                helperText={invalidRecipient ? "Email is not valid." : validRecipient ? "Email is valid." : ''}
                required
              >
              </TextField>
              <Button
                color='secondary'
                className={classes.recipientBtn}
                type='submit'
              >
              Submit Email
              </Button>

            </form>
          </Paper>
        )
      }

      {
        savingProgress && (
          <Typography
            align="center"
            color="primary"
          >Saving progress...</Typography>
        )
      }

      <Paper className={classes.formPaper}>
        <FormModel
          formSelected={formSelected}
          form={form}
          handleSubmit={handleSubmit}
          handleResponse={handleResponse}
          setUploadingFile={setUploadingFile}
          cancelForm={cancelForm}
          updateTempForm={updateTempForm}
        />
      </Paper>

      <BackDrop loading={ submittingForm || uploadingFile } />
      <ErrorDialog message={errorMessage} toOpen={showError} />
      <ConfirmDialog message={confirmMessage} toOpen={showConfirm} />
    </div>
  )
}

export default IncompleteFormsComponent;
