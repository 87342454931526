import React, { useEffect, useState, useContext } from 'react';
import { firebaseApp } from '../../firebase';

import Papa from 'papaparse';
import BackDrop from '../backdrop.component';

import MainContext from '../state/main.context';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Modal,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Input,
  FormControl
} from '@material-ui/core';
import MaterialTable from "material-table";
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
    width: '50vw',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  btn: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5)
  },
  optionsMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150
  },
}));

const UsersComponent = props => {

  const context = useContext(MainContext);
  let { mainState } = context;
  const classes = useStyles();
  const [ domains, setDomains ] = useState([]);
  const [ users, setUsers] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ domainSelected, setDomain ] = useState('');
  const [ showForm, setShowForm ] = useState(false);
  const [ showUploadForm, setShowUploadForm ] = useState(false);
  const [ lastBatch, setLastBatch ] = useState(null);
  const [ processing, setProcessing ] = useState(false);
  const [ response, setResponse ] = useState('');
  const [ showResponse, setShowResponse ] = useState(false);
  const [ updatingRole, setUpdatingRole ] = useState(false);
  let unsubscribe = null;

  useEffect(() => {
    if(!mainState.authUser){
      return;
    }

    //  GRAB ALL DOMAINS
    const domainListeners = firebaseApp.firestore().collection('domains')
      .onSnapshot(querySnap => {
       if(querySnap.size > 0) {
         let arr = [];
         querySnap.forEach(snap => {
           let tempData = snap.data();

           arr.push(tempData);

         })
         console.log(arr);
         setDomains(arr);
         setLoading(false);
         return;
       }
       setLoading(false);
    });

  },[]);

  const initShowResponse = (textRes) => {
    setResponse(textRes);
    setShowResponse(true);
    setTimeout(() => {
      setShowResponse(false);
      setResponse('')
    },4000)
  }

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    setProcessing(true);

    let obj = {};
    obj.firstName = e.target[0].value;
    obj.lastName = e.target[1].value;
    obj.email = e.target[2].value;
    obj.displayName = obj.firstName + ' ' + obj.lastName;
    obj.domain = domainSelected;

    let options = {
      method: 'Post',
      headers: {
        Authorization: mainState.authUser.uid
      },
      body: JSON.stringify(obj)
    }

    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/addOneUser";

    try {
        let initAddUser = await fetch(url,options);
        let textRes = await initAddUser.text();
        console.log('RESPONSE: ' + textRes);
        setProcessing(false);
        initShowResponse(textRes);

    } catch (e) {
      console.log('ERROR',e);
      setProcessing(false);
      initShowResponse('THERE WAS AN ERROR.  PLEASE TRY AGAIN LATER.');

    }

  }

  const initDeactivateUser = async (user) => {
    setProcessing(true);
    let options = {
      method: 'Post',
      headers: {
        Authorization: mainState.authUser.uid
      },
      body: JSON.stringify(user)
    }
    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/deactivateUser";

    try {
        let initDeactivate = await fetch(url,options);
        let textRes = await initDeactivate.text();
        console.log(textRes);
        setProcessing(false);
    } catch (e) {
      console.log("ERROR",e);
      setProcessing(false);
    }
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    setProcessing(true);
    e.persist();

    let file = e.target[0].files[0];

    Papa.parse(file, {
      complete: function (results) {
        let data = results.data || [];
        console.dir(data);
        if(data.length <= 0) {
          setProcessing(false);
          initShowResponse('THERE WAS AN ERROR.  PLEASE TRY AGAIN LATER.');
          return;
        }
        let options = {
          method: 'Post',
          headers: {
            Authorization: mainState.authUser.uid
          },
          body: JSON.stringify({
            data: data,
            domain: domainSelected
          })
        }

        let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/uploadUsers";

        fetch(url,options)
          .then(res => {
            return res.text();
          })
          .then(text => {
            console.log('text',text);
            setProcessing(false);
            initShowResponse(text);
            e.target.reset();
          })
          .catch(err => {
            console.log('ERROR',err);
            setProcessing(false);
            initShowResponse('THERE WAS AN ERROR WITH THE UPLOAD.  PLEASE TRY AGAIN LATER.');
            e.target.reset();
          })

      }
    })


  }

  const setForm = form => {
    switch(form) {
      case 'userForm':
        setShowForm(!showForm);
        setShowUploadForm(false);
        break;

      case 'uploadForm':
        setShowForm(false);
        setShowUploadForm(!showUploadForm);
        break;
    }
  }

  const Form = props => (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '25vw'
      }}
      onSubmit={handleUserSubmit}
    >
      <TextField type='text' label="Enter First Name" required />
      <TextField type='text' label="Enter Last Name" required />
      <TextField type='email' label="Enter Email Address" required />
      <Button
        disabled={processing}
        style={{marginTop: '10px',marginBottom: '10px'}}
        variant='contained'
        color='secondary'
        type='submit'
      >
        Add User
      </Button>
    </form>
  )

  const UploadForm = props => (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '25vw'
      }}
      onSubmit={handleUpload}
    >
      <p>
        A .csv file with THREE columns is required.  The first column is for the First Name.  The second column is for the Last Name.  The third column is the email address that matches the domain.  DO NOT INCLUDE HEADERS. &nbsp; &nbsp;
        <a href="https://docs.google.com/spreadsheets/d/1WJwo1Dzo-5cy_DBPBWXD8yLMCHmNMb18NLsDWMdWfes/edit?usp=sharing" target="_blank">
          Example Sheet
        </a>
      </p>
      <TextField type='file' required />
      <Button
        style={{marginTop: '10px',marginBottom: '10px'}}
        variant='contained'
        color='secondary'
        type='submit'
      >
        Upload Users
      </Button>
    </form>
  )

  const initUpdateUserPermission = async (email, userUid, role, enable) => {
    console.log(email, role, enable);
    let domain = mainState.authUser.email.split('@')[1];

    let options = {
      method: 'Post',
      headers: {
        Authorization: mainState.authUser.uid
      },
      body: JSON.stringify({
        email: email,
        userUid: userUid,
        role: role,
        enable: enable,
        domain: domain
      })
    }

    // let url = '/approve-it-5c4fc/us-central1/updateAdminRole';
    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/updateAdminRole";

    setUpdatingRole(true);

    try {

      let initUpdateRole = await fetch(url,options);
      let textRes = await initUpdateRole.text();
      console.log(textRes);

      if(!initUpdateRole.ok) {
        alert(textRes);
      }

      setUpdatingRole(false);

    } catch (e) {
      console.log(e);

      setUpdatingRole(false);

      alert('THERE WAS AN ISSUE UPDATING ROLE.  PLEASE TRY AGAIN LATER.');
    }

  }

  const columns = [
    {
      title: 'First',
      field: 'firstName',
      render: rowData => <p>{ rowData.firstName.toUpperCase() }</p>
    },
    {
      title: 'Last',
      field: 'lastName',
      render: rowData => <p>{ rowData.lastName.toUpperCase() }</p>
    },
    {
      title: 'Email',
      field: 'email',
      render: rowData => <p>{ rowData.email.toUpperCase() }</p>
    },
    {
      title: 'Group Admin',
      render: rowData => {

        return (
          <Switch
            color='secondary'
            checked={rowData.role === 'group-admin' ? true : false}
            onChange={() => {
              let enable = rowData.role === 'group-admin' ? false : true;
              initUpdateUserPermission(rowData.email, rowData.uid, 'group-admin', enable);
            }}
            disabled={rowData.email === mainState.authUser.email}
          />
        )
      }
    },
    {
      title: 'Org Admin',
      render: rowData => {

        return (
          <Switch
            color='secondary'
            checked={rowData.role === 'org-admin' ? true : false}
            onChange={() => {
              let enable = rowData.role === 'org-admin' ? false : true;
              initUpdateUserPermission(rowData.email, rowData.uid, 'org-admin', enable);
            }}
            disabled={rowData.email === mainState.authUser.email}
          />
        )
      }
    }
  ]

  const handleDomainSelection = e => {
    if(unsubscribe){
      unsubscribe();
    }

    setLoading(true);

    unsubscribe = firebaseApp.firestore().collection('domains').doc(e.target.value)
      .collection('users')
      .onSnapshot(snapshot => {
        if(snapshot.size > 0) {
          let arr = [];
          snapshot.forEach(snap => {
            let tempData = snap.data();

            arr.push(tempData);

          })
          setUsers(arr);
          setLoading(false);
          return;
        }
      });

  }

  return (
    <div className={classes.root}>

    <div
      className={classes.optionsMenu}
    >

        <FormControl className={classes.formControl}>
          <InputLabel>Select Group</InputLabel>
          <Select
            value={domainSelected}
            onChange={handleDomainSelection}
          >
            {
              domains.map(domain =>
                <MenuItem
                  value={domain.domain}
                  key={domain.domain}
                >{domain.domain.toUpperCase()}</MenuItem>
              )
            }
          </Select>
        </FormControl>

    </div>

      <div className={classes.btns}>
        <Button onClick={() => setForm('userForm')} color='primary' className={classes.btn}>Add a user</Button>
        <Button onClick={() => setForm('uploadForm')} color='secondary' className={classes.btn}>Upload Users (.csv)</Button>
      </div>

      {
        showForm && (
          <Form />
        )
      }

      {
        showUploadForm && (
          <UploadForm />
        )
      }

      {
        showResponse && (
          <h3 style={{
            textAlign: 'center'
          }}>{response}</h3>
        )
      }

      <MaterialTable
        title="Users"
        columns={columns}
        data={users}
        actions={[
          rowData => ({
            icon: () =>
            <Switch color='secondary' checked={rowData.active}
              disabled={rowData.email === mainState.authUser.email}
            />,
            tooltip: 'Enable | Disable User',
            onClick: (event, rowData) => {
              if(rowData.email === mainState.authUser.email){
                return;
              }
              initDeactivateUser(rowData)
            }
          })
        ]}
        style={{
          width: window.innerWidth * 0.6
        }}
        pageSize={10}
      />

      <BackDrop loading={ loading || processing || processing } />
    </div>
  )
}

export default UsersComponent
