import React, { useEffect, useState, useContext } from 'react';
import {
  CircularProgress,
  Button,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Modal,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from "material-table";

import MainContext from '../../state/main.context';
import { firebaseApp } from '../../../firebase';
import BackDrop from '../../backdrop.component';
import ErrorDialog from '../error.dialog.component';
import ConfigGroupComponent from './config.group.component';
import EditFormComponent from './edit.form.component';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  detailPanel: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid lightgrey',
    margin: theme.spacing(1)
  },
  detailTitle: {
    marginRight: theme.spacing(5)
  },
  configBtn: {
    cursor: 'pointer',
    marginRight: theme.spacing(2)
  },
  editBtn: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2)
  },
  modal: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw'
  },
  configContainer: {
    overflow: 'auto',
    height: '80vh',
    width: '80vw',
    margin: '0 auto'
  },
  closeBtn: {
    alignSelf: 'flex-start',
    margin: theme.spacing(5),
    marginLeft: '10vw'
  }
}))

const AssignFormsComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState } = context;
  const [ loading, setLoading ] = useState(false);
  const [ forms, setForms ] = useState([]);
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ showModal, setModal ] = useState(false);
  const [ configGroup, setConfigGroup ] = useState(null);
  const [ configForm, setConfigForm ] = useState(null);
  const [ showEditModal, setEditModal ] = useState(false);
  const [ editGroup, setEditGroup ] = useState(null);
  const [ editForm, setEditForm ] = useState(null);

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    const formsListener = firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('forms').onSnapshot(snapshot => {
        if(snapshot.size > 0) {
          let tempArr = [];
          snapshot.forEach(snap => {
            let data = snap.data();
            data.id = snap.id;
            tempArr.push(data);
          });
          // console.dir(tempArr);
          setForms(tempArr);
        }
      },
      (error) => {
        console.log('ERROR',error);
      })

  }, []);

  const columns = [
    {
      title: 'Title',
      field: 'title',
      render: rowData => <p>{ rowData.title }</p>
    },
    {
      title: 'Description',
      field: 'desc',
      render: rowData => <p>{ rowData.desc }</p>
    }
  ]

  const handleCheckbox = (group,form,bool) => {
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('forms').doc(form.id)
      .update({
        [`groups.${group.groupName}`]: bool
      })
  }

  const initConfig = (group,form) => {
    setConfigGroup(group);
    setConfigForm(form);
    setModal(true);
  }

  const initEdit = (group,form) => {
    console.log(group.groupName);
    console.log(form);
    setEditGroup(group.groupName);
    setEditForm(form);
    setEditModal(true);
  }

  const DetailPanel = form => {
    console.dir(form);

    return (
      <div>
        {
          mainState.groups.length > 0 && (
            mainState.groups.map(group => {
              let isChecked = form.hasOwnProperty('groups') &&
                              form.groups[group.groupName] ? true : false
              return (
                <div className={classes.detailPanel} key={group.groupName}>
                  <Typography variant='button' className={classes.detailTitle}>
                    {group.groupName.toUpperCase()}
                  </Typography>

                  <Typography
                    variant='button'
                    color='primary'
                    className={classes.configBtn}
                    onClick={() => initConfig(group,form)}
                  >
                    Configure
                  </Typography>

                  <Typography
                    variant='button'
                    color='primary'
                    className={classes.editBtn}
                    onClick={() => initEdit(group,form)}
                  >
                    Edit
                  </Typography>
                </div>
              )
            })
          )
        }
      </div>
    )
  }

  return (
    <div className={classes.root}>

      <MaterialTable
        title="Forms"
        columns={columns}
        data={forms}
        detailPanel={ rowData =>
          <DetailPanel {...rowData} />
        }
        style={{
          width: window.innerWidth * 0.6
        }}
        pageSize={10}
      />

      {/*  CONFIGURE FORM MODAL

      */}

      <Modal
        className={classes.modal}
        open={showModal}
        onClick={() => console.log('Must Click on Close...') }
        BackdropProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10vw'
          }
        }}
      >
        <div className={classes.modalContainer}>
          <Button
            color='secondary'
            variant='contained'
            className={classes.closeBtn}
            onClick={() => {
              setModal(false)
              setConfigForm(null)
              setConfigGroup(null)
            }}
          >
            Close
          </Button>

          <div className={classes.configContainer}>
            <ConfigGroupComponent
              group={configGroup}
              form={configForm}
            />

          </div>

        </div>

      </Modal>


      {/*  EDIT FORM MODAL

      */}

      <Modal
        className={classes.modal}
        open={showEditModal}
        onClick={() => console.log('Must Click on Close...') }
        BackdropProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10vw',
            backgroundColor: 'white'
          }
        }}
      >
        <div className={classes.modalContainer}>
          <Button
            color='secondary'
            variant='contained'
            className={classes.closeBtn}
            onClick={() => {
              setEditModal(false)
            }}
          >
            Done
          </Button>

          <div className={classes.configContainer}>

            <EditFormComponent
              domain={mainState.domain}
              group={editGroup}
              form={editForm}
            />

          </div>

        </div>

      </Modal>

      <BackDrop loading={loading} />
      <ErrorDialog
        toOpen={showError}
        message={errorMessage}
      />
    </div>
  )
}

export default AssignFormsComponent
