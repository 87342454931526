import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Typography,
  Paper,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';

import moment from 'moment';

import CustomHeader from '../header.component';
import BackDrop from '../backdrop.component';
import ConfirmDialog from '../shared_components/confirm.dialog.component';
import ErrorDialog from '../shared_components/error.dialog.component';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90vw',
    alignItems: 'center',
    paddingTop: '10vh',
    paddingBottom: '15vh'
  },
  printRef: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    padding: theme.spacing(2)
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '60vw',
    marginTop: '10vh'
  },
  textField: {
    marginBottom: theme.spacing(2.5)
  },
  paper: {
    margin: theme.spacing(5),
    width: '80%',
    padding: theme.spacing(3)
  },
  formItem: {
    margin: theme.spacing(2)
  },
  approveBtn: {
    marginBottom: theme.spacing(1)
  },
  formResponse: {
    paddingLeft: theme.spacing(2)
  },
  formLabel: {
    margin: theme.spacing(1)
  }
}))

const ViewRequestComponent = ({requestData}) => {
  const printRef = useRef();
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);

  const [ confirmMessage, setConfirmMessage] = useState('');
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ showError, setShowError ] = useState(false);
  const [ approved, setApproved ] = useState(true);
  const [ comments, setComments ] = useState('');

  const RenderRequestData = () => (
    <Paper className={classes.paper}>

      <Typography variant='h4' style={{ textAlign: 'center' }}>
        {requestData.formTitle}
      </Typography>

      <Typography variant='h5' style={{ textAlign: 'center' }}>
        by {requestData.requester.displayName}
      </Typography>

      <div className={classes.formItem}>
        <Typography variant="h6">Request Date</Typography>
        <Typography
          className={classes.formResponse}
        >{moment(new Date(requestData.requestDate)).format("dddd, MMMM Do YYYY, h:mm a")}</Typography>
      </div>

      {
        requestData.form.map(item => {
          switch(item.type) {
            case 'textarea':
            case 'text':
            case 'number':
            case 'radio':
            case 'select': {
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >{item.response}</Typography>
                </div>
              )
            }

            case 'checkbox': {
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >
                    {
                      Object.keys(item.response).filter(key => item.response[key]).join(', ')
                    }
                  </Typography>

                </div>
              )
            }

            case 'date':{
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >Start: {moment(new Date(item.start)).format("dddd, MMM Do YYYY, h:mm a")}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >End: {moment(new Date(item.end)).format("dddd, MMM Do YYYY, h:mm a")}</Typography>
                </div>
              )
            }

            case 'address':{
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >{item.response.location}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >{item.response.street}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >{item.response.city}, {item.response.state} {item.response.zip}</Typography>
                </div>
              )
            }

            case 'expense':{
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >Quantity: {item.response.quantity}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >Cost: ${item.response.cost}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >Total: ${item.response.total}</Typography>
                </div>
              )
            }
          }

        })

      }
    </Paper>
  )

  const columns = [
    {
      title: 'Admin',
      field: 'displayName'
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData => <p>{
        (rowData.hasOwnProperty('status')) ?
          rowData.status.toUpperCase()
        : ''
      }</p>
    },
    {
      title: 'Comments',
      field: 'comment',
      render: rowData => <p>{
        (rowData.hasOwnProperty('comment')) ?
          rowData.comment.toUpperCase()
        : ''
      }</p>
    },
    {
      title: 'Action Date',
      field: 'actionDate',
      render: rowData => <p>{
        (rowData.hasOwnProperty('actionDate') && rowData.actionDate) ?
            moment(rowData.actionDate).format("M-D-YY h:mm a")
          : ''
      }</p>
    }

  ]

  return (
    <div
      className={classes.root}
      ref={ printRef }
    >
      <CustomHeader title='Request Approval' />

      <div
        className={classes.printRef}
      >


        <ReactToPrint
          trigger={() =>
            <PrintIcon
              style={{
                marginTop: '25px',
                right: '25px',
                cursor: 'pointer'
              }}
            />
          }
          content={ () => printRef.current }
        />

        <MaterialTable
          title="Request Status"
          data={requestData.approvers}
          columns={columns}
          style={{
            width: '80%'
          }}
          pageSize={requestData.approvers.length}
          options={{
            search: false,
            showFirstLastPageButtons: false,
            paging: false
          }}
        />

        {
          requestData && (
            <RenderRequestData />
          )
        }

        <ErrorDialog message={errorMessage} toOpen={showError} />
        <ConfirmDialog message={confirmMessage} toOpen={showConfirm} />
        <BackDrop loading={loading} />

      </div>

    </div>
  )
}

export default ViewRequestComponent
