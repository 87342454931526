import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import CustomHeader from './header.component';
import MainContext from '../state/main.context';
import { firebaseApp } from '../../firebase';
import { SignOut } from '../utilities/firebase.actions';
import CalendarComponent from '../shared_components/calendar.component';
import UsersComponent from '../shared_components/users.component';
import FormsConfigComponent from '../shared_components/admin_form_components/forms.config';
import GroupRequests from './group.requests';
import ResourcesComponent from './resources.component';
import ProfileComponent from './profile.component';
import TermsPrivacyAgreement from '../agreements/termsPrivacyAgreement.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: theme.spacing(10)
  },
  title: {
    flexGrow: 1,
  },
  error: {
    color: 'red',
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  list: {
    marginTop: theme.spacing(10)
  },
  drawer: {
    marginTop: theme.spacing(5),
  }
}));

const GroupAdminComponent = props => {
  let { history } = props;
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(false);
  const [ domain, setDomain ] = useState('');
  const [ groups, setGroups ] = useState([]);
  const [ menuOpen, toggleMenu ] = useState(false);
  const [ page,showPage ] = useState('Forms');
  const menuOptions = [
    'Profile',
    'Requests',
    'Users',
    'Forms'
  ];

  useEffect(() => {
    if(!mainState.authUser) {
      setLoading(false);
      return;
    }

    //  IF USER DOES NOT HAVE A DISPLAY NAME, FORCE THEM TO UPDATE PROFILE
    if(!mainState.authUser.hasOwnProperty('displayName') || (mainState.authUser.hasOwnProperty('displayName') && !mainState.authUser.displayName) || (mainState.authUser.hasOwnProperty('displayName') && mainState.authUser.displayName.trim().length <= 0)) {
      showPage('Profile');
      alert('Please update your first and last name.');
      return;
    }

    const tempDomain = mainState.authUser.email.split('@')[1];
    const uid = mainState.authUser.uid;
    setDomain(tempDomain);

    const groupListener = firebaseApp.firestore().collection('domains').doc(tempDomain)
      .collection('groups').where('domain','==',tempDomain)
      .where('admins','array-contains',mainState.authUser.email)
      .onSnapshot(querySnap => {
       if(querySnap.size > 0) {
         let arr = [];
         querySnap.forEach(snap => {
           let tempData = snap.data();
             arr.push(snap.data());
         })
         mainDispatch({ type: 'setGroups', payload: arr })
         setGroups(arr);
         setLoading(false);
         return;
       }
       setGroups([]);
       setLoading(false);
    })

    //  MAKE SURE THEY SIGNED THE AGREEMENT
    initCheckAgreement();

      return () => groupListener();

  },[])

  useEffect(() => {
    if(!mainState.authUser) {
      props.history.push('/login');
      return;
    }
  },[ mainState ] );

  const initCheckAgreement = async () => {

    let email = mainState.authUser.email;

    try {
      
      let agreementStatus = await firebaseApp.firestore()
      .collection('termsPrivacyAgreement').doc(email).get();

      if(!agreementStatus.exists) {
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: true });
        return;
      }

    } catch(e) {
      // statements
      console.log(e);
    }

  }

  const agreementResponse = agreed => {

    if(!agreed) {
      SignOut();
      mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      history.push('/home');
      return;
    }

    let email = mainState.authUser.email;

    firebaseApp.firestore().collection('termsPrivacyAgreement').doc(email)
      .set({
        agreed: Date.now()
      })
      .then(() => {
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      })
      .catch(err => {
        console.log(err);
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      })

  }


  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {menuOptions.map((text) => (
          <div key={text}>
            <ListItem
              button
              onClick={() => showPage(text)}
              key={text}
              selected={page === text}
            >
              <ListItemText primary={text} />
            </ListItem>
            <Divider />
          </div>
        ))}


      </List>


    </div>
  );

  const toggleDrawer = () => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    toggleMenu(!menuOpen)
  };

  const doToggle = () => toggleMenu(!menuOpen);

  return (
    <div className={classes.root}>
      <CustomHeader
        title={ 'Group Admin | ' + page }
        toggleMenu={doToggle}
      />

      <Drawer open={menuOpen} onClose={toggleDrawer()} className={classes.drawer}>
        {sideList()}
      </Drawer>

      {
        page === 'Profile' && (

          <ProfileComponent />

        )
      }

      {
        page === 'Calendar' && (
          <CalendarComponent />
        )
      }

      {
        page === 'Users' && (
          <UsersComponent />
        )
      }

      {
        page === 'Resources' && (
          <ResourcesComponent />
        )
      }

      {
        page === 'Forms' && (
          <FormsConfigComponent />
        )
      }

      {
        page === 'Requests' && (
          <GroupRequests />
        )
      }


      <TermsPrivacyAgreement
        visible={mainState.termsPrivacyAgreement}
        agreementResponse={agreementResponse}
      />
    </div>
  )
}

export default GroupAdminComponent
