import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import CustomHeader from '../header.component';
import MainContext from '../state/main.context';
import OrgFormComponent from './org.form.component';
import OrgsComponent from './orgs.component';
import UsersComponent from './users.component';

import FormsConfigComponent from '../shared_components/admin_form_components/forms.config';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    // border: '1px solid black'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: theme.spacing(10)
  },
  title: {
    flexGrow: 1,
  },
  error: {
    color: 'red',
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  list: {
    marginTop: theme.spacing(10)
  },
  drawer: {
    marginTop: theme.spacing(5),
  }
}));

const SuperAdminComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  const { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(false);
  const [ menuOpen, toggleMenu ] = useState(false);
  const [ page,showPage ] = useState('Organizations List');
  const menuOptions = [
    'Add Organization',
    'Organizations List',
    'Forms',
    'Users'
  ];

  useEffect(() => {
    if(!mainState.authUser) {
      props.history.push('/login');
      return;
    }
  },[ mainState ] );


  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>

        {menuOptions.map((text) => (
          <div key={text}>
            <ListItem button
              onClick={() => showPage(text)}
            key={text}>
              <ListItemText primary={text} />
            </ListItem>
            <Divider />
          </div>
        ))}


      </List>


    </div>
  );

  const toggleDrawer = () => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    toggleMenu(!menuOpen)
  };

  return (
    <div>
      <CustomHeader title='Super Admin' />
      <IconButton edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer()}
      >
        <MenuIcon />
      </IconButton>

      <Drawer open={menuOpen} onClose={toggleDrawer()} className={classes.drawer}>
        {sideList()}
      </Drawer>

      {
        page === 'Add Organization' && (

          <OrgFormComponent />

        )
      }

      {
        page === 'Organizations List' && (

          <OrgsComponent />

        )
      }

      {
        page === 'Forms' && (
          <FormsConfigComponent />
        )
      }

      {
        page === 'Users' && (
          <UsersComponent />
        )
      }

    </div>
  )
}

export default SuperAdminComponent
