import React, { useEffect, useState, useContext } from 'react';
import {
  CircularProgress,
  Button,
  Select,
  MenuItem,
  TextField,
  Tabs,
  Tab,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MainContext from '../../state/main.context';
import { firebaseApp } from '../../../firebase';
import BackDrop from '../../backdrop.component';
import CreateFormComponent from './create.form.component';
import AssignFormsComponent from './assign.forms.component';
import AdminSubmitFormsComponent from '../../shared_components/adminSubmit.forms.component';
import IncompleteFormsComponent from './incomplete.forms.component';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formBtns: {
    display: 'flex',
    flexDirection: 'row'
  }

}))

const GroupFormsComponent = props => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(false);
  const [ tab, setValue ] = useState(2);
  const [ showTour, setShowTour ] = useState(true);

  const steps = [
    {
      target: '.forms-root',
      content: <div style={{ marginTop: '10vh' }}>
        <p>
          Click next to learn more about this page.
        </p>
      </div>,
      placement: 'top-end',
    },
    {
      target: '.config-form-tab',
      content: 'Access apps listing and edit app information.'
    },
    {
      target: '.create-form-tab',
      content: 'Search for apps that you want to add from over 3,000 EdTech apps in our database.'
    },
    {
      target: '.submit-form-tab',
      content: 'Add individual app information or use our template to perform a bulk upload.  Click on the button to get started.'
    },
    {
      target: '.incomplete-form-tab',
      content: 'View usage data about apps that are being used in your district.'
    }
  ];

  const handleCallback = data => {

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTour(false);
      setTimeout(() => setShowTour(true),500);
    }

  }

  return (
    <div className={`${classes.root} forms-root`}>

      <Joyride
        run={showTour}
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleCallback}
      />

      <Paper className={classes.formBtns} square>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          onChange={(event,newValue) => setValue(newValue) }
        >
          <Tab label="Configure Form" className='config-form-tab' />
          <Tab label="Create Form" className='create-form-tab'  />
          <Tab label="Submit Form" className='submit-form-tab'  />
          <Tab label="Incomplete Forms" className='incomplete-form-tab'  />
        </Tabs>
      </Paper>

      {
        tab === 0 && (
          <AssignFormsComponent />
        )
      }

      {
        tab === 1 && (
          <CreateFormComponent />
        )
      }

      {
        (tab === 2 && mainState.authUser) && (
          <AdminSubmitFormsComponent
            user={mainState.authUser}
          />
        )
      }

      {
        (tab === 3 && mainState.authUser) && (
          <IncompleteFormsComponent
            user={mainState.authUser}
          />
        )
      }

      <BackDrop loading={loading} />
    </div>
  )
}

export default GroupFormsComponent
