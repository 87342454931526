import React, { useEffect, useState, useContext } from 'react';

const FormSubmitted = props => {
  console.dir(props);
  const [ loading, setLoading ] = useState(false);
  return (
    <div>
      <h1>Hello Form</h1>
    </div>
  )
}

export default FormSubmitted
