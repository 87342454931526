import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Modal,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    backgroundColor: 'rgba(128,128,128,0.8)'
  },
  policyframe: {
    flexGrow: 1,
    height: 5000,
    width: 800,
    border: '1px solid black'
  },
  termsframe: {
    flexGrow: 1,
    height: 5000,
    width: 800,
    border: '1px solid #327'
  },
  titles: {
    margin: theme.spacing(1),
    color: '#ffffff',
    fontWeight: 'bold'
  },
  btns: {
    width: 500,
    margin: theme.spacing(1)
  }
}));


const TermsPrivacyAgreement = ({visible,agreementResponse}) => {
  const classes = useStyles();

  if(visible) {
    return (
      <div>
        <Modal 
          open={visible}
          onClose={() => console.log('modal closed.')}
          className={classes.modal}
        >
          <div className={classes.modalContainer}>
            <Typography 
              component="h1" 
              variant="h6" 
              align="center"
              className={classes.titles}
            >Privacy Policy</Typography>
            <iframe
              className={classes.policyframe}
              frameBorder={0}
              src="https://docs.google.com/document/d/e/2PACX-1vQIG9JaJucznEnIzJ3pCMn1AjDtUkmX5f7t7WO1bpE4bkFVIrZEixv3_9A1M2a1nhNnNFeVOkrnV-SZ/pub?embedded=true"
            >
            </iframe>

            <Typography 
              component="h1" 
              variant="h6" 
              align="center"
              className={classes.titles}
            >Terms and Conditions</Typography>
            <iframe
              className={classes.termsframe}
              frameBorder={0}
              src="https://docs.google.com/document/d/e/2PACX-1vS-tlrX0AfXm2UphKjCUNvWEpgsxjuAhQ0XXsEyZ7LB1Zf2QB5tiGBZVDBR_QfFPNxW_693GJqRAsQe/pub?embedded=true"
            >
            </iframe>
            <Button 
              onClick={() => agreementResponse(true)}
              color="primary"
              variant="contained"
              className={classes.btns}
            >I Agree</Button>
            <Button 
              color="default"
              variant="contained"
              className={classes.btns}
              onClick={() => agreementResponse(false)}
            >I Disagree</Button>
          </div>
        </Modal>
      </div>
    )
  }

  return (
    <div></div>
  )

}

export default TermsPrivacyAgreement
