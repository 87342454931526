import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Select,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  InputLabel,
  Switch,
  IconButton
} from '@material-ui/core';
import {
  ArrowDropUp,
  ArrowDropDown
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2)
  },
  divCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    width: '100%'
    // margin: theme.spacing(2)
  },
  form:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  item: {
    margin: theme.spacing(1)
  },
  addressDiv: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row'
  },
  city: {
    flexGrow: 8
  },
  state: {
    flexGrow: 0.5,
    marginLeft: theme.spacing(0.5)
  },
  zip: {
    flexGrow: 1.5,
    marginLeft: theme.spacing(0.5)
  },
  removeBtn: {
    margin: theme.spacing(0.5)
  },
  actionBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  }

}))

const AddressInputComponent = ({ elem, addTitle, removeInput, setRequired, moveItem }) => {

  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);

  return (
    <div className={classes.mainDiv}>
      <div className={classes.divCont}>

        <TextField
          onChange={e => addTitle(elem._id, e.target.value)}
          type="text"
          label="Enter Title/Question"
          variant='outlined'
          className={classes.item}
          defaultValue={elem.title}
        />

        <TextField
          type="text"
          label="Enter Name of Location"
          variant='outlined'
          className={classes.item}
        />

        <TextField
          type="text"
          label="Enter Address"
          variant='outlined'
          multiline
          rows={3}
          className={classes.item}
        />

        <TextField
          type="text"
          label="Suite #"
          variant='outlined'
          className={classes.item}
        />

        <div className={classes.addressDiv}>

          <TextField
            type="text"
            label="City"
            variant='outlined'
            className={classes.city}
          />

          <TextField
            type="text"
            label="State"
            helperText="2 Letter State Code (i.e. IL, CO)"
            variant='outlined'
            className={classes.state}
          />

          <TextField
            type="text"
            label="Zip Code"
            variant='outlined'
            className={classes.zip}
          />

        </div>

        <TextField
          type="text"
          label="Country"
          variant='outlined'
          className={classes.item}
        />

        <FormControlLabel
          control={
            <Switch
              checked={elem.required}
              onChange={e => setRequired(elem._id,!elem.required)}
            />
          }
          label="Required"
        />

      </div>

      <div className={classes.actionBtns}>

        <IconButton
          variant='contained'
          className={classes.removeBtn}
          onClick={() => moveItem(elem._id,'up')}
        >
          <ArrowDropUp />
        </IconButton>
        <Button
          variant='contained'
          color='secondary'
          className={classes.removeBtn}
          onClick={() => removeInput(elem._id)}
        >
        Remove
        </Button>
        <IconButton
          variant='contained'
          className={classes.removeBtn}
          onClick={() => moveItem(elem._id,'down')}
        >
          <ArrowDropDown />
        </IconButton>

      </div>

    </div>
  )
}

export default AddressInputComponent
