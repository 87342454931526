import React, { useEffect, useContext, useState } from 'react';
import MainContext from './state/main.context';
import {
  Button,
  TextField,
  Paper,
  AppBar,
  Toolbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { firebaseApp, provider } from '../firebase';

import CustomHeader from './header.component';
import BackDrop from './backdrop.component';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '10vh',
    padding: '20px'
  },
  button: {
    margin: theme.spacing(1),
    width: '45vw',
    alignSelf: 'center'
  },
  googleBtn: {
    height: '100px',
    margin: theme.spacing(5),
    cursor: 'pointer'
  }
}))

const LoginComponent = props => {
  let { history } = props;
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch, userState, userDispatch } = context;

  const [ loading, setLoading ] = useState(false);
  const [ email, setEmail ] = useState(null);
  const [ password, setPassword ] = useState(null);
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  useEffect(() => {
    //  IF THERE IS A USER AND A USERTYPE HAS BEEN SET
    if(mainState.authUser && mainState.userType) {
      setLoading(false);
      history.push(`/${mainState.userType}`)
    } else {
      setLoading(false);
    }
  }, [ mainState ])


  const submit = async (e) => {

    e.preventDefault();
    e.persist();

    try {

      mainDispatch({ type: 'setLoading', payload: true })

      let initSignin = await firebaseApp.auth().signInWithEmailAndPassword(email, password);

      if(initSignin.hasOwnProperty('user') && initSignin.email === email) {
        mainDispatch({ type: 'setLoading', payload: false });
        setLoading(false);
        return;
      }

      setLoading(false);

    } catch (e) {

      console.log(e);
      mainDispatch({ type: 'setLoading', payload: false });
      setLoading(false);
      setErrorMessage(e.message);
      setShowError(true);

    }

  }

  const initSigninWithGoogle = () => {

    setLoading(true);

    firebaseApp.auth().signInWithPopup(provider).then(function(result) {

      var user = result.user;

      setLoading(false);

    }).catch(function(error) {

      var errorCode = error.code;
      var errorMessage = error.message;
      setErrorMessage(errorMessage);
      setLoading(false);
      setShowError(true);

    });

  }

  return (
    <div className={classes.root}>

      <CustomHeader title='Login' />

      <div className={classes.formContainer}>
        <Paper className={classes.paper}>
        <Typography className={classes.title}>
          LOGIN
        </Typography>
        <form onSubmit={ (event)=> submit(event) } className={classes.form}>
          <TextField
            id="email"
            label="Email"
            type='email'
            onChange={e => setEmail(e.target.value)}
            margin="normal"
            required={true}
          />
          <TextField
            id="password"
            label="Password"
            onChange={e => setPassword(e.target.value)}
            margin="normal"
            required={true}
            type='password'
            error={showError}
            helperText={showError ? errorMessage : null}
          />

          <Button
            type='submit'
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={mainState.isLoading}
          >
            Log In
          </Button>
        </form>

        </Paper>

        <img
          src="https://firebasestorage.googleapis.com/v0/b/approve-it-5c4fc.appspot.com/o/site_assets%2Fbtn_google_signin_dark_focus_web%402x.png?alt=media&token=92b37253-1171-4b37-865e-d226bbf45734"
          className={classes.googleBtn}
          onClick={initSigninWithGoogle}
        />

      </div>

      <BackDrop loading={mainState.isLoading} />
    </div>
  )
}

export default LoginComponent
