import React, { useEffect, useState, useContext } from 'react';
import { firebaseApp } from '../../firebase';

import CustomHeader from '../header.component';
import MainContext from '../state/main.context';

import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Button,
  TextField
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialTable from "material-table";
import BackDrop from '../backdrop.component';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  addAdminContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '80%',
    margin: theme.spacing(1)
  }
}));

const GroupComponent = props => {
  let {
    domain,
    roles,
    groupName
  } = props;
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(true);
  const [ users, setUsers ] = useState([]);
  const [ adminUids, setUids ] = useState([]);
  const [ admins, setAdmins ] = useState([]);
  const [ processing, setProcessing ] = useState(false);
  const [ response, setResponse ] = useState('');
  const [ showResponse, setShowResponse ] = useState(false);

  useEffect(() => {
    //  GET ADMIN UIDS
    let tempUids = Object.keys(roles);
    if(tempUids.length > 0) {
      // setUids(tempUids);
      // fetchAdmins(tempUids);
      return;
    }
    setLoading(false);

  },[ ])

  const fetchAdmins = async (uids) => {
    let options = {
      method: 'Post',
      headers: {
        Authorization: mainState.authUser.uid
      },
      body: JSON.stringify({
        uids: uids,
        domain: mainState.authUser.email.split('@')[1]
      })
    }

    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/getUsers";

    try {

      let initGetUsers = await fetch(url,options);
      if(initGetUsers.ok) {
        let tempAdmins = await initGetUsers.json();
        console.log('admins',tempAdmins);
        setAdmins(tempAdmins);
        setLoading(false);
        return;
      }

      let textRes = await initGetUsers.text();
      console.log('ERROR RESPONSE',textRes);
      setLoading(false);

    } catch (e) {
      console.log('ERROR',e);
      setLoading(false);
    }

  }

  if(loading) {
    return (
      <div className={classes.root}>
        <CircularProgress color="secondary" />
        <p style={{ textAlign: 'center' }}>Please wait...</p>
      </div>
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    setProcessing(true);
    let options = {
      method: 'Post',
      headers: {
        Authorization: mainState.authUser.uid
      },
      body: JSON.stringify({
        email: e.target[0].value,
        domain: mainState.authUser.email.split('@')[1],
        groupName: groupName
      })
    }
     let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/addGroupAdmin";

    try {

      let initAdd = await fetch(url,options);
      let textRes = await initAdd.text();
      setResponse(textRes);
      setShowResponse(true);
      setProcessing(false);
      e.target.reset();
    } catch (e) {
      console.log('ERROR',e);
      setResponse('THERE WAS AN ERROR ADDING ADMIN.  PLEASE TRY AGAIN LATER.');
      setShowResponse(true);
      setProcessing(false);
      e.target.reset();
    }

  }

  const removeAdmin = async (user) => {
    setProcessing(true);
    let options = {
      method: 'Post',
      headers: {
        Authorization: mainState.authUser.uid
      },
      body: JSON.stringify({
        uid: user.uid,
        domain: mainState.authUser.email.split('@')[1],
        groupName: groupName
      })
    }
     let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/removeGroupAdmin";

    try {

      let initAdd = await fetch(url,options);
      let textRes = await initAdd.text();
      setResponse(textRes);
      setShowResponse(true);
      setProcessing(false);

    } catch (e) {
      console.log('ERROR',e);
      setResponse('THERE WAS AN ERROR REMOVING ADMIN.  PLEASE TRY AGAIN LATER.');
      setShowResponse(true);
      setProcessing(false);
    }

  }

  const columns = [
    {
      title: 'Email',
      field: 'email',
      render: rowData => <p>{ rowData.email.toUpperCase() }</p>
    }
  ]

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.addAdminContainer}>
        <TextField
          type='email'
          label='Enter Email Address'
          style={{
            width: '80%'
          }}
          disabled={processing}
        />
        <Button
          color='secondary'
          type='submit'
          disabled={processing}
        >
          Add Admin
        </Button>
      </form>

      {
        showResponse && (
          <p style={{
            margin: '10px',
            textAlign: 'center'
          }}>
            { response }
          </p>
        )
      }

      <MaterialTable
        title="Admins"
        columns={columns}
        data={admins}
        actions={[
          rowData => ({
            icon: () => <DeleteIcon color='secondary' />,
            tooltip: 'Delete Admin',
            onClick: (event, rowData) => removeAdmin(rowData)
          })
        ]}
        style={{
          width: '80%'
        }}
        pageSize={10}
      />

      <BackDrop loading={processing} />
    </div>
  )
}

export default GroupComponent
