import React, {
  useEffect,
  useState
} from 'react';
import {
  Button,
  Typography,
  CardContent,
  Card,
  TextField,
  IconButton,
  Paper,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import HeaderComponent from './header.component';
import FooterComponent from '../footer.component';
import BackDrop from '../backdrop.component.js';
import { useParams } from 'react-router-dom';
import MaterialTable from 'material-table'


const useStyles = makeStyles( theme => ({
  root: {
    paddingTop: '8vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  paperTabs: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  policyframe: {
    marginTop: '10vh',
    flexGrow: 1,
    height: 5000,
    [theme.breakpoints.down('xl')]: {
      width: 840
    },
    [theme.breakpoints.down('sm')]: {
      width: 700
    },
    '@media(max-width: 695px)': {
      width: 600
    },
    '@media(max-width: 525px)': {
      width: 550
    },
    '@media(max-width: 425px)': {
      width: 400
    },
  },
  legalframe: {
    marginTop: '10vh',
    flexGrow: 1,
    height: 18000,
    [theme.breakpoints.down('xl')]: {
      width: 840
    },
    [theme.breakpoints.down('sm')]: {
      width: 700
    },
    '@media(max-width: 695px)': {
      width: 600
    },
    '@media(max-width: 525px)': {
      width: 550
    },
    '@media(max-width: 425px)': {
      width: 400
    },
  }

}));

const LegalDocuments = props => {

  let { history } = props;

  const classes = useStyles();
  const params = useParams();

  const [ loading, setLoading ] = useState(true);
  const [tab, setTab] = React.useState(0);

  useEffect(() => {
    if(params.doc == 'terms') {
      setTab(1);
    } else if(params.doc == 'privacy') {
      setTab(0);
    } else {
      setTab(0);
    }
  },[params.doc])

  const handleChange = (event, newValue) => {
    setTab(newValue);
    switch(newValue) {
      case 0:
        return history.push('/legal/privacy');
      case 1:
        return history.push('/legal/terms');
      default:
        return history.push('legal/privacy');
    }
  };

  return (
    <div className={classes.root}>
      <HeaderComponent title="Legal Documents" />

      <div className={classes.paperTabs}>
        <Paper square>
          <Tabs
            value={tab}
            indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          >
            <Tab label="Privacy Policy" />
            <Tab label="Terms and Conditions" />
          </Tabs>
        </Paper>
      </div>

      {
        tab === 0 && (
          <iframe
            className={classes.policyframe}
            frameBorder={0}
            src="https://docs.google.com/document/d/e/2PACX-1vQIG9JaJucznEnIzJ3pCMn1AjDtUkmX5f7t7WO1bpE4bkFVIrZEixv3_9A1M2a1nhNnNFeVOkrnV-SZ/pub?embedded=true"
          >
          </iframe>
        )
      }

      {
        tab === 1 && (
          <iframe
            className={classes.legalframe}
            frameBorder={0}
            src="https://docs.google.com/document/d/e/2PACX-1vS-tlrX0AfXm2UphKjCUNvWEpgsxjuAhQ0XXsEyZ7LB1Zf2QB5tiGBZVDBR_QfFPNxW_693GJqRAsQe/pub?embedded=true"
          >
          </iframe>
        )
      }


      <FooterComponent />
    </div>
  )
}

export default LegalDocuments
