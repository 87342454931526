import React, { useState } from 'react';
import {
  FormLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
  divCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(2)
  },
  form:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  item: {
    margin: theme.spacing(1)
  },
  removeBtn: {
    margin: theme.spacing(0.5)
  },
  actionBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  label: {
    color: 'black',
    marginBottom: theme.spacing(1)
  },
  info: {
    color: 'red',
    marginBottom: theme.spacing(1)
  }

}))

const DatePreviewComponent = ({ elem }) => {

  const classes = useStyles();

  return (

    <div className={classes.divCont}>

      <FormLabel className={classes.label}>
        {elem.title}
        { elem.required ? " *" : null }
      </FormLabel>

      <DateTimePicker
        label="Start Date and Time"
        inputVariant="outlined"
        className={classes.item}
        // value={selectedDate}
        // onChange={handleDateChange}
      />

      <DateTimePicker
        label="End Date and Time"
        inputVariant="outlined"
        className={classes.item}
        // value={selectedDate}
        // onChange={handleDateChange}
      />

    </div>

  )
}

export default DatePreviewComponent
