import React, { useEffect, useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuList,
  Popper,
  Paper,
  ClickAwayListener,
  MenuItem,
  Grow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import MainContext from './state/main.context';
import { SignOut } from './utilities/firebase.actions';

const useStyles = makeStyles( theme => ({
  toolbar: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  headerTitles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  mainTitle: {
    marginRight: theme.spacing(5),
    cursor: 'pointer'
  },
  loginBtn: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2)
  },
  pageLocation: {
    fontWeight: 'bolder'
  },
  btnGroup: {
    flexGrow: 1,
    display:'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(10)
  },
  menuButton: {
    display: 'flex',
    cursor: 'pointer',
    margin: theme.spacing(2)
  }
}))


const CustomHeader = ({ title }) => {
  const history = useHistory();
  const classes = useStyles();
  const context = useContext(MainContext);

  const [ showBtn, setShowBtn ] = useState(true);
  const [ pageTitle, setPageTitle ] = useState(title);
  const [ showMenu, setShowMenu ] = useState(true);
  const [ open, setOpen ] = useState(false);
  const anchorRef = React.useRef(null);

  let { mainState, mainDispatch } = context;

  useEffect(() => {

    switch(title) {
      case 'Confirm Account | Change Password':
      case 'Sign Up':
      case 'Login':
      case 'Inactive Domain':
      case 'Request Approval':
        setShowBtn(false);
        setShowMenu(false);
        break;

      case 'Requester':
        if(mainState.authUser) {
          setPageTitle(mainState.authUser.displayName);
        }
        break;

      default:
        setShowBtn(true);
    }

  },[])

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClick = () => {
    if(!mainState.authUser || title === 'Home') {
      history.push('/login');
      return;
    }
    mainDispatch({ type: 'reset' });
    //  LOGOUT METHOD
    history.push('/home');
    SignOut();
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (event,page) => {

    if(page) {
      goToPage(page);
    }

    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);

  }

  const goToPage = page => {

    switch(page) {
      case 'manage-it':
        window.open('https://manage-it.app/home');
        break;

      default:
        return;
    }

  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

    return (
      <AppBar position='fixed'>
        <Toolbar className={classes.toolbar}>
          <div className={classes.headerTitles}>
            <Typography
              variant='h6'
              noWrap
              className={classes.mainTitle}
              onClick={() => history.push('/home')}
            >
            Approve iT  |
            </Typography>
            <Typography
              className={classes.pageLocation}
            >
              { pageTitle }
            </Typography>
          </div>

          <div className={classes.btnGroup}>
            {
              showMenu && (
                <div className={classes.menuButton}>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color='inherit'
                  >
                    Solutions
                  </Button>
                  <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={event => handleMenuClose(event,'')}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem onClick={event => handleMenuClose(event,'manage-it')}>Manage iT Extension</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              )
            }


            {
              showMenu && (
                <div className={classes.menuButton}>
                  <Button
                    color='inherit'
                    onClick={() => {
                      history.push('/pricing');
                    }}
                  >
                    Pricing
                  </Button>
                </div>
              )
            }
          </div>


          {
            showBtn ?

              <div className={classes.loginBtn}>
                <Button
                  color='inherit'
                  onClick={handleClick}
                >
                  {(mainState.authUser && title !== 'Home') ? 'Logout' : 'Login'}
                </Button>
              </div>

            : null

          }

        </Toolbar>
      </AppBar>
    )
}

export default CustomHeader;
