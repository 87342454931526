import React, { useState, useEffect, useContext } from 'react';
import {
  Calendar,
  momentLocalizer
} from 'react-big-calendar';
import moment from 'moment';
import MaterialTable from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, Paper } from '@material-ui/core';

import MainContext from '../state/main.context';
import { firebaseApp } from '../../firebase';
import BackDrop from '../backdrop.component';
import ViewRequestComponent from '../redirects/view_request.component';

const localizer = momentLocalizer(moment)

const useStyles = makeStyles(theme => ({

  root: {
    marginTop: '15vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
 modal: {
   backgroundColor: 'white',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   alignItems: 'center',
   padding: theme.spacing(5),
 },
 modelCont: {
   overflow: 'auto',
   backgroundColor: 'white',
   boxShadow: '5px 5px 5px 5px lightgrey',
   margin: '0 auto',
   marginTop: theme.spacing(5),
   paddingTop: theme.spacing(10)
 },
 closeBtn: {
   marginLeft: theme.spacing(1),
   position: 'fixed',
   alignSelf: 'flex-end'
 }

}))

const ViewRequests = props => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(true);
  const [ domain, setDomain ] = useState(null);
  const [ uid, setUid ] = useState(null);
  const [ requests, setRequests ] = useState([]);

  useEffect(() => {
    if(mainState.authUser){
      initComponent();
    }
  },[ mainState ])

  //  GET ALL REQUESTS WHEN DOMAIN EXISTS
  useEffect(() => {
    if(uid) {

      const requestsListener = firebaseApp.firestore().collection('domains').doc(domain)
        .collection('formRequests').where('requesterUid','==',uid).onSnapshot(querySnap => {
         if(querySnap.size > 0) {
           let arr = [];

           querySnap.forEach(snap => {
             let tempData = snap.data();
             tempData._id = snap.id;
             arr.push(tempData);

           });

           if(arr.length > 0) {
             setRequests([...arr]);
           }

         }
      })

      return;
    }

    setLoading(false);

  }, [ uid ]);

  useEffect(() => {
    if(requests.length > 0){
      // console.dir(requests);
    }
  }, [ requests ]);

  const initComponent = () => {

    setDomain(mainState.authUser.email.split('@')[1]);
    setUid(mainState.authUser.uid);

    setLoading(false);

  }

  const handleSelection = (formRequest) => {
    console.log(formRequest);
  }

  const columns = [
    {
      title: 'Request Date',
      field: 'requestDate',
      render: rowData => <p>{ moment(new Date(rowData.requestDate)).format('M-D-YY h:mm a') } </p>
    },
    {
      title: 'Form',
      field: 'formTitle'
    },
    {
      title: 'Requester',
      field: 'requester',
      render: rowData => <p>{

        (rowData.hasOwnProperty('requester') && rowData.requester.hasOwnProperty('displayName') && rowData.requester.displayName) ?
          rowData.requester.displayName.toUpperCase()
        : ""

      }</p>
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData => {

        let approvalResults = rowData.approvers.map(approver => approver.status);
        let status = '';

        if(approvalResults.includes('deny')){
          status="Denied"
        } else if(approvalResults.includes('action required')) {
          status = 'Action Required'
        } else {
          status = 'Approved';
        }

        return (
          <Button
            variant='contained'
            color={
              status === 'Denied' ?
                'secondary'
              : status === 'Approved' ?
                'primary'
              : 'default'
            }
            onClick={() => handleSelection(rowData)}
          >{ status }</Button>
        )
      }
    }

  ]

  return (
    <div className={classes.root}>
      <MaterialTable
        title="All Requests"
        data={requests}
        columns={columns}
        style={{
          width: window.innerWidth * 0.8
        }}
      />

      <BackDrop loading={loading} />
    </div>
  )
}

export default ViewRequests
