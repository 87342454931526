import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Select,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  divCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(2)
  },
  form:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  item: {
    margin: theme.spacing(1)
  },
  removeBtn: {
    marginLeft: theme.spacing(1)
  },
  label: {
    color: 'black'
  }

}))

const ExpensePreview = ({ elem }) => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ quantity, setQuantity ] = useState(0);
  const [ cost, setCost ] = useState(0);
  const [ total, setTotal ] = useState(cost*quantity);

  return (
    <div className={classes.divCont}>

      <FormLabel className={classes.label}>
        {elem.title}
        { elem.required ? " *" : null }
      </FormLabel>

      <form className={classes.form} onSubmit={e => e.preventDefault()}>
        <TextField
          type='number'
          label='Quantity'
          required={ elem.required }
          className={classes.item}
        />

        <FormLabel
        className={classes.item}>X</FormLabel>

        <TextField
          type='number'
          label='Cost'
          required={ elem.required }
          className={classes.item}
        />

        <FormLabel
        className={classes.item}>=</FormLabel>
        <FormLabel
        className={classes.item}>{ total }</FormLabel>
      </form>
    </div>
  )
}

export default ExpensePreview;
