import React, { useEffect, useState, useContext } from 'react';
import { firebaseApp } from '../../firebase';

import MainContext from '../state/main.context';
import BackDrop from '../backdrop.component';

import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  TextField,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialTable from "material-table";

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  resourcePanel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1)
  },
  panelForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '5px 5px 5px lightgrey'
  },
  panelTextFields: {
    margin: theme.spacing(1),
    width: '100%'
  }
}));

const ResourcesComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState } = context;

  const [ loading, setLoading ] = useState(true);
  const [ resources, setResources ] = useState([]);
  const [ resource, setResource ] = useState(null);
  const [ showResource, setShowResource ] = useState(false);
  const [ domain, setDomain ] = useState(null);
  const [ showForm, setShowForm ] = useState(false);
  const [ groups, setGroups ] = useState([]);
  const [ location, setLocation ] = useState('');
  const [ processing, setProcessing ] = useState(false);
  const [ showResponse, setShowResponse ] = useState(false);
  const [ response, setResponse ] = useState('');
  const [ confirmDelete, setConfirmDelete ] = useState(false);
  const [ toDelete, setToDelete ] = useState(null);


  useEffect(() => {

    if(!mainState.authUser) {
      return;
    }
    setLoading(false);

    const tempDomain = mainState.authUser.email.split('@')[1];
    setDomain(tempDomain);

    const resourceListener = firebaseApp.firestore().collection('domains').doc(tempDomain)
      .collection('resources').where('domain','==',tempDomain).onSnapshot(querySnap => {
       if(querySnap.size > 0) {
         let arr = [];
         querySnap.forEach(snap => {
           console.dir(snap.data());
           arr.push(snap.data());
         })
         setResources(arr);
         setLoading(false);
         return;
       }
       setResources([]);
       setLoading(false);
      })

      return () => {
        resourceListener();
      }
  },[])

  useEffect(() => {
    if(mainState.groups.length > 0) {
      setGroups(mainState.groups);
    }
  },[ mainState ])


  if(loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="secondary" />
        <p style={{ textAlign: 'center' }}>Please wait...</p>
      </div>
    )
  }

  const initShowResource = group => {
    setResource(group);
    setShowResource(true);
  }

  const initCloseResource = () => {
    setResource()(null);
    setShowResource(false);
  }

  const columns = [
    {
      title: 'Name',
      field: 'title',
      render: rowData => <p>{ rowData.title.toUpperCase() }</p>
    },
    {
      title: 'Location',
      field: 'location',
      render: rowData => <p>{ rowData.location }</p>
    },
    {
      title: 'Tags',
      field: 'tags',
      render: rowData => <p>{ rowData.tags.join(", ") }</p>
    }
  ]

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    var obj = {};
    obj.randomId = Math.random().toString(36).substr(2,10) + Math.random().toString(36).substr(2,10);
    obj.location = e.target[0].value;
    obj.title = e.target[1].value.toLowerCase();
    obj.desc = e.target[2].value;
    let tempTags = e.target[3].value.split(',');
    obj.tags = tempTags.map(tag => tag.trim());
    obj.tags = obj.tags.filter(tag => tag !== "" );
    obj.domain = domain;
    obj.status = 'active';

    console.dir(obj);

    let auth = mainState.authUser.uid;

    let options = {
      method: 'Post',
      headers: {
        Authorization: auth
      },
      body: JSON.stringify(obj)
    }

    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/addResource";

    try {

      let initAddResource = await fetch(url,options);
      let textRes = await initAddResource.text();
      setResponse(textRes);
      setProcessing(false);
      initShowResponse();
    } catch (e) {
      console.log('ERROR',e);
      setProcessing(false);
    }

  }

  const handleEdit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    var obj = {};
    obj.title = e.target[0].value.toLowerCase();
    let tempTags = e.target[2].value.split(',');
    obj.tags = tempTags.map(tag => tag.trim());
    obj.tags = obj.tags.filter(tag => tag !== "" );
    obj.desc = e.target[4].value;
    obj.domain = domain;

    console.dir(obj);

    let auth = mainState.authUser.uid;

    let options = {
      method: 'Post',
      headers: {
        Authorization: auth
      },
      body: JSON.stringify(obj)
    }

    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/editResource";

    try {

      let initEditResource = await fetch(url,options);
      let textRes = await initEditResource.text();
      setResponse(textRes);
      setProcessing(false);
      initShowResponse();
    } catch (e) {
      console.log('ERROR',e);
      setProcessing(false);
    }

  }

  const initDeleteResource = trans => {
    setToDelete(trans);
    setConfirmDelete(true);
  }

  const deleteResponse = bool => {
    setConfirmDelete(false);
    if(bool) {
      doDelete();
      return;
    }
    setToDelete(null);
  }

  const doDelete = async () => {
    setProcessing(true);
    console.log('toDelete',toDelete);

    let options = {
      method: 'Post',
      headers: {
        Authorization: mainState.authUser.uid
      },
      body: JSON.stringify(toDelete)
    }
    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/deleteResource";

    try {

      let initDeleteResource = await fetch(url,options);
      let initRes = await initDeleteResource.text();
      console.log(initRes);
      setResponse(initRes);
      setProcessing(false);
      setToDelete(null);
      initShowResponse();
    } catch (e) {
      console.log('ERROR',e);
      setProcessing(false);
      setToDelete(null);
    }


  }

  const initShowResponse = () => {
    setShowResponse(true);
    setTimeout(() => {
      setShowResponse(false);
    },4000)

  }

  const AddResource = props => (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '50vw'
      }}
      onSubmit={handleSubmit}
    >

      <Select
        value={location}
        displayEmpty
        onChange={e => setLocation(e.target.value)}
        className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="" disabled>
          Assign Resource Location
        </MenuItem>
        <MenuItem value='org'>ORGANIZATION</MenuItem>
        {
          groups.length > 0 && (
            groups.map(group =>
              <MenuItem value={group.groupName} key={group.groupName}>
                { group.groupName.toUpperCase() }
              </MenuItem>
            )
          )
        }
      </Select>

      <TextField label='Resource Name' type='text' required/>

      <TextField
        label='Resource Description'
        multiline
        rows={5}
      />

      <TextField
        label='Enter Tags/Categories'
        type='text'
        helperText="Tags help categorize the resource.  You can enter as many as you would like.  Separate each entry with a comma (i.e. computing, tools, laptop, loaner)."
      />

      {
        showResponse ?
          <p style={{margin: '10px', textAlign: 'center'}}>{ response }</p>
        : null
      }

      <Button style={{marginTop: '10px',marginBottom: '10px'}} variant='contained' color='secondary' type='submit'>
        Submit
      </Button>
    </form>
  )

  const ResourcePanel = (props) => (
    <div className={classes.resourcePanel}>

      <h3>Description</h3>
      <p style={{
        whiteSpace: 'pre-line',
        whiteSpace: 'pre-wrap'
      }}> { props.desc } </p>

      <hr />

      <Button
        color='primary'
      >
        Edit Resource
      </Button>

      <form
        onSubmit={handleEdit}
        className={classes.panelForm}>

        <TextField
          id='new_title'
          variant='filled'
          label='Title (Uneditable)'
          disabled
          defaultValue={props.title}
          className={classes.panelTextFields}
        />

        <TextField
          id='location'
          variant='filled'
          disabled
          label='Location (Uneditable)'
          defaultValue={props.location}
          className={classes.panelTextFields}
        />

        <TextField
          id='new_tags'
          variant='outlined'
          label='Update Tags'
          defaultValue={props.tags.join(',')}
          className={classes.panelTextFields}
        />

        <TextField
          id='new_desc'
          variant='outlined'
          label='Update Description'
          multiline
          rows={5}
          defaultValue={props.desc}
          className={classes.panelTextFields}
        />

        <Button
          color='primary'
          type='submit'
        >
          Submit Edit
        </Button>
      </form>

    </div>
  )

  return (
    <div className={classes.root}>
      <Button color='primary' onClick={() => setShowForm(!showForm)}>Add Resource</Button>
      {
        showForm ?
          <AddResource />
        : null
      }

      <MaterialTable
        title="Resources"
        columns={columns}
        data={resources}
        actions={[
          {
            icon: () => <DeleteIcon color='secondary' />,
            tooltip: 'Delete Resource',
            onClick: (event, rowData) => { initDeleteResource(rowData) }
          }
        ]}
        detailPanel={ rowData =>
          <ResourcePanel {...rowData} />
        }
        style={{
          width: window.innerWidth * 0.6
        }}
        pageSize={10}
      />

      <BackDrop loading={processing} />

      <Dialog
        open={confirmDelete}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to remove { toDelete ? toDelete.title : '' }
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => deleteResponse(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => deleteResponse(true)} color="primary" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ResourcesComponent
