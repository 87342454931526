import React, { useState, useEffect, useContext } from 'react';
import {
  Calendar,
  momentLocalizer
} from 'react-big-calendar';
import moment from 'moment';
import MaterialTable from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button, Paper, Typography } from '@material-ui/core';

import MainContext from '../state/main.context';
import { firebaseApp } from '../../firebase';
import BackDrop from '../backdrop.component';
import ViewRequestComponent from '../redirects/view_request.component';

const localizer = momentLocalizer(moment)

const useStyles = makeStyles(theme => ({

  root: {
    marginTop: '15vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
 modal: {
   backgroundColor: 'white',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   alignItems: 'center',
   padding: theme.spacing(5),
 },
 modelCont: {
   overflow: 'auto',
   backgroundColor: 'white',
   boxShadow: '5px 5px 5px 5px lightgrey',
   margin: '0 auto',
   marginTop: theme.spacing(5),
   paddingTop: theme.spacing(10)
 },
 closeBtn: {
   marginLeft: theme.spacing(1),
   position: 'fixed',
   alignSelf: 'flex-end'
 },
 detailPanel: {
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'flex-start',
   padding: theme.spacing(2)
 },
 detailItem: {
   margin: theme.spacing(1)
 }

}))

const GroupRequests = props => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(true);
  const [ domain, setDomain ] = useState(null);
  const [ uid, setUid ] = useState(null);
  const [ groups, setGroups ] = useState([]);
  const [ requests, setRequests ] = useState([]);
  const [ viewRequest, setViewRequest ] = useState(null);
  const [ showRequest, setShowRequest ] = useState(false);

  useEffect(() => {
    if(mainState.authUser){
      initComponent();
    }
  },[ mainState ])

  //  GET ALL REQUESTS WHEN DOMAIN EXISTS
  useEffect(() => {

    if(groups.length > 0) {

      const groupRequests = firebaseApp.firestore().collection('domains').doc(domain)
        .collection('formRequests').where('group','in',groups).onSnapshot(querySnap => {
         if(querySnap.size > 0) {
           let arr = [];

           querySnap.forEach(snap => {
             let tempData = snap.data();
             tempData._id = snap.id;
             arr.push(tempData);

           });

           if(arr.length > 0) {
             setRequests([...arr]);
           }

         }
      })

      setLoading(false);

      return () => groupRequests();
    }

    setLoading(false);

  }, [ groups ]);

  useEffect(() => {
    if(domain) {

      const requestWithUserUid = firebaseApp.firestore().collection('domains').doc(domain)
        .collection('formRequests').where('approverUIDs','array-contains',uid).onSnapshot(querySnap => {

         if(querySnap.size > 0) {

           let arr = [];

           querySnap.forEach(snap => {
             let tempData = snap.data();
             tempData._id = snap.id;
             arr.push(tempData);

           });

           if(arr.length > 0) {
             setRequests([...requests,...arr]);
           }

         }
      })

      setLoading(false);

      return () => requestWithUserUid();

    }
  }, [ domain ]);

  useEffect(() => {
    if(requests.length > 0){
      // console.dir(requests);
    }
  }, [ requests ]);

  const initComponent = () => {

    setDomain(mainState.authUser.email.split('@')[1]);
    setUid(mainState.authUser.uid);

    //  SET GROUPS
    if(mainState.hasOwnProperty('groups') && mainState.groups.length > 0){
      let tempGroups = mainState.groups.reduce((acc,curr) => {
        // console.log('curr: ', curr);
        // console.log('acc',acc);
        acc.push(curr.groupName);
        // console.log('acc after',acc);
        return acc;
      },[]);
      setGroups([...tempGroups]);

      return;
    }

    setLoading(false);

  }

  const handleSelection = (rowData) => {

    let approvalUrl = `/approvalPage/${rowData.domain}/${rowData._id}/${uid}`;
    let requestStatus = `/approvalPage/${rowData.domain}/${rowData._id}/${uid}`;

    window.open(approvalUrl);

  }

  const columns = [
    {
      title: 'Request Date',
      field: 'requestDate',
      render: rowData => <p>{ moment(new Date(rowData.requestDate)).format('M-D-YY h:mm a') } </p>
    },
    {
      title: 'Form',
      field: 'formTitle'
    },
    {
      title: 'Requester',
      field: 'requester',
      render: rowData => <p>{ rowData.requester.displayName.toUpperCase() }</p>
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData => {

        let approvalResults = rowData.approvers.map(approver => approver.status);
        let status = '';

        if(approvalResults.includes('deny')){
          status="Denied"
        } else if(approvalResults.includes('action required')) {
          status = 'Action Required'
        } else {
          status = 'Approved';
        }

        return (
          <Button
            variant='contained'
            color={
              status === 'Denied' ?
                'secondary'
              : status === 'Approved' ?
                'primary'
              : 'default'
            }
            onClick={() => handleSelection(rowData)}
          >{ status }</Button>
        )
      }
    }

  ]

  return (
    <div className={classes.root}>
      <MaterialTable
        title="All Requests"
        data={requests}
        columns={columns}
        style={{
          width: window.innerWidth * 0.8
        }}
        detailPanel={rowData => {
          if(!rowData.administrativeInfo) {
            return(
              <div className={classes.detailPanel}>
                <Typography variant="body1" component="h1">No Additional Info</Typography>
              </div>
            )
          }

          return (
            <div className={classes.detailPanel}>
              <Typography variant="body1" component="h1">PO: {rowData.administrativeInfo.po}</Typography>
              <Typography variant="body1" component="h1">Account #: {rowData.administrativeInfo.account_number}</Typography>
              <Typography variant="body1" component="h1">Notes: {rowData.administrativeInfo.notes}</Typography>
            </div>
          )
        }}
      />

      <Modal
        className={classes.modal}
        open={showRequest}
        onClick={() => console.log('close with button') }
        pageSize={10}
        BackdropProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: 'white',
            padding: '10vw'
          }
        }}
      >

        <div className={classes.modelCont}>

        <Button
          color='primary'
          variant='contained'
          onClick={() => setShowRequest(false) }
          className={classes.closeBtn}
        >
          Close
        </Button>

        {
          viewRequest && (
              <ViewRequestComponent
                requestData={viewRequest}
              />
          )
        }

        </div>

      </Modal>


      <BackDrop loading={loading} />
    </div>
  )
}

export default GroupRequests
