import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  useHistory,
  useLocation
} from 'react-router-dom'

import GlobalState from './app/state/global.state';
import LandingPageComponent from './app/landing_page.component';
import HomeComponent from './app/home.component';
import LoginComponent from './app/login.component'
import PricingComponent from './app/pricing.component';
import ContactUsComponent from './app/contact_us.component';
import SuperAdminComponent from './app/super_admin/super_admin.component';
import ChangePasswordComponent from './app/redirects/change_password.component';
import FinalizaAccountComponent from './app/redirects/finalize-account.component';
import ApprovalPageComponent from './app/redirects/approval_page.component';
import InactiveDomainComponent from './app/redirects/inactive_domain.component';
import RequestStatusComponent from './app/redirects/request_status.component';
import SignUpComponent from './app/sign_up.component';
import OrgAdminComponent from './app/org_admin/org_admin.component';
import GroupComponent from './app/org_admin/group.component';
import GroupAdminComponent from './app/group_admin/group_admin.component';
import DefaultUserComponent from './app/default_user/default.component';
import FormSubmitted from './app/group_admin/form.submit';
import FormComponent from './app/user_forms/form.component';
import NoPageComponent from './app/noPageFound.component';
import LegalDocuments from './app/policies/legal.docs.component';

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// CUSTOM THEME
const theme = createMuiTheme({"palette":{"common":{"black":"#000","white":"#fff"},"background":{"paper":"#fff","default":"#fafafa"},"primary":{"light":"rgba(100, 70, 167, 1)","main":"rgba(51, 29, 119, 1)","dark":"rgba(0, 0, 74, 1)","contrastText":"#fff"},"secondary":{"light":"rgba(200, 233, 90, 1)","main":"rgba(148, 183, 37, 1)","dark":"rgba(98, 135, 0, 1)","contrastText":"#fff"},"error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},"text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}})

const App = props => {

  useEffect(() => {
    document.title = "Approve iT";
  },[]);

  return (
    <GlobalState>
      <Router>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Switch>
              <Route path='/' exact={true} component={LandingPageComponent} />
              <Route path='/home' component={HomeComponent} />
              <Route path='/login' component={LoginComponent} />
              <Route path='/pricing' component={PricingComponent} />
              <Route path='/contact-us' component={ContactUsComponent} />
              <Route path='/super-admin' component={SuperAdminComponent} />
              <Route path='/org-admin' component={OrgAdminComponent} />
              <Route path='/group/' component={GroupComponent} />
              <Route path='/group-admin' component={GroupAdminComponent} />
              <Route path='/default-user' component={DefaultUserComponent} />
              <Route path='/inactive-domain' component={InactiveDomainComponent} />
              <Route path='/form-submit' component={FormSubmitted} />
              <Route path='/form/:group/:formId' component={FormComponent} />
              <Route path='/change-password/:uid/:email' component={ChangePasswordComponent} />
              <Route path='/finalize-account/:email' component={FinalizaAccountComponent} />
              <Route path='/legal/:doc' component={LegalDocuments} />
              <Route
                path='/approvalPage/:domain/:formRequest/:uid'
                component={ApprovalPageComponent}
              />
              <Route
                path='/request-status/:domain/:formRequest/:uid'
                component={RequestStatusComponent}
              />
              <Route path='/signup' component={SignUpComponent} />
              <Route component={NoPageComponent} />
            </Switch>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Router>
    </GlobalState>
  )
}

export default App;
