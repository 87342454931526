import React from "react";
import {
  Button,
  Select,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  InputLabel,
  Switch,
  IconButton
} from '@material-ui/core';
import {
  ArrowDropUp,
  ArrowDropDown
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import ExpenseInput from './expense.input.component';
import DateInputComponent from './date.input.component';
import AddressInputComponent from './address.input.component';

const useStyles = makeStyles(theme => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2)
  },
  divCont: {
    flexGrow: 9,
    display: "flex",
    flexDirection: "column",
    // margin: theme.spacing(2)
  },
  item: {
    marginTop: theme.spacing(1)
  },
  removeBtn: {
    margin: theme.spacing(0.5)
  },
  actionBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  }

}))

const InputComponents = ({
  elem, addTitle,
  addOptions, removeInput,
  setRequired, moveItem }) => {

  const classes = useStyles();

  switch (elem.type) {

    case 'address':
      return (
        <AddressInputComponent
          elem={elem}
          addTitle={addTitle}
          removeInput={removeInput}
          setRequired={setRequired}
          moveItem={moveItem}
        />
      )

    case 'date':
      return (
        <DateInputComponent
          elem={elem}
          addTitle={addTitle}
          removeInput={removeInput}
          setRequired={setRequired}
          moveItem={moveItem}
        />
      )

    case 'expense':
      return (
        <ExpenseInput
          elem={elem}
          addTitle={addTitle}
          removeInput={removeInput}
          setRequired={setRequired}
          moveItem={moveItem}
        />
      )

    case "textarea":
      return (
        <div className={classes.mainDiv}>
          <div className={classes.divCont}>
            <TextField
              onChange={e => addTitle(elem._id, e.target.value)}
              type="text"
              label="Enter Title/Question"
              variant='outlined'
              defaultValue={elem.title}
            />

            <TextField
              label="Response"
              multiline
              rows={5}
              variant="outlined"
              className={classes.item}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={elem.required}
                  onChange={e => setRequired(elem._id,!elem.required)}
                />
              }
              label="Required"
            />
          </div>

          <div className={classes.actionBtns}>

            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'up')}
            >
              <ArrowDropUp />
            </IconButton>
            <Button
              variant='contained'
              color='secondary'
              className={classes.removeBtn}
              onClick={() => removeInput(elem._id)}
            >
            Remove
            </Button>
            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'down')}
            >
              <ArrowDropDown />
            </IconButton>

          </div>
        </div>
      );

    case "text":
      return (
        <div className={classes.mainDiv}>
          <div className={classes.divCont}>
            <TextField
              onChange={e => addTitle(elem._id, e.target.value)}
              type="text"
              label="Enter Title/Question"
              variant='outlined'
              defaultValue={elem.title}
            />
            <TextField
              label='Response'
              variant="outlined"
              className={classes.item}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={elem.required}
                  onChange={e => setRequired(elem._id,!elem.required)}
                />
              }
              label="Required"
            />
          </div>
          <div className={classes.actionBtns}>

            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'up')}
            >
              <ArrowDropUp />
            </IconButton>
            <Button
              variant='contained'
              color='secondary'
              className={classes.removeBtn}
              onClick={() => removeInput(elem._id)}
            >
            Remove
            </Button>
            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'down')}
            >
              <ArrowDropDown />
            </IconButton>

          </div>
        </div>
      );

    case "number":
      return (
        <div className={classes.mainDiv}>
          <div className={classes.divCont}>
            <TextField
              onChange={e => addTitle(elem._id, e.target.value)}
              type="text"
              label="Enter Title/Question"
              variant='outlined'
              defaultValue={elem.title}
            />
            <TextField
              label="Enter Number"
              variant="outlined"
              type="number"
              className={classes.item}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={elem.required}
                  onChange={e => setRequired(elem._id,!elem.required)}
                />
              }
              label="Required"
            />
          </div>
          <div className={classes.actionBtns}>

            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'up')}
            >
              <ArrowDropUp />
            </IconButton>
            <Button
              variant='contained'
              color='secondary'
              className={classes.removeBtn}
              onClick={() => removeInput(elem._id)}
            >
            Remove
            </Button>
            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'down')}
            >
              <ArrowDropDown />
            </IconButton>

          </div>
        </div>
      );

    case "radio":
      return (
        <div className={classes.mainDiv}>
          <div  className={classes.divCont}>
            <TextField
              onChange={e => addTitle(elem._id, e.target.value)}
              type="text"
              label="Enter Title/Question"
              variant='outlined'
              defaultValue={elem.title}
            />

            <TextField
              onChange={e => addOptions(elem._id, e.target.value)}
              type="text"
              label="Enter options separated by a comma."
              variant='outlined'
              className={classes.item}
              defaultValue={elem.options.join(',')}
            />

            <FormControl component='fieldset'
              className={classes.item}>
              <RadioGroup name={elem._id}>
                {
                  elem.options.length > 0 && (
                    elem.options.map(option =>
                      <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                    )
                  )
                }
              </RadioGroup>
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={elem.required}
                  onChange={e => setRequired(elem._id,!elem.required)}
                />
              }
              label="Required"
            />
          </div>
          <div className={classes.actionBtns}>

            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'up')}
            >
              <ArrowDropUp />
            </IconButton>
            <Button
              variant='contained'
              color='secondary'
              className={classes.removeBtn}
              onClick={() => removeInput(elem._id)}
            >
            Remove
            </Button>
            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'down')}
            >
              <ArrowDropDown />
            </IconButton>

          </div>
        </div>
      );

    case "checkbox":
      return (
        <div className={classes.mainDiv}>
          <div  className={classes.divCont}>
            <TextField
              onChange={e => addTitle(elem._id, e.target.value)}
              type="text"
              label="Enter Title/Question"
              variant='outlined'
              defaultValue={elem.title}
            />

            <TextField
              onChange={e => addOptions(elem._id, e.target.value)}
              type="text"
              label="Enter options separated by a comma."
              variant='outlined'
              className={classes.item}
              defaultValue={elem.options.join(',')}
            />

            {
              elem.options.length > 0 && (

                elem.options.map(option =>
                  <FormControlLabel
                    key={option}
                    control={<Checkbox />}
                    label={option}
                  />
                )

              )
            }

            <FormControlLabel
              control={
                <Switch
                  checked={elem.required}
                  onChange={e => setRequired(elem._id,!elem.required)}
                />
              }
              label="Required"
            />

          </div>
          <div className={classes.actionBtns}>

            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'up')}
            >
              <ArrowDropUp />
            </IconButton>
            <Button
              variant='contained'
              color='secondary'
              className={classes.removeBtn}
              onClick={() => removeInput(elem._id)}
            >
            Remove
            </Button>
            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'down')}
            >
              <ArrowDropDown />
            </IconButton>

          </div>
        </div>
      );

    case "select":
      return (
        <div className={classes.mainDiv}>
          <div  className={classes.divCont}>
            <TextField
              onChange={e => addTitle(elem._id, e.target.value)}
              type="text"
              label="Enter Title/Question"
              variant='outlined'
              defaultValue={elem.title}
            />

            <TextField
              onChange={e => addOptions(elem._id, e.target.value)}
              type="text"
              label="Enter options separated by a comma."
              variant='outlined'
              className={classes.item}
              defaultValue={elem.options.join(',')}
            />

            <FormControl
              className={classes.item}>
              <InputLabel>Select Option</InputLabel>
              <Select>
                {
                  elem.options.length > 0 && (
                    elem.options.map(option =>
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    )
                  )
                }
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={elem.required}
                  onChange={e => setRequired(elem._id,!elem.required)}
                />
              }
              label="Required"
            />
          </div>
          <div className={classes.actionBtns}>

            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'up')}
            >
              <ArrowDropUp />
            </IconButton>
            <Button
              variant='contained'
              color='secondary'
              className={classes.removeBtn}
              onClick={() => removeInput(elem._id)}
            >
            Remove
            </Button>
            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'down')}
            >
              <ArrowDropDown />
            </IconButton>

          </div>
        </div>
      );

    case "file":
      return (
        <div className={classes.mainDiv}>
          <div className={classes.divCont}>

            <TextField
              onChange={e => addTitle(elem._id, e.target.value)}
              type="text"
              label="Enter Title/Question"
              variant='outlined'
              defaultValue={elem.title}
            />

            <TextField
              type="file"
              variant='outlined'
            />

            <FormControlLabel
              control={
                <Switch
                  checked={elem.required}
                  onChange={e => setRequired(elem._id,!elem.required)}
                />
              }
              label="Required"
            />
          </div>
          <div className={classes.actionBtns}>

            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'up')}
            >
              <ArrowDropUp />
            </IconButton>
            <Button
              variant='contained'
              color='secondary'
              className={classes.removeBtn}
              onClick={() => removeInput(elem._id)}
            >
            Remove
            </Button>
            <IconButton
              variant='contained'
              className={classes.removeBtn}
              onClick={() => moveItem(elem._id,'down')}
            >
              <ArrowDropDown />
            </IconButton>

          </div>
        </div>
      );

    default:
      return null;
  }
};

export default InputComponents;
