import React, { useEffect, useState, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Paper,
  Modal,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { firebaseApp } from '../../firebase';
import InputsHandler from './admin_form_components/adminInputs.handler.component';
import BackDrop from '../backdrop.component';
import MainContext from '../state/main.context';

const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '10vh',
    width: '80vw',
    alignSelf: 'center'
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px'
  },
  formListItem: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(2)
  },
  accordionSummary: {
    backgroundColor: fade(theme.palette.primary.main, 0.55)
  },
  accordionHeading: {
    fontWeight: 'bold',
    color: '#ffffff'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  copyLink: {
    marginLeft: theme.spacing(2),
    cursor: 'pointer'
  },
  snackbar: {
    backgroundColor: fade(theme.palette.primary.main,0.65),
    color: '#ffffff'
  }
}))

const FormModel = ({
  formSelected,
  form,
  handleSubmit,
  handleResponse,
  setUploadingFile,
  cancelForm
}) => {
  const classes = useStyles();
  const [ typing, setTyping ] = useState(false);

  const initSetTyping = () => {
    setTyping(true);
    setTimeout(() => setTyping(false),1000);
  }

  if(!formSelected) {
    return (
      <Typography>Please select a group and then a form.</Typography>
    )
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
      onReset={cancelForm}
    >
      <Typography className={classes.formTitle}>{formSelected.title}</Typography>
      {
        form.map((elem,i) =>
          <InputsHandler
            key={elem._id}
            elem={elem}
            handleResponse={handleResponse}
            toggleTyping={initSetTyping}
            setUploadingFile={setUploadingFile}
          />
        )
      }

      <Button
        className={classes.submitBtn}
        color='primary'
        variant="contained"
        type='submit'
        disabled={typing}
      >
        Submit
      </Button>
      <Button
        className={classes.submitBtn}
        color='secondary'
        variant="contained"
        type='reset'
      >
        Cancel
      </Button>
    </form>
  )
}

const AdminSubmitFormsComponent = ({ user }) => {
  const context = useContext(MainContext);
  let { mainState } = context;
  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const [ allGroups, setAllGroups ] = useState([]);
  const [ allForms, setAllForms ] = useState({});
  const [ open, setOpen ] = useState(false);

  useEffect(() => {

    getAllGroups();

  },[]);


  useEffect(() => {

    if(allGroups.length > 0) {
      getAllForms();
    }

  },[ allGroups ]);

  const getAllGroups = () => {

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('groups').get()
      .then(snapshots => {
        let tempArr = [];

        snapshots.forEach(snap => tempArr.push(snap.data()))
        setAllGroups([ ...tempArr ]);
        setLoading(false);
      })
      .catch(err => {
        console.log('error',err);
        setLoading(false);
        alert('There was an error.  Please try again later.');
      })
  }

  const getAllForms = async () => {

    setLoading(true);

    let promises = [];

    let tempGroups = allGroups;
    tempGroups.push({
      groupName: 'test name'
    })

    allGroups.forEach(async (group) => {
      promises.push(
        firebaseApp.firestore().collection('domains').doc(mainState.domain)
          .collection('groups').doc(group.groupName)
          .collection('forms').get()
      )
    })

    let allPromised = await Promise.all(promises);

    let formsObj = {};

    allPromised.forEach(result => {
      if(!result.empty) {
        result.docs.forEach(doc => {
          let group = doc.ref.path.split('/')[3];
          if(formsObj.hasOwnProperty(group)) {
            formsObj[group].forms.push(doc.data());
          } else {
            formsObj[group] = {
              forms: []
            }

            formsObj[group].forms.push(doc.data())
          }
        })
      }
    });  //  FOR EACH RESULT

    setAllForms(formsObj);
    setLoading(false);

  }

  const copyToClipboard = async (url) => {
    let uri = encodeURI(url)
    let initCopy = await navigator.clipboard.writeText(uri);
    setOpen(true);
  }

  return (
    <div className={classes.root}>

      <Typography
        variant='h4'
        component="h1"
        className={classes.title}
      >Available Forms</Typography>

      {
        (Object.keys(allForms).length > 0) && (
            <div>
            {
                Object.keys(allForms).map((key,i) => {

                  let group = key;

                  return (
                    <Accordion key={key}>
                      <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon style={{backgroundColor: 'white'}} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography
                          className={classes.accordionHeading}
                        >{key.toUpperCase()}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        {
                          allForms[key].forms.map((form,i) => {
                            let formId = form._id;
                            let url = `https://approveit.app/form/${group}/${formId}`;
                            return (
                              <div key={formId} className={classes.linkContainer}>
                                <Typography
                                  className={classes.formListItem}
                                  onClick={() => {
                                    window.open(url);
                                  }}
                                >{form.title}</Typography>
                                <Typography
                                  className={classes.copyLink}
                                  color="primary"
                                  onClick={() => {
                                    copyToClipboard(url);
                                  }}
                                >Copy Link</Typography>
                              </div>
                            )
                          })
                        }
                      </AccordionDetails>
                    </Accordion>
                  )
                })
            }
            </div>
        )
      }

      <Snackbar
       className={classes.snackbar}
       anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'left',
       }}
       open={open}
       onClose={() => {
         setOpen(false);
       }}
       autoHideDuration={5000}
       message="Copied to clipboard."
     />
      <BackDrop loading={loading} />
    </div>
  )
}

export default AdminSubmitFormsComponent
