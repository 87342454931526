import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Paper,
  Modal,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { firebaseApp } from '../../firebase';
import InputsHandler from '../user_forms/inputs.handler.component';
import ErrorDialog from '../shared_components/error.dialog.component';
import ConfirmDialog from '../shared_components/confirm.dialog.component';
import BackDrop from '../backdrop.component';
import MainContext from '../state/main.context';
import LoginComponent from '../login_no_redirect.component';
import HeaderComponent from './header.component';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '10vh',
    width: '80vw',
    alignSelf: 'center'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150
  },
  formPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginBottom: '10vh'
  },
  formPaperRecipient: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  recipientForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  optionsMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%'
  },
  formTitle: {
    textAlign: 'center',
    margin: theme.spacing(1)
  },
  submitBtn: {
    margin: theme.spacing(2),
    width: '60%'
  },
  item: {
    width: '80%'
  },
  textCentered: {
    textAlign: 'center'
  },
  recipientBtn: {
    margin: theme.spacing(1)
  }

}))

const FormModel = ({
  formData,
  form,
  handleSubmit,
  handleResponse,
  setUploadingFile,
  cancelForm
}) => {
  const classes = useStyles();
  const [ typing, setTyping ] = useState(false);

  const initSetTyping = () => {
    setTyping(true);
    setTimeout(() => setTyping(false),1000);
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
      onReset={cancelForm}
    >
      <Typography className={classes.formTitle}>{formData.title}</Typography>
      {
        form.map((elem,i) =>
          <InputsHandler
            key={elem._id}
            elem={elem}
            handleResponse={handleResponse}
            toggleTyping={initSetTyping}
            setUploadingFile={setUploadingFile}
          />
        )
      }

      <Button
        className={classes.submitBtn}
        color='primary'
        variant="contained"
        type='submit'
        disabled={typing}
      >
        Submit
      </Button>
      <Button
        className={classes.submitBtn}
        color='secondary'
        variant="contained"
        type='reset'
        onClick={cancelForm}
      >
        Cancel
      </Button>
    </form>
  )
}

const FormComponent = props => {
  const classes = useStyles();
  const params = useParams();
  const context = useContext(MainContext);
  let { mainState } = context;
  const [ loading, setLoading ] = useState(false);
  const [ formData, setFormData ] = useState(null);
  const [ form, setForm ] = useState(null);
  const [ confirmMessage, setConfirmMessage ] = useState('');
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ submittingForm, setSubmittingForm ] = useState(false);
  const [ uploadingFile, setUploadingFile ] = useState(false);
  const [ tempFormRef, setTempFormRef ] = useState(null);
  const [ savingProgress, setSavingProgress ] = useState(false);
  const [ formSubmitted, setFormSubmitted ] = useState(false);
  const [ invalidRecipient, setInvalidRecipient ] = useState(false);
  const [ validRecipient, setRecipientValid ] = useState(false);
  const [ recipient, setRecipient ] = useState('');

  useEffect(() => {
    if(!mainState.authUser) {
      setLoading(false);
      return;
    }
    const getForm = firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('groups').doc(params.group)
      .collection('forms').doc(params.formId)
      .get()
      .then(snapshot => {
        if(!snapshot.exists) {
          setLoading(false);
          alert('The form does not exist.  Please contact your administrator.');
          return;
        }

        let tempForm = snapshot.data();

        console.dir(tempForm);

        //  ADD RESPONSE KEY OBJECT TO EACH ELEMENT
        tempForm.form.forEach(item => {
          switch(item.type) {
            case 'text':
            case 'number':
            case 'textarea':
            case 'radio':
            case 'select':
              item.response = '';
              break;
            case 'checkbox':
              item.response = item.options.reduce((acc,val) => {
                console.log(acc);
                acc[val] = false;
                return acc;
              },{})
              break;
            case 'address':
              item.response = {
                location: '',
                street: '',
                city: '',
                state: '',
                zip: '',
                country: ''
              }
              break;
            case 'date':
              item.response = {
                start: null,
                end: null
              }
              break;
            case 'expense':
              item.response = {
                cost: 0,
                quantity: 0,
                total: 0.00
              }
              break;
          }
        })

        setForm(tempForm.form);
        setFormData(tempForm);
        saveTempForm(tempForm,params.group);
      })
      .catch(err => {
        setLoading(false);
        console.dir(err);
        alert("Error locating form.");
      })

      return () => getForm();

  },[mainState.authUser])

  const promptMissingRequired = () => {
    setErrorMessage('One or more required(*) form items are missing.  Please ensure that you complete the form thoroughly.');
    setShowError(true);
    setTimeout(()=> {
      setShowError(false);
    }, 2000)
  }

  const initConfirm = () => {
    setShowConfirm(true);
    setTimeout(() => {
      setShowConfirm(false)
    },1000)
  }

  const initError = () => {
    setShowError(true);
    setTimeout(() => setShowError(false),1000)
  }

  const cancelForm = e => {
    //  DELETE ANY FILES ASSOCIATED WITH THIS FORM
    form.forEach(item => {
      if(item.type === 'file') {
        deleteFile(item.response.fullPath);
      }
    });

    deleteTempForm();
    window.close();
  }

  const deleteFile = (path) => {
    let fileRef = firebaseApp.storage().ref().child(path);
    fileRef.delete()
    .then(() => {
      console.log("Form cancelled and associated file successfully deleted.");
    })
    .catch(err => console.dir(err));
  }

  const deleteTempForm = () => {
    firebaseApp.firestore().collection('incompleteForms')
      .doc(mainState.authUser.uid).collection('tempForms').doc(tempFormRef)
      .delete()
      .then(() => {
        console.log('Successfully deleted form.');
      })
      .catch(err => {
        console.log('Error updating tempForm.');
        console.dir(err);
      })
  }

  const handleResponse = (elemType,id,res) => {

    let tempForm = form;

    switch(elemType) {

      case 'file':

        tempForm.some(item => {
          if(id == item._id) {
            item.response = res;
            return true;
          }
          return false;
        });

        break;

      case 'textarea':
      case 'text':
      case 'number':
      case 'radio':
      case 'select':

        tempForm.some(item => {
          if(id == item._id) {
            item.response = res;
          }
          return false;
        });

        break;

      case 'checkbox':
        tempForm.some(item => {
          if(id == item._id) {
            let checkObj = item.response;
            checkObj[res.key] = res.val;
            return true;
          }

          return false;
        })
        break;

      case 'date':
        tempForm.some(item => {
          if(id == item._id) {
            item.response = {...res};
            if(res.hasOwnProperty('start')) {
              item.start = res.start;
            }
            if(res.hasOwnProperty('end')) {
              item.end = res.end;
            }
            return true;
          }

          return false;
        })
        break;
      case 'address':
      case 'expense':
        tempForm.some(item => {
          if(id == item._id) {
            item.response = {...res};
            return true;
          }

          return false;
        })
        break;

    }

    setForm([...tempForm]);
    setSavingProgress(true);
    updateTempForm();

  }

  const updateTempForm = _.debounce(() => {
    firebaseApp.firestore().collection('incompleteForms')
      .doc(mainState.authUser.uid).collection('tempForms').doc(tempFormRef)
      .update({
        "formData.form": form
      })
      .then(() => {
        console.log('Successfully updated temporary form.');
        setSavingProgress(false);
      })
      .catch(err => {
        setSavingProgress(false)
        console.log('Error updating tempForm.');
        console.dir(err);
      })
  },3500)

  const saveTempForm = (selectedForm, group) => {
    firebaseApp.firestore().collection('incompleteForms')
      .doc(mainState.authUser.uid).collection('tempForms')
      .add({
        timestamp: new Date().getTime(),
        group: group,
        formData: selectedForm
      })
      .then(docRef => {
        setTempFormRef(docRef.id);
      })
      .catch(err => {
        console.dir(err);
        console.log('Error saving tempForm.');
      })
  }

  const initGetRecipient = e => {

    e.preventDefault();
    e.persist();

    let email = e.target[0].value;

    if(!email) {
      return;
    }

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('users').doc(email)
      .get().then(docSnap => {
        if(!docSnap.exists){
          //  PROMPT USER
          setErrorMessage('Unable to find user.  Please check that you entered the correct email address.');
          setLoading(false);
          setInvalidRecipient(true);
          setRecipientValid(false);
          initError();
          return;
        }
        let approver = docSnap.data();
        let tempFormData = formData;
        let tempApprover = {
          actionDate: '',
          comment: '',
          displayName: approver.displayName,
          email: approver.email,
          status: 'action required',
          uid: approver.uid
        }
        tempFormData.approvers = [ tempApprover ];
        tempFormData.approverUIDs = [
          approver.uid
        ];

        setFormData(tempFormData);
        setLoading(false);
        setInvalidRecipient(false);
        setRecipientValid(true);
        updateManualRecipient(tempApprover);
      })
      .catch(err => {
        console.dir(err);
        setLoading(false);
      });
  }

  const updateManualRecipient = (recipient) => {
    firebaseApp.firestore().collection('incompleteForms')
      .doc(mainState.authUser.uid).collection('tempForms').doc(tempFormRef)
      .update({
        "formData.approvers": [ recipient ]
      })
      .then(() => {
        console.log('Successfully updated temporary form.');
        setSavingProgress(false);
      })
      .catch(err => {
        setSavingProgress(false)
        console.log('Error updating tempForm.');
        console.dir(err);
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    //  IF APPROVER IS REQUIRED AND NO APPROVER
    //  PROMPT USER TO ENTER APPROVER
    if(formData.manualApproval && !formData.hasOwnProperty('approvers') || formData.manualApproval && formData.approvers.length <= 0) {
      setErrorMessage('An approver\'s email is required.  Please enter a valid email before you can submit the request.');
      setLoading(false);
      initError();
      return;
    }

    //  CHECK IF ANY OF THE ITEM IS REQUIRED.
    let isRequiredMissing = false;

    form.some(item => {
      if(item.required) {
        switch(item.type) {
          case 'text':
          case 'number':
          case 'textarea':
          case 'radio':
          case 'select':
            if(!item.response) {
              isRequiredMissing = true;
              return true;
            }
            break;

          case 'file':
            if(!item.response.url) {
              isRequiredMissing = true;
            }
            break;

          case 'checkbox':
            let checkArr = Object.keys(item.response).filter(key => item.response[key]);
            if(checkArr.length > 0) {
              isRequiredMissing = true;
              return true;
            }
            break;
          case 'address':
            let addressArr = Object.keys(item.response).filter(key => !item.response[key]);
            if(addressArr.length > 0) {
              isRequiredMissing = true;
              return true;
            }
            break;

          case 'date':
            let arr = Object.keys(item.response).filter(key => !item.response[key]);
            if(arr.length > 0) {
              isRequiredMissing = true;
              return true;
            }
            break;

        }
      }
      return false;
    });

    if(isRequiredMissing) {
      setLoading(false);
      promptMissingRequired();
      return;
    }

    setSubmittingForm(true);

    let toSave = {};
    toSave.approvers = formData.approvers;
    toSave.approvers.forEach(approver => approver.status = 'action required');
    toSave.approverUIDs = toSave.approvers.map(approver => approver.uid);
    toSave.domain = mainState.domain;
    toSave.manualApproval = formData.manualApproval;
    toSave.formTitle = formData.title;
    toSave.form = form;
    toSave.group = params.group;
    toSave.requesterUid = mainState.authUser.uid;
    toSave.requester = {
      displayName: mainState.authUser.displayName,
      uid: mainState.authUser.uid,
      email: mainState.authUser.email
    }
    toSave.edits = [];
    toSave.finalRecipients = [];
    if(formData.hasOwnProperty('finalRecipients') && formData.finalRecipients.length > 0) {
      toSave.finalRecipients = formData.finalRecipients;
    }

    let options = {
      method: 'Post',
      headers: {
        Authorization: mainState.authUser.uid
      },
      body: JSON.stringify(toSave)
    }

    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/submitRequest";

    try {

      let initSubmitForm = await fetch(url,options);
      let textRes = await initSubmitForm.text();

      if(initSubmitForm.ok) {
        setConfirmMessage(textRes);
        setSubmittingForm(false);
        initConfirm();
        deleteTempForm();
        setFormSubmitted(true);
        return;
      }

      setErrorMessage(textRes);
      setSubmittingForm(false);
      initError();

    } catch (e) {
      console.log('ERROR',e);
      setErrorMessage('THERE WAS AN ERROR SUBMITTING YOUR REQUEST.  PLEASE TRY AGAIN LATER.');
      setSubmittingForm(false);
      initError();
    }

  }

  if(!mainState.authUser) {
    return (
      <LoginComponent />
    )
  }

  if(formSubmitted) {
    return (
      <div className={classes.root}>
        <HeaderComponent
          title={formData ? formData.title : ""}
          domain={mainState.domain}
        />

        <Typography
          align='center'
          variant='h1'
          style={{
            alignSelf: 'center',
            marginTop: '20vh'
          }}
        >
          Request Sent!
        </Typography>

      </div>
    )
  }

  return (
    <div className={classes.root}>

    <HeaderComponent
      title={formData ? formData.title : ""}
      domain={mainState.domain}
    />

    {
      (formData && formData.manualApproval) && (
        <Paper className={classes.formPaperRecipient}>
          <Typography className={classes.textCentered}>
            <span style={{color: 'red'}}>IMPORTANT</span>:  Please enter the email of person that is responsible for approving this form.  If you do not know, please contact your administrator before completing this form.
          </Typography>
          <form
            onSubmit={initGetRecipient}
            className={classes.recipientForm}
          >

            <TextField
              autoFocus
              label="Enter Email of Recipient"
              defaultValue={recipient}
              variant="outlined"
              className={classes.item}
              onChange={e => setRecipient(e.target.value) }
              // onBlur={initGetRecipient}
              error={invalidRecipient ? true : false}
              helperText={invalidRecipient ? "Email is not valid." : validRecipient ? "Email is valid." : ''}
              required
            >
            </TextField>
            <Button
              color='secondary'
              className={classes.recipientBtn}
              type='submit'
            >
             Add Recipient
            </Button>

          </form>
        </Paper>
      )
    }

    {
      savingProgress && (
        <Typography
          align="center"
          color="primary"
        >Saving progress...</Typography>
      )
    }

      {
        formData && (
          <Paper className={classes.formPaper}>
            <FormModel
              formData={formData}
              form={form}
              handleSubmit={handleSubmit}
              handleResponse={handleResponse}
              setUploadingFile={setUploadingFile}
              cancelForm={cancelForm}
            />
          </Paper>
        )
      }

      <BackDrop loading={ submittingForm || uploadingFile } />
      <ErrorDialog message={errorMessage} toOpen={showError} />
      <ConfirmDialog message={confirmMessage} toOpen={showConfirm} />
    </div>
  )
}

export default FormComponent
