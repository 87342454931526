import React, { useEffect, useState, useContext } from 'react';
import { firebaseApp } from '../../firebase';

import Papa from 'papaparse';
import BackDrop from '../backdrop.component';

import MainContext from '../state/main.context';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Modal,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Box
} from '@material-ui/core';
import MaterialTable, { MTableEditField } from "material-table";
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
    width: '50vw',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  btn: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5)
  }
}));

const UsersComponent = props => {

  const context = useContext(MainContext);
  let { mainState } = context;
  const classes = useStyles();
  const [ users, setUsers] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ showForm, setShowForm ] = useState(false);
  const [ showUploadForm, setShowUploadForm ] = useState(false);
  const [ lastBatch, setLastBatch ] = useState(null);
  const [ processing, setProcessing ] = useState(false);
  const [ response, setResponse ] = useState('');
  const [ showResponse, setShowResponse ] = useState(false);
  const [ updatingRole, setUpdatingRole ] = useState(false);

  //  GET FIRST 50 SET OF USERS
  useEffect(() => {
    if(!mainState.authUser){
      return;
    }
    const usersListener = firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('users').where('domain','==',mainState.domain).onSnapshot(querySnap => {
       if(querySnap.size > 0) {
         let arr = [];
         querySnap.forEach(snap => {
           let tempData = snap.data();

           arr.push(tempData);

         })

         setUsers(arr);
         setLoading(false);
         return;
       }
       setUsers([]);
       setLoading(false);
    })

  },[]);

  if(loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="secondary" />
        <p style={{ textAlign: 'center' }}>Please wait...</p>
      </div>
    )
  }

  const initShowResponse = (textRes) => {
    setResponse(textRes);
    setShowResponse(true);
    setTimeout(() => {
      setShowResponse(false);
      setResponse('')
    },4000)
  }

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    setProcessing(true);

    let obj = {};
    obj.firstName = e.target[0].value;
    obj.lastName = e.target[1].value;
    obj.email = e.target[2].value;
    obj.displayName = obj.firstName + ' ' + obj.lastName;
    obj.domain = mainState.domain;
    obj.role = 'user';
    obj.active = true;
    obj.roles = {
      orgAdmin: false,
      groupAdmin: false
    }
    obj.domainId = mainState.domain;
    obj.createdAt = new Date().getTime();

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('users').doc(obj.email)
      .set(obj)
      .then(() => {
        setProcessing(false);
        console.log('Successfully added user.');
      })
      .catch(err => {
        console.dir(err);
        setProcessing(false);
        alert('There was an issue adding user.  Please try again later.');
      })

  }

  const initDeactivateUser = async (user) => {

    setProcessing(true);

    let userRef = firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('users').doc(user.email);

    try {
      let initAction = await userRef.update({ active:!user.active });
      setProcessing(false);
    } catch (e) {
      console.dir(e);
      setProcessing(false);
      alert('There was an issue.  Please try again later.');
    }

  }

  const handleUpload = async (e) => {
    e.preventDefault();
    setProcessing(true);
    e.persist();

    let file = e.target[0].files[0];

    Papa.parse(file, {
      complete: function (results) {
        let data = results.data || [];

        if(data.length <= 0) {
          setProcessing(false);
          initShowResponse('THERE WAS AN ERROR.  PLEASE TRY AGAIN LATER.');
          return;
        }

        let domainRef = firebaseApp.firestore()
        .collection('domains').doc(mainState.domain);

        //  REMOVE DUPLICATES
        let unique = [];
        var userImportRecords = [];
        let itemsFound = {};

        for(var i = 0; i < data.length; i++) {
          let email = data[i][2];
          if(itemsFound[email]) {
            continue;
          }
          unique.push(data[i]);
          itemsFound[email] = true;
        }

        var userCollection = domainRef.collection('users');

        let batch = firebaseApp.firestore().batch();

        unique.forEach(item => {

          //  LET REF BE THE EMAIL
          let ref = userCollection.doc(item[2]);
          batch.set(ref,{
            createdAt: new Date().getTime(),
            firstName: item[0],
            lastName: item[1],
            email: item[2],
            displayName: item[0] + ' ' + item[1],
            domain: mainState.domain,
            active: true,
            role: 'user',
            roles: {
              orgAdmin: false,
              groupAdmin: false
            }
          });

        });

        batch.commit()
        .then(() => {
          setProcessing(false);
        })
        .catch(err => {
          setProcessing(false);
          console.dir(err);
          alert('There was an error.  Please try again later.');
        })

      }
    })


  }

  const setForm = form => {
    switch(form) {
      case 'userForm':
        setShowForm(!showForm);
        setShowUploadForm(false);
        break;

      case 'uploadForm':
        setShowForm(false);
        setShowUploadForm(!showUploadForm);
        break;
    }
  }

  const Form = props => (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '25vw'
      }}
      onSubmit={handleUserSubmit}
    >
      <TextField type='text' label="Enter First Name" required />
      <TextField type='text' label="Enter Last Name" required />
      <TextField type='email' label="Enter Email Address" required />
      <Button
        disabled={processing}
        style={{marginTop: '10px',marginBottom: '10px'}}
        variant='contained'
        color='secondary'
        type='submit'
      >
        Add User
      </Button>
    </form>
  )

  const UploadForm = props => (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '25vw'
      }}
      onSubmit={handleUpload}
    >
      <p>
        A .csv file with THREE columns is required.  The first column is for the First Name.  The second column is for the Last Name.  The third column is the email address that matches the domain.  DO NOT INCLUDE HEADERS. &nbsp; &nbsp;
        <a href="https://docs.google.com/spreadsheets/d/1WJwo1Dzo-5cy_DBPBWXD8yLMCHmNMb18NLsDWMdWfes/edit?usp=sharing" target="_blank">
          Example Sheet
        </a>
      </p>
      <TextField type='file' required />
      <Button
        style={{marginTop: '10px',marginBottom: '10px'}}
        variant='contained'
        color='secondary'
        type='submit'
      >
        Upload Users
      </Button>
    </form>
  )

  const initUpdateUserPermission = async (email, role, enable) => {

    setProcessing(true);

    let roleToSet = 'user';
    let groupAdmin = false;
    let orgAdmin = false;

    switch(role) {
      case 'org-admin':
        if(enable) {
          roleToSet = 'org-admin';
          orgAdmin = true;
          groupAdmin = false;
        }

        break;

      case 'group-admin':
        if(enable) {
          roleToSet = 'group-admin';
          groupAdmin = true;
          orgAdmin = false;
        }
        break;
    }

    try {

      let updateRole = await firebaseApp.firestore()
      .collection('domains').doc(mainState.domain)
      .collection('users').doc(email)
      .update({
        role: roleToSet,
        roles: {
          groupAdmin: groupAdmin,
          orgAdmin: orgAdmin
        }
      })
      setProcessing(false);

    } catch (e) {
      console.dir(e);
      setProcessing(false);
      alert('There was an issue.  Please try again later.');
    }

  }

  const updateUser = async (newValue,key,email) => {
    setProcessing(true);

    try {

      let updateKey = await firebaseApp.firestore()
      .collection('domains').doc(mainState.domain)
      .collection('users').doc(email)
      .update({
        [key]: newValue
      })
      setProcessing(false);

    } catch (e) {
      console.dir(e);
      setProcessing(false);
      alert('There was an issue.  Please try again later.');
    }

  }

  const columns = [
    {
      title: 'First',
      field: 'firstName',
      render: rowData => <p>{ rowData.firstName.toUpperCase() }</p>
    },
    {
      title: 'Last',
      field: 'lastName',
      render: rowData => <p>{ rowData.lastName.toUpperCase() }</p>
    },
    {
      title: 'Email',
      field: 'email',
      editable: 'never',
      render: rowData => <p>{ rowData.email.toUpperCase() }</p>
    },
    {
      title: 'Group Admin',
      editable: 'never',
      render: rowData => {

        return (
          <Switch
            color='secondary'
            checked={rowData.role === 'group-admin' ? true : false}
            onChange={() => {
              let enable = rowData.role === 'group-admin' ? false : true;
              initUpdateUserPermission(rowData.email, 'group-admin', enable);
            }}
            disabled={rowData.email === mainState.authUser.email}
          />
        )
      }
    },
    {
      title: 'Org Admin',
      editable: 'never',
      render: rowData => {

        return (
          <Switch
            color='secondary'
            checked={rowData.role === 'org-admin' ? true : false}
            onChange={() => {
              let enable = rowData.role === 'org-admin' ? false : true;
              initUpdateUserPermission(rowData.email, 'org-admin', enable);
            }}
            disabled={rowData.email === mainState.authUser.email}
          />
        )
      }
    }
  ]

  return (
    <div className={classes.root}>
      <div className={classes.btns}>
        <Button onClick={() => setForm('userForm')} color='primary' className={classes.btn}>Add a user</Button>
        <Button onClick={() => setForm('uploadForm')} color='secondary' className={classes.btn}>Upload Users (.csv)</Button>
      </div>

      {
        showForm && (
          <Form />
        )
      }

      {
        showUploadForm && (
          <UploadForm />
        )
      }

      {
        showResponse && (
          <h3 style={{
            textAlign: 'center'
          }}>{response}</h3>
        )
      }

      <MaterialTable
        title="Users"
        columns={columns}
        data={users}
        actions={[
          rowData => ({
            icon: () =>
            <Switch color='secondary' checked={rowData.active}
              disabled={rowData.email === mainState.authUser.email}
            />,
            tooltip: 'Enable | Disable User',
            onClick: (event, rowData) => {
              if(rowData.email === mainState.authUser.email){
                alert('You cannot deactive your own account.');
                return;
              }
              initDeactivateUser(rowData)
            }
          })
        ]}
        style={{
          width: window.innerWidth * 0.6
        }}
        pageSize={10}
        cellEditable={{
          cellStyle:{
            width: '200px'
          },
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) =>
            new Promise((resolve, reject) => {
              updateUser(newValue,columnDef.field,rowData.email);
              resolve();
            })
        }}
      />

      <BackDrop loading={processing} />
      <BackDrop loading={updatingRole} />
    </div>
  )
}

export default UsersComponent
