import React, { useEffect, useState, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Backdrop,
  Paper,
  Button,
  Typography
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  headerTitle: {
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '2.5em',
    textAlign: 'center',
    margin: theme.spacing(2),
    [theme.breakpoints.down('xl')]: {
      fontSize: '6.5em'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '5.5em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '4.5em'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.0em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.0em'
    }
  },
  subTitle: {
    color: '#ffffff',
    fontSize: '2.0em',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.0em'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.0em'
    }
  },
  title: {
    fontSize: '2.5em',
    textAlign: 'center',
    margin: theme.spacing(1),
    color: '#341d76',
    [theme.breakpoints.down('xl')]: {
      fontSize: '4.0em'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '4.0em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5em'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.25em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3.0em'
    }
  },
  desc1Cont: {
    maxWidth: 500,
    alignSelf: 'center'
  },
  desc: {
    fontSize: '2.0em',
    textAlign: 'center',
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.5em'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5em'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.0em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.0em'
    }
  },
  backdrop: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '5vh',
    backgroundColor: fade('#341d76',0.75)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 500,
    height: 150,
    margin: theme.spacing(2),
    cursor: 'pointer',
    [theme.breakpoints.down('xl')]: {
      height: 300,
      width: 800
    },
    [theme.breakpoints.down('lg')]: {
      height: 300,
      width: 800
    },
    [theme.breakpoints.down('md')]: {
      height: 250,
      width: 600
    },
    [theme.breakpoints.down('sm')]: {
      height: 200
    },
    [theme.breakpoints.down('xs')]: {
      height: 200,
      width: 475
    }
  },

}));

const LandingPageComponent = props => {
  let { history } = props;
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    document.getElementById('inquiry').style.display = 'none';
  },[])

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={true}>

        <Typography variant="h1" className={classes.headerTitle}>Welcome to Approve iT!</Typography>

        <Typography variant="h3" className={classes.subTitle}>Please select a product.</Typography>

        <Paper className={classes.paper}
          onClick={() =>
            window.open('https://manage-it.app/home')
          }
        >
          <Typography variant='h1' className={classes.title}>Manage iT</Typography>

          <div className={classes.desc1Cont}>
            <Typography className={classes.desc}>
            Manage Online Assets | Google Classroom Admin | Student Online Activities | App Usage Insights | Google Chrome Extension
            </Typography>
          </div>
          
        </Paper>

        <Paper className={classes.paper}
          onClick={() =>
            history.push('/home')
          }
        >
          <Typography variant='h1' className={classes.title}>Approve iT Forms</Typography>
          <Typography className={classes.desc}>Automated Internal Approval Forms</Typography>
        </Paper>

      </Backdrop>
    </div>
  )
}

export default LandingPageComponent
