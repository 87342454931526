export const initialState = {
  isLoading: true,
  authUser: null,
  userType: null,
  groups: [],
  subscription: 'basic',
  domain: null,
  termsPrivacyAgreement: false
}

export const mainReducer = (state,action) => {

  switch(action.type) {

    case 'setShowTermsPrivacyAgreement':
      return {
        ...state,
        termsPrivacyAgreement: action.payload
      }

    case 'setLoading':
      return {
        ...state,
        isLoading: action.payload
      }

    case 'setDomain':
      return {
        ...state,
        domain: action.payload
      }

    case 'setAuthUser':
      let domain = null;
      if(action.payload) {
        let emailSplit = (action.payload.email.split('@')[1]).split('.');
        domain = emailSplit[emailSplit.length - 2] + "." + emailSplit[emailSplit.length - 1];
      }

      return {
        ...state,
        authUser: action.payload,
        domain: domain
      }

    case 'setUserType':
      return {
        ...state,
        userType: action.payload
      }

    case 'setSubscription':
      return {
        ...state,
        subscription: action.payload
      }

    case 'setGroups':
      return {
        ...state,
        groups: action.payload
      }

    case 'setAdminUsers':
      return {
        ...state,
        adminUsers: action.payload
      }

    case 'reset':
      return {
        ...initialState
      }

    default:
      return;

  }

}
