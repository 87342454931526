import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Modal,
  Button,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { SignOut } from '../utilities/firebase.actions';
import { firebaseApp } from '../../firebase';
import * as firebase from 'firebase/app';
import "firebase/auth";
import 'firebase/firestore';

import CustomHeader from './header.component';
import BackDrop from '../backdrop.component';
import MainContext from '../state/main.context';
import ProfileComponent from './profile.component';
import SubmitFormsComponent from './submit.forms.component';
import GroupFormsComponent from './group.forms.component';
import ViewRequests from './view.requests';
import TermsPrivacyAgreement from '../agreements/termsPrivacyAgreement.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
    // border: '1px solid black'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: theme.spacing(10)
  },
  title: {
    flexGrow: 1,
  },
  error: {
    color: 'red',
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  list: {
    marginTop: theme.spacing(10)
  },
  drawer: {
    marginTop: theme.spacing(5),
  },
  modal: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  closeBtn: {
    width: '20px',
    margin: theme.spacing(2),
    position: 'fixed'
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '80vh',
    width: '80vw',
    padding: '10px',
    paddingBottom: '50px',
    alignSelf: 'center',
    border: '1px solid black',
    overflow: 'auto',
    backgroundColor: 'white'
  },
  checkBoxes: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    alignSelf: 'center',
    paddingTop: theme.spacing(5)
  },
  label: {
    fontSize: theme.spacing(5)
  }
}));

const DefaultUserComponent = props => {
  let { history } = props;
  const classes = useStyles();
  const context = useContext(MainContext);
  const { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(false);
  const [ groups, setGroups ] = useState([]);
  const [ menuOpen, toggleMenu ] = useState(false);
  const [ page,showPage ] = useState('Forms');
  const [ userData, setUserData ] = useState(null);
  const [ selectGroup, setSelectGroup ] = useState(false);
  const menuOptions = [
    'Profile',
    'Forms',
    'Requests'
  ];

  useEffect(() => {
    if(!mainState.authUser) {
      setLoading(false);
      return;
    }

    //  IF USER DOES NOT HAVE A DISPLAY NAME, FORCE THEM TO UPDATE PROFILE
    if(!mainState.authUser.hasOwnProperty('displayName') || (mainState.authUser.hasOwnProperty('displayName') && !mainState.authUser.displayName) || (mainState.authUser.hasOwnProperty('displayName') && mainState.authUser.displayName.trim().length <= 0)) {
      showPage('Profile');
      alert('Please update your first and last name.');
      return;
    }

    const groupListener = firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('groups').get().then(snapShot => {
       if(snapShot.size > 0) {
         let arr = [];
         snapShot.forEach(snap => {

           arr.push(snap.data().groupName);

         });
         mainDispatch({ type: 'setGroups', payload: arr })
         setGroups(arr);
         setLoading(false);
         return;
       }
       setGroups([]);
       setLoading(false);
    });

    const userListener = firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('users').doc(mainState.authUser.email).onSnapshot(doc => {

        if(doc.exists) {

          let tempDoc = doc.data();

          //  CHECK IF USER BELONGS TO A GROUP ALREADY
          if(!tempDoc.hasOwnProperty('groups') ||
              tempDoc.hasOwnProperty('groups') &&
              tempDoc.groups.length <= 0) {

            console.log('USER DOES NOT BELONG TO A GROUP.');
            tempDoc.groups = [];

            setUserData(tempDoc);

            setTimeout(() => setSelectGroup(true),1000);

            return;
          }

          setUserData(tempDoc);

        }

      }, error => {
        console.log('ERROR',error);
      });

    //  MAKE SURE THEY SIGNED THE AGREEMENT
    initCheckAgreement();

    return () => {
      userListener();
    }

  }, [ mainState.authUser, mainDispatch ])

  useEffect(() => {
    if(!mainState.authUser) {
      props.history.push('/login');
      return;
    }
  },[ mainState.authUser ] );

  const initCheckAgreement = async () => {

    let email = mainState.authUser.email;

    try {
      
      let agreementStatus = await firebaseApp.firestore()
      .collection('termsPrivacyAgreement').doc(email).get();

      if(!agreementStatus.exists) {
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: true });
        return;
      }

    } catch(e) {
      // statements
      console.log(e);
    }

  }

  const agreementResponse = agreed => {

    if(!agreed) {
      SignOut();
      mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      history.push('/home');
      return;
    }

    let email = mainState.authUser.email;

    firebaseApp.firestore().collection('termsPrivacyAgreement').doc(email)
      .set({
        agreed: Date.now()
      })
      .then(() => {
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      })
      .catch(err => {
        console.log(err);
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      })

  }


  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {menuOptions.map((text) => (
          <div key={text}>
            <ListItem button
              onClick={() => showPage(text)}
            key={text}>
              <ListItemText primary={text} />
            </ListItem>
            <Divider />
          </div>
        ))}


      </List>


    </div>
  );

  const toggleDrawer = () => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    toggleMenu(!menuOpen)
  };

  const doToggle = () => toggleMenu(!menuOpen);

  const handleCheckbox = e => {
    let tempVal = e.target.value;
    let checkVal = !userData.groups.includes(tempVal);
    setLoading(true);
    let userRef = firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('users').doc(mainState.authUser.email);

    if(checkVal) {

      let tempGroups = userData.groups;

      //  IF GROUP IS EMPTY
      if(tempGroups.length <= 0) {
        userRef.update({
          groups: [ tempVal ]
        })
        .then(() => {
          setLoading(false);
        })
        .catch(err => {
          console.log('ERROR',err);
          setLoading(false);
        })
        return;
      }

      userRef.update({
        groups: firebase.firestore.FieldValue.arrayUnion(tempVal)
      })
      .then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        setLoading(false);
      })
      return;
    }

    userRef.update({
      groups: firebase.firestore.FieldValue.arrayRemove(tempVal)
    })
    .then(() => {
      setLoading(false);
    })
    .catch(err => {
      console.log('ERROR',err);
      setLoading(false);
    })
  }

  const toggleSelectGroup = () => {
      setSelectGroup(!selectGroup);
  }

  return (
    <div className={classes.root}>
      <CustomHeader
        title={ mainState.authUser ? mainState.authUser.displayName : '' }
        toggleMenu={doToggle}
      />

      <Drawer open={menuOpen} onClose={toggleDrawer()} className={classes.drawer}>
        {sideList()}
      </Drawer>

      {
        page === 'Profile' && (
          <ProfileComponent
            groups={ userData ? userData.groups : [] }
            toggleSelectGroup={toggleSelectGroup}
          />
        )
      }

      {
        (page === 'Forms' && userData) && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <GroupFormsComponent
              userData={userData}
            />
          </MuiPickersUtilsProvider>
        )
      }

      {
        page === 'Requests' && (
          <ViewRequests />
        )
      }


      <Modal
        className={classes.modal}
        open={selectGroup}
        onClick={() => console.log('Must Click on Close...') }
        BackdropProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10vw'
          }
        }}
      >

        <div className={classes.modalContainer}>
          <Button
            variant='contained'
            color='secondary'
            className={classes.closeBtn}
            onClick={() => setSelectGroup(false)}
            disabled={userData ? userData.groups.length <= 0 : '' }
          >
            Done
          </Button>

          <Typography
            variant='h3'
            style={{ textAlign: 'center' }}
          >Select your group(s)</Typography>

          {
            groups.length > 0 && (
              <div
                className={classes.checkBoxes}
              >
                {
                  groups.map(group =>
                    <FormControlLabel key={group}
                      value={group}
                      control={
                        <Checkbox
                        checked={ userData ? userData.groups.includes(group) : false }
                        color='primary'
                        onChange={handleCheckbox}
                        />
                      }
                      label={<Typography className={classes.label}>{group.toUpperCase()}</Typography>}
                      labelPlacement="end"
                    />
                  )
                }
              </div>
            )
          }


        </div>

      </Modal>


      <TermsPrivacyAgreement
        visible={mainState.termsPrivacyAgreement}
        agreementResponse={agreementResponse}
      />
      <BackDrop loading={loading} />
    </div>
  )
}

export default DefaultUserComponent
