import React from 'react';

import GlobalState from './app/state/global.state';
import ContactSupport from './app/inquiry_forms/contact_support.component';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// CUSTOM THEME
const theme = createMuiTheme({"palette":{"common":{"black":"#000","white":"#fff"},"background":{"paper":"#fff","default":"#fafafa"},"primary":{"light":"rgba(100, 70, 167, 1)","main":"rgba(51, 29, 119, 1)","dark":"rgba(0, 0, 74, 1)","contrastText":"#fff"},"secondary":{"light":"rgba(200, 233, 90, 1)","main":"rgba(148, 183, 37, 1)","dark":"rgba(98, 135, 0, 1)","contrastText":"#fff"},"error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},"text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}})

const Inquiry = props => (
  <GlobalState>
    <MuiThemeProvider theme={theme}>
      <ContactSupport />
    </MuiThemeProvider>
  </GlobalState>
)

export default Inquiry
