import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Divider,
  Paper,
  TextField
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { firebaseApp } from '../../firebase';
import MainContext from '../state/main.context';
import BackDrop from '../backdrop.component';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '20vh',
    width: '90vw',
    alignSelf: 'center',
    padding: theme.spacing(2),
    border: '1px solid lightgrey',
    borderRadius: theme.spacing(1)
  },
  updateBtn: {
    alignSelf: 'flex-end',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  profileHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textField: {
    margin: theme.spacing(2)
  },
  divCont1: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: theme.spacing(1),
    minWidth: 500,
    midHeight: 500,
    padding: theme.spacing(1)
  },
  divCont2: {
    flex: 1,
    margin: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: theme.spacing(1),
    minWidth: 500,
    midHeight: 500,
    padding: theme.spacing(1)
  }
}))

const ProfileComponent = ({ groups,toggleSelectGroup }) => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState } = context;
  const [ groupArray, setGroupArray ] = useState([]);
  const [ editting, setEditting ] = useState(false);
  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ newFirst, setNewFirst ] = useState("");
  const [ newLast, setNewLast ] = useState("");
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {

  }, [groups])

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    if(mainState.authUser.hasOwnProperty('displayName') && mainState.authUser.displayName) {

      let nameArr = mainState.authUser.displayName.split(' ');
      let tempFirst,tempLast = "";

      if(nameArr.length > 1) {

        if(nameArr.length == 2) {
          tempFirst = nameArr[0];
          tempLast = nameArr[1];
        }

        if(nameArr.length > 2) {
          tempFirst = nameArr[0] + " " + nameArr[1];
          tempLast = nameArr[2];
        }

        setFirstName(tempFirst);
        setLastName(tempLast);

      }

    }

  },[])

  const updateDisplayName = async () => {

    setLoading(true);

    let user = firebaseApp.auth().currentUser;
    let displayName = newFirst + " " + newLast;

    try {

      let updateAccountDisplayName = await user.updateProfile({
        displayName: displayName
      });

      let updateUserInfo = await firebaseApp.firestore()
        .collection('domains').doc(mainState.domain)
        .collection('users').doc(mainState.authUser.email)
        .update({
          displayName: displayName,
          firstName: newFirst,
          lastName: newLast
        })

      let reloadUser = await user.reload();

      setLoading(false);
      setEditting(false);

    } catch (e) {

      console.dir(e);
      setEditting(false);
      setLoading(false);
      alert('There was an issue.  Please try again later.');

    }

  }

  return (
    <Paper className={classes.root}>

      <div className={classes.divCont1}>

        <div className={classes.profileHeader}>
        <Typography
          variant="h6"
          component="h1"
          align="center"
          style={{ margin: '5px' }}
        >Profile</Typography>
          <EditIcon
            color="primary"
            onClick={() => setEditting(!editting)}
          />
        </div>

        <TextField
          className={classes.textField}
          disabled={!editting}
          placeholder={firstName}
          variant="outlined"
          label="First Name"
          InputLabelProps={{
            shrink: true
          }}
          onChange={ e => setNewFirst(e.target.value) }
        />

        <TextField
          className={classes.textField}
          disabled={!editting}
          placeholder={lastName}
          variant="outlined"
          label="Last Name"
          InputLabelProps={{
            shrink: true
          }}
          onChange={ e => setNewLast(e.target.value) }
        />

        {
          editting && (
            <Button
              variant="contained"
              color="primary"
              onClick={updateDisplayName}
            >
              Submit Changes
            </Button>
          )
        }

      </div>

      <div className={classes.divCont2}>
        <Button
          color='primary'
          className={classes.updateBtn}
          onClick={ toggleSelectGroup }
        >
          Update My Group(s)
        </Button>

        <Typography align="center" variant='h5' component='h1'>Your Groups</Typography>

        <Typography style={{ textAlign: 'center' }}>
          Forms are available based on your group membership.  You may add or remove groups by clicking on the Update My Group button.
        </Typography>

        <Divider className={classes.divider} />

        {
          groups.length > 0 && (

              groups.map(group =>
                <Typography key={group} variant='h4'>
                  - {group.toUpperCase()}
                </Typography>
              )

          )
        }

    </div>

      <BackDrop loading={loading} />
    </Paper>
  )
}

export default ProfileComponent
