import React, { useState } from 'react';
import {
  TextField,
  FormLabel
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';
const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  divCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(2)
  },
  form:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  item: {
    margin: theme.spacing(1)
  },
  addressDiv: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'

  },
  city: {
    flexGrow: 8
  },
  state: {
    flexGrow: 1.5,
    marginLeft: theme.spacing(0.5)
  },
  zip: {
    flexGrow: 1.5,
    marginLeft: theme.spacing(0.5)
  },
  removeBtn: {
    margin: theme.spacing(0.5)
  },
  actionBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  title: {
    color: 'black'
  }

}))

const AddressPreviewComponent = ({ elem, handleResponse, toggleTyping }) => {

  const classes = useStyles();
  const [ obj, updateObj ] = useState(elem.response);
  const states = [
    'AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'
  ]

  const initUpdate = (key,val) => {
    let tempObj = obj;
    tempObj[key] = val;
    updateObj(tempObj);
    handleResponse('address',elem._id,tempObj);
  }

  const handleTextField = _.debounce((key,val) => {
    initUpdate(key,val);
  },500)

  return (
    <div className={classes.divCont}>

      <FormLabel className={classes.title}>{elem.title} { elem.required ? ' *' : null }</FormLabel>

      <TextField
        type="text"
        label="Enter Name of Location"
        variant='outlined'
        className={classes.item}
        required={elem.required}
        defaultValue={elem.response.location}
        onChange={e => {
          toggleTyping();
          handleTextField('location',e.target.value)
        }}
      />

      <TextField
        type="text"
        label="Address"
        variant='outlined'
        multiline
        rows={3}
        className={classes.item}
        required={elem.required}
        defaultValue={elem.response.street}
        onChange={(e) => {
          toggleTyping();
          handleTextField('street',e.target.value)
        }}
      />

      <div className={classes.addressDiv}>

        <TextField
          type="text"
          label="City"
          variant='outlined'
          className={classes.city}
          required={elem.required}
          defaultValue={elem.response.city}
          onChange={(e) => {
            toggleTyping();
            handleTextField('city',e.target.value)
          }}
        />

        <Autocomplete
          value={elem.response.state}
          className={classes.state}
          style={{ width: 150 }}
          options={states}
          freeSolo
          getOptionLabel={option => option}
          renderInput={(params) => {
            return(
              <TextField
                {...params}
                label="State"
                variant="outlined"
                required={elem.required}
                onBlur={e => {
                  initUpdate('state',e.target.value)
                }}
              />
            )
        }}
        />

        {/*

        <FormControl
          variant='outlined'
          className={classes.stateFormControl}
        >
          <Select
            className={classes.state}
            displayEmpty
            onChange={e => initUpdate('state',e.target.value)}
            value={elem.response.state}
          >
            <MenuItem value="" disabled>
              State
            </MenuItem>
            {
                states.map(option =>
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                )
            }
          </Select>
        </FormControl>
        */}

        <TextField
          type="text"
          label="Zip Code"
          variant='outlined'
          className={classes.zip}
          required={elem.required}
          defaultValue={elem.response.zip}
          onChange={(e) => {
            toggleTyping();
            handleTextField('zip',e.target.value)
          }}
        />

      </div>

      <TextField
        type="text"
        label="Country"
        variant='outlined'
        className={classes.item}
        required={elem.required}
        defaultValue={elem.response.country}
        onChange={(e) => {
          toggleTyping();
          handleTextField('country',e.target.value)
        }}
      />

    </div>
  )
}

export default AddressPreviewComponent
