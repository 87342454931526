import React, { useEffect, useState, useContext } from 'react';
import { firebaseApp } from '../../firebase';

import MainContext from '../state/main.context';
import GroupComponent from './group.component';
import GroupFormComponent from './group.form';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Modal } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import MaterialTable from "material-table";

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const GroupsComponent = props => {
  const history = useHistory();
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(true);
  const [ groups, setGroups ] = useState([]);
  const [ group, setGroup ] = useState(null);
  const [ showGroup, setShowGroup ] = useState(false);
  const [ domain, setDomain ] = useState(null);

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    setLoading(false);

    const tempDomain = mainState.authUser.email.split('@')[1];
    setDomain(tempDomain);

    const groupsListener = firebaseApp.firestore().collection('domains').doc(tempDomain)
      .collection('groups').where('domain','==',tempDomain).onSnapshot(querySnap => {

       if(querySnap.size > 0) {
         let arr = [];
         querySnap.forEach(snap => {
            arr.push(snap.data());
         })
         
         setGroups(arr);

         return;
       }
       setGroups([]);

      })

      return () => groupsListener();
  },[])

  if(loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="secondary" />
        <p style={{ textAlign: 'center' }}>Please wait...</p>
      </div>
    )
  }

  const initShowGroup = group => {
    setGroup(group);
    setShowGroup(true);
  }

  const initCloseGroup = () => {
    setGroup(null);
    setShowGroup(false);
  }

  const columns = [
    {
      title: 'Group',
      field: 'groupName',
      editable: 'never',
      render: rowData => <p>{ rowData.groupName.toUpperCase() }</p>
    },
    {
      title: 'Admins',
      field: 'admins',
      render: rowData => <p>{ rowData.admins.join(',') }</p>
    }
  ];

  const updateGroup = (newData) => {

      let admins;

      if(typeof newData.admins === 'string') {
        admins = newData.admins.split(',');
      } else {
        admins = [];
      }

    firebaseApp.firestore().collection('domains').doc(domain)
      .collection('groups').doc(newData.groupName)
      .update({admins: admins})
      .then(() => {
        setLoading(false);

      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        alert('Error adding admin.  Please contact support@approveit.app');
      })


  }

  return (
    <div className={classes.root}>

        <GroupFormComponent />

        <MaterialTable
          title="Organization Groups"
          columns={columns}
          data={groups}
          // detailPanel={ rowData =>
          //   <GroupComponent {...rowData} />
          // }
          style={{
            width: window.innerWidth * 0.6
          }}
          pageSize={10}

          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                updateGroup(newData);
                resolve();
              })
          }}
        />

    </div>
  )
}

export default GroupsComponent
