import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { firebaseApp, provider } from '../../firebase';

import CustomHeader from '../header.component';
import BackDrop from '../backdrop.component';
import ConfirmDialog from '../shared_components/confirm.dialog.component';
import ErrorDialog from '../shared_components/error.dialog.component';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '1.5em',
    margin: theme.spacing(2),
    textAlign: 'center'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
  },
  paper: {
    marginTop: '10vh',
    padding: '20px'
  },
  button: {
    margin: theme.spacing(1),
    width: '45vw',
    alignSelf: 'center'
  },
  dialog: {
    padding: theme.spacing(3)
  },
  dialogMessage: {
    margin: theme.spacing(2)
  },
  dialogBtn: {
    margin: theme.spacing(2)
  },
  googleBtn: {
    height: '100px',
    margin: theme.spacing(5),
    cursor: 'pointer'
  }
}))

const FinalizaAccountComponent = props => {
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();
  let { email } = params;

  const [ loading, setLoading ] = useState(false);
  const [ pass1, setPass1 ] = useState(null);
  const [ pass2, setPass2 ] = useState(null);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showDialog, setShowDialog ] = useState(false);
  const [ dialogTitle, setDialogTitle ] = useState('Hello');
  const [ dialogMessage, setDialogMessage ] = useState('This is a message.');
  const [ showError, setShowError ] = useState(false);
  const [ response, setResponse ] = useState('');

  useEffect(() => {},[showDialog])

  const submit = async (e) => {

    e.preventDefault();

    let hiddenVal = document.querySelector('#approve-it').value;

    if(hiddenVal) {
      return;
    }

    setLoading(true);

    if(pass1 !== pass2) {
      setShowError(true);
      setLoading(false);
      return;
    }

    setShowError(false);


    firebaseApp.auth().createUserWithEmailAndPassword(email, pass1).catch(function(error) {

      var errorCode = error.code;
      var errorMessage = error.message;

      setDialogMessage(errorMessage);
      setLoading(false);
      setShowError(true);

      return;
    });

    setLoading(false);
    window.confirm("Successfully created account.");
    history.push('/home');

  }

  const initSigninWithGoogle = () => {

    setLoading(true);

    firebaseApp.auth().signInWithPopup(provider).then(function(result) {

      var user = result.user;

      setLoading(false);
      window.confirm("Successfully created account.");
      history.push('/home');

    }).catch(function(error) {

      var errorCode = error.code;
      var errorMessage = error.message;
      setDialogMessage(errorMessage);
      setLoading(false);
      setShowError(true);

    });

  }

  return (
    <div>

    <CustomHeader title='Finalize Account' />

    <div className={classes.formContainer}>
      <Paper className={classes.paper}>

      <Typography className={classes.label}>
        Non-Google Users
      </Typography>

      <form
        onSubmit={submit} className={classes.form}
      >

        <TextField
          label="Email"
          value={email ? email : '' }
          required={true}
          onChange={() => console.log('email')}
        />

        <TextField
          error={showError}
          helperText={showError ? 'Passwords do not match.' : null}
          id='pass_1'
          label='Enter Password'
          type='password'
          margin='normal'
          onChange={e => setPass1(e.target.value.trim())}
          required={true}
        />

        <TextField
          error={showError}
          helperText={showError ? 'Passwords do not match.' : null}
          id='pass_2'
          label='Confirm Password'
          type='password'
          margin='normal'
          onChange={e => setPass2(e.target.value.trim())}
          required={true}
        />

        <input
          className={classes.formItem}
          type='text'
          value=''
          id='approve-it' hidden
          onChange={() => console.log('nice try bot.')}
        />

        <Button
          type='submit'
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={ loading || !pass1 || !pass2 }
        >
          FINALIZE AND CREATE ACCOUNT
        </Button>

        {
          showSuccess ?
            <p style={{
              textAlign: 'center',
              color: 'green'
            }}>{response}</p>
          : null
        }
      </form>
      </Paper>

      <img
        src="https://firebasestorage.googleapis.com/v0/b/approve-it-5c4fc.appspot.com/o/site_assets%2Fbtn_google_signin_dark_focus_web%402x.png?alt=media&token=92b37253-1171-4b37-865e-d226bbf45734"
        className={classes.googleBtn}
        onClick={initSigninWithGoogle}
      />
    </div>


    <BackDrop loading={loading} />
    <ConfirmDialog message={dialogMessage} toOpen={showSuccess} />
    <ErrorDialog message={dialogMessage} toOpen={showError} />
    </div>
  )
}

export default FinalizaAccountComponent;
