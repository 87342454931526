const userReducer = (state,action) => {
  switch(action.type) {
    case 'setUser':
      return {
        ...state,
        authUser: action.payload
      }
  }
}


export default userReducer;
