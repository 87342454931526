import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Paper,
  Modal,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Divider
} from '@material-ui/core';
import { firebaseApp } from '../../firebase';
import firebase from 'firebase/firebase';
import MaterialTable from 'material-table';
import InputHandler from '../default_user/user_forms/inputs.handler.component';
import ErrorDialog from '../shared_components/error.dialog.component';
import ConfirmDialog from '../shared_components/confirm.dialog.component';
import BackDrop from '../backdrop.component';
import LoginNoRedirect from '../login_no_redirect.component';
import MainContext from '../state/main.context';

const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    marginTop: '3vh',
    width: '80vw',
    height: '100vh',
    alignSelf: 'center',
    overflow: 'auto'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150
  },
  formPaper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginBottom: '10vh'
  },
  formPaperRecipient: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  recipientForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  optionsMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%'
  },
  formTitle: {
    textAlign: 'center',
    margin: theme.spacing(1)
  },
  submitBtn: {
    margin: theme.spacing(2),
    width: '60%'
  },
  item: {
    width: '80%'
  },
  textCentered: {
    textAlign: 'center'
  },
  recipientBtn: {
    margin: theme.spacing(1)
  }

}))

const FormModel = ({

  formSelected,
  form,
  handleSubmit,
  handleResponse,
  setUploadingFile,
  closeForm

  }) => {

  const classes = useStyles();

  const [ typing, setTyping ] = useState(false);

  const initToggleTyping = () => {
    setTyping(true);
    setTimeout(() => setTyping(false),1000);
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
      onReset={() => closeForm(false)}
    >
      <Typography variant='h6' color='primary'>
        Edit Submission
      </Typography>

      {
        form.map((elem,i) =>
          <InputHandler
            key={elem._id}
            elem={elem}
            handleResponse={handleResponse}
            toggleTyping={initToggleTyping}
            setUploadingFile={setUploadingFile}
          />
        )
      }

      <Button
        className={classes.submitBtn}
        color='primary'
        variant="contained"
        type='submit'
        disabled={typing}
      >
        Submit
      </Button>
      <Button
        className={classes.submitBtn}
        color='secondary'
        variant="contained"
        type='reset'
      >
        Cancel
      </Button>
    </form>
  )
}

const EditSubmittedFormsComponent = ({
    uid,
    requestData,
    domain,
    formRequestId,
    closeModal,
    editSuccessfull
  }) => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState } = context;
  const [ loading, setLoading ] = useState(false);
  const [ formSelected, setFormSelected ] = useState(null);
  const [ form, setForm ] = useState([]);
  const [ formTitle, setFormTitle ] = useState('');
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ confirmMessage, setConfirmMessage ] = useState('');
  const [ submittingForm, setSubmittingForm ] = useState(false);
  const [ invalidRecipient, setInvalidRecipient ] = useState(false);
  const [ validRecipient, setRecipientValid ] = useState(false);
  const [ uploadingFile, setUploadingFile ] = useState(false);
  const [ tempFormRef, setTempFormRef ] = useState(null);
  const [ savingProgress, setSavingProgress ] = useState(false);

  useEffect(() => {
    setForm(requestData.form);
    setFormSelected(requestData);
    setFormTitle(requestData.formTitle);
  },[]);

  const handleSubmit = async (e) => {

    e.preventDefault();

    setLoading(true);

    let formRequestRef = firebaseApp.firestore()
    .collection('domains').doc(mainState.domain)
    .collection('formRequests').doc(formRequestId);


    let timestamp = new Date().getTime();
    let userEmail = mainState.authUser.email;
    console.log(timestamp,userEmail);

    try {

      await firebaseApp.firestore().runTransaction(async (t) => {

        const doc = await t.get(formRequestRef);

        let edits = doc.data().edits || [];

        edits.push({
          email: userEmail,
          timestamp: timestamp
        })

        t.update(formRequestRef,
          {
            form: form,
            edits: edits
          }
        );

      });

      console.log('Successfully made edits');
      setLoading(false);
      closeModal(false);

    } catch (e) {
      console.dir(e);
      setLoading(false);
      closeModal(false);
      setTimeout(() => {
        alert("There was an error editing submission.  Please try again later.")
      },1000)
    }
  }

  const handleResponse = (elemType,id,res) => {

    let tempForm = form;

    switch(elemType) {

      case 'file':

        tempForm.some(item => {
          if(id == item._id) {
            item.response = res;
          }
          return false;
        });

        break;

      case 'textarea':
      case 'text':
      case 'number':
      case 'radio':
      case 'select':

      tempForm.some(item => {
        if(id == item._id) {
          item.response = res;
        }
        return false;
      });

        break;

      case 'file':

        alert('Cannot change file from here.');
        // tempForm.some(item => {
        //   if(id == item._id) {
        //     item.response = res;
        //     return true;
        //   }
        //
        //   return false;
        // });

        break;

      case 'checkbox':
        tempForm.some(item => {
          if(id == item._id) {
            let checkObj = item.response;
            checkObj[res.key] = res.val;
            return true;
          }

          return false;
        })
        break;

      case 'date':
        console.dir(res);
        tempForm.some(item => {
          if(id == item._id) {
            item.response = {...res};
            if(res.hasOwnProperty('start')) {
              item.start = res.start;
            }
            if(res.hasOwnProperty('end')) {
              item.end = res.end;
            }
            return true;
          }

          return false;
        })
        break;
      case 'address':
      case 'expense':
        tempForm.some(item => {
          if(id == item._id) {
            item.response = {...res};
            return true;
          }

          return false;
        })
        break;

    }
    setForm([...tempForm]);
  }

  return (
    <div className={classes.root}>

      <Paper className={classes.formPaper}>
        <FormModel
          formSelected={formSelected}
          form={form}
          handleSubmit={handleSubmit}
          handleResponse={handleResponse}
          closeForm={closeModal}
        />
      </Paper>

      <BackDrop loading={ loading } />
      <ErrorDialog message={errorMessage} toOpen={showError} />
      <ConfirmDialog message={confirmMessage} toOpen={showConfirm} />
    </div>
  )
}

export default EditSubmittedFormsComponent
