import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  TextField,
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MainContext from '../state/main.context';
import { firebaseApp } from '../../firebase';

import CustomHeader from '../header.component';
import BackDrop from '../backdrop.component';

const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
  },
  paper: {
    marginTop: '10vh',
    padding: '20px'
  },
  button: {
    margin: theme.spacing(1),
    width: '45vw',
    alignSelf: 'center'
  }
}))

const OrgFormComponent = props => {
  const context = useContext(MainContext);
  const classes = useStyles();
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showError, setShowError ] = useState(false);
  const [ response, setResponse ] = useState('');
  const [ processing, setProcessing ] = useState(false);

  const initShowResponse = (resType) => {
    switch(resType) {
      case 'success':

        setShowSuccess(true);

        setTimeout(() => {
          setShowSuccess(false);
        },3000);

        break;

      case 'error':

        setShowError(true);

        setTimeout(() => {
          setShowError(false);
        },3000);

        break;
    }
  }

  const submit = async (e) => {
    e.preventDefault();
    e.persist();
    setProcessing(true);
    let target = e.target;
    let obj = {};
    obj.orgName = target[0].value;
    obj.domain = target[1].value;
    obj.firstName = target[2].value;
    obj.lastName = target[3].value;
    obj.adminEmail = target[4].value;

    try {

      //  CREATE DOMAIN
      let initCreateDomain = await firebaseApp.firestore()
                                   .collection('domains').doc(obj.domain)
                                   .set({
                                     createdAt: new Date().getTime(),
                                     orgName: obj.orgName,
                                     domain: obj.domain,
                                     roles:{},
                                     active: true,
                                     subscription: 'basic'
                                   });

      //  CREATE USER
      let initCreateUser = await firebaseApp.firestore()
                                .collection('domains').doc(obj.domain)
                                .collection('users').doc(obj.adminEmail)
                                .set({
                                  createdAt: new Date().getTime(),
                                  uid: '',
                                  email: obj.adminEmail,
                                  displayName: obj.firstName + " " + obj.lastName,
                                  firstName: obj.firstName,
                                  lastName: obj.lastName,
                                  role: 'org-admin',
                                  domain: obj.domain,
                                  active: true
                                })
       e.target.reset();
       setProcessing(false);
       return alert("Successfully created user and domain.");

    } catch (e) {
      console.dir(e);
      e.target.reset();
      setProcessing(false);
      return alert("There was an error.  Please try again later");
    }

  }

  return (
    <div>

      <div className={classes.formContainer}>
        <Paper className={classes.paper}>
        <Typography className={classes.title}>
          Add New Organization
        </Typography>
        <form onSubmit={ (event)=> submit(event) } className={classes.form}>

          <TextField
            id='org_name'
            label='Organization/District Name'
            type='text'
            margin='normal'
            required={true}
          />

          <TextField
            id='org_domain'
            label='Organization/District Domain'
            type='text'
            margin='normal'
            required={true}
          />

          <TextField
            id="admin_first_name"
            label="Admin First Name"
            type='text'
            margin="normal"
            required={true}
          />

          <TextField
            id="admin__last_name"
            label="Admin Last Name"
            type='text'
            margin="normal"
            required={true}
          />

          <TextField
            id="admin_email"
            label="Admin Email"
            type='email'
            margin="normal"
            required={true}
          />

          <Button
            type='submit'
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={mainState.isLoading}
          >
            Submit
          </Button>
          {
            showError ?
              <p style={{
                textAlign: 'center',
                color: 'red'
              }}>{response}</p>
            : null
          }
          {
            showSuccess ?
              <p style={{
                textAlign: 'center',
                color: 'green'
              }}>{response}</p>
            : null
          }
        </form>
        </Paper>
      </div>

      <BackDrop loading={processing} />
    </div>
  )
}

export default OrgFormComponent
