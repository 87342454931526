import React, { useState } from 'react';
import {
  TextField,
  FormLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  divCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(2)
  },
  form:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
  item: {
    margin: theme.spacing(1)
  },
  addressDiv: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row'
  },
  city: {
    flexGrow: 8
  },
  state: {
    flexGrow: 0.5,
    marginLeft: theme.spacing(0.5)
  },
  zip: {
    flexGrow: 1.5,
    marginLeft: theme.spacing(0.5)
  },
  removeBtn: {
    margin: theme.spacing(0.5)
  },
  actionBtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  title: {
    color: 'black'
  }

}))

const AddressPreviewComponent = ({ elem }) => {

  const classes = useStyles();

  return (
    <div className={classes.divCont}>

      <FormLabel className={classes.title}>{elem.title} { elem.required ? ' *' : null }</FormLabel>

      <TextField
        type="text"
        label="Enter Name of Location"
        variant='outlined'
        className={classes.item}
      />

      <TextField
        type="text"
        label="Address"
        variant='outlined'
        multiline
        rows={3}
        className={classes.item}
      />

      <TextField
        type="text"
        label="Suite #"
        variant='outlined'
        className={classes.item}
      />

      <div className={classes.addressDiv}>

        <TextField
          type="text"
          label="City"
          variant='outlined'
          className={classes.city}
        />

        <TextField
          type="text"
          label="State"
          helperText="2 Letter State Code (i.e. IL, CO)"
          variant='outlined'
          className={classes.state}
        />

        <TextField
          type="text"
          label="Zip Code"
          variant='outlined'
          className={classes.zip}
        />

      </div>

      <TextField
        type="text"
        label="Country"
        variant='outlined'
        className={classes.item}
      />

    </div>
  )
}

export default AddressPreviewComponent
