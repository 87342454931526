import React, { useEffect, useState, useContext, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import CustomHeader from './header.component';
import MainContext from '../state/main.context';
import GroupFormComponent from './group.form';
import GroupsComponent from './groups.component';
import DomainRequestsComponent from './domain.requests';
import ResourcesComponent from './resources.component';
import CalendarComponent from '../shared_components/calendar.component';
import UsersComponent from './users.component';
import FormsConfigComponent from './forms_components/forms.config';
import ProfileComponent from './profile.component';
import TermsPrivacyAgreement from '../agreements/termsPrivacyAgreement.component';
import { SignOut } from '../utilities/firebase.actions';
import { firebaseApp } from '../../firebase';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: theme.spacing(10)
  },
  title: {
    flexGrow: 1,
  },
  error: {
    color: 'red',
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  list: {
    marginTop: theme.spacing(10)
  },
  drawer: {
    marginTop: theme.spacing(5),
  }
}));

const OrgAdminComponent = props => {
  let { history } = props;
  const classes = useStyles();
  const context = useContext(MainContext);
  const { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(false);
  const [ menuOpen, toggleMenu ] = useState(false);
  const [ groups, setGroups ] = useState([]);
  const [ page,showPage ] = useState('Forms');
  const [ domain, setDomain ] = useState('');
  const menuOptions = [
    'Profile',
    'Manage Groups',
    'Users',
    'Forms',
    'Requests'
  ];

  useEffect(() => {
    if(!mainState.authUser) {
      setLoading(false);
      return;
    }


    //  IF USER DOES NOT HAVE A DISPLAY NAME, FORCE THEM TO UPDATE PROFILE
    if(!mainState.authUser.hasOwnProperty('displayName') || (mainState.authUser.hasOwnProperty('displayName') && !mainState.authUser.displayName) || (mainState.authUser.hasOwnProperty('displayName') && mainState.authUser.displayName.trim().length <= 0)) {
      showPage('Profile');
      alert('Please update your first and last name.');
      return;
    }

    const tempDomain = mainState.authUser.email.split('@')[1];
    const uid = mainState.authUser.uid;
    setDomain(tempDomain);

    const groupListener = firebaseApp.firestore().collection('domains').doc(tempDomain)
      .collection('groups').where('domain','==',tempDomain).onSnapshot(querySnap => {
       if(querySnap.size > 0) {
         let arr = [];
         querySnap.forEach(snap => {
           let tempData = snap.data();

           //  ASSIGN ALL GROUPS TO THE ORG-ADMIN
           if(mainState.userType == 'org-admin') {
             arr.push(snap.data());
           }

         })
         // console.log(arr);
         mainDispatch({ type: 'setGroups', payload: arr })
         setGroups(arr);
         setLoading(false);
         return;
       }
       setGroups([]);
       setLoading(false);
    });

    //  MAKE SURE THEY SIGNED THE AGREEMENT
    initCheckAgreement();

    return () => groupListener();

  },[])

  useEffect(() => {
    if(!mainState.authUser) {
      props.history.push('/login');
      return;
    }
  },[ mainState ] );


  const initCheckAgreement = async () => {

    let email = mainState.authUser.email;

    try {
      
      let agreementStatus = await firebaseApp.firestore()
      .collection('termsPrivacyAgreement').doc(email).get();

      if(!agreementStatus.exists) {
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: true });
        return;
      }

    } catch(e) {
      // statements
      console.log(e);
    }

  }

  const agreementResponse = agreed => {

    if(!agreed) {
      SignOut();
      mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      history.push('/home');
      return;
    }

    let email = mainState.authUser.email;

    firebaseApp.firestore().collection('termsPrivacyAgreement').doc(email)
      .set({
        agreed: Date.now()
      })
      .then(() => {
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      })
      .catch(err => {
        console.log(err);
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      })

  }


  const SideList = forwardRef((props,ref) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>

        {menuOptions.map((text) => (
          <div key={text}>
            <ListItem button
              ref={ref}
              onClick={() => showPage(text)}
              key={text}
              selected={page === text}
            >
              <ListItemText primary={text} />
            </ListItem>
            <Divider />
          </div>
        ))}


      </List>

    </div>
  ));

  const toggleDrawer = () => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    toggleMenu(!menuOpen)
  };

  const doToggle = () => toggleMenu(!menuOpen);

  return (
    <div className={`${classes.root} org-root`}>
      <CustomHeader
        title={'Organization Admin | ' + page }
        toggleMenu={doToggle}
      />

      <Drawer open={menuOpen} onClose={toggleDrawer()} className={classes.drawer}>
        {/*sideList()*/}
        <SideList />
      </Drawer>

      {
        page === 'Profile' && (

          <ProfileComponent />

        )
      }


      {
        page === 'Manage Groups' && (

          <GroupsComponent />

        )
      }

      {
        page === 'Users' && (

          <UsersComponent />

        )
      }

      {
        page === 'Resources' && (

          <ResourcesComponent />

        )
      }

      {
        page === 'Calendar' && (
          <CalendarComponent />
        )
      }

      {
        page === 'Forms' && (
          <FormsConfigComponent />
        )
      }

      {
        page === 'Requests' && (
          <DomainRequestsComponent
            groups={groups}
          />
        )
      }


      <TermsPrivacyAgreement
        visible={mainState.termsPrivacyAgreement}
        agreementResponse={agreementResponse}
      />
    </div>
  )
}

export default OrgAdminComponent
