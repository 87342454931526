import React, { useState } from "react";
import {
  Button,
  Select,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  InputLabel,
  Switch
} from '@material-ui/core';
import { firebaseApp } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import ExpenseHandler from './expense.handler.component';
import DateHandler from './date.handler.component';
import AddressHandler from './address.handler.component';

import checkFileType from '../../utilities/check_file_type';

const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  divCont: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
    width: '100%'
  },
  item: {
    marginTop: theme.spacing(1)
  },
  removeBtn: {
    marginLeft: theme.spacing(1)
  },
  label: {
    color: 'black'
  },
  processingLabel: {
    color: 'red',
    margin: theme.spacing(1)
  }

}))

const MyTextField = ({elem, handleTextField}) => {
  const classes = useStyles();
  return (
    <TextField
      label="Respond Here"
      multiline
      rows={5}
      defaultValue={elem.response}
      variant="outlined"
      required={elem.required}
      className={classes.item}
      onChange={e => handleTextField('textarea',elem._id,e.target.value)}
    />
  )
}

const InputsHandler = ({elem, handleResponse, toggleTyping, setUploadingFile }) => {

  const [ uploading, setUploading ] = useState(false);

  const classes = useStyles();

  const handleTextField = _.debounce((elemType,id,val) => {
    handleResponse(elemType,id,val);
  },1000);

  const uploadImageAndGetUrl = async (id, fileData, fileName, fileType) => {

    try {

      setUploading(true);
      setUploadingFile(true);

      //  GET TOKEN
      let currentUser = await firebaseApp.auth().currentUser;

      let domain = currentUser.email.split("@")[1];

      let idTokenResult = await currentUser.getIdTokenResult();

      if(idTokenResult && idTokenResult.claims.superAdmin ||
         idTokenResult && idTokenResult.claims.orgAdmin ||
         idTokenResult && idTokenResult.claims.groupAdmin ||
         idTokenResult && idTokenResult.claims.user
       ) {
         console.log("USER HAS REQUIRED TOKEN.");

         let storageRef = firebaseApp.storage().ref();
         let fileRef = storageRef.child(domain + "/" + currentUser.uid + "/" + uuidv4() + "/" + fileName);

         let fileRes = {};

         fileRef.putString(fileData, 'data_url')
         .then(function(snapshot) {
           fileRes.fullPath = snapshot.metadata.fullPath;
           console.log('Uploaded a blob or file!');
           return fileRef.getDownloadURL()
            .then( url => {

              setUploading(false);
              setUploadingFile(false);

              fileRes.fileName = fileName;
              fileRes.url = url;
              fileRes.fileType = fileType;

              //  HANDLE RESPONSE
              return handleResponse('file',id, fileRes);

            })
            .catch(err => {
              console.log(err.message)
              setUploading(false);
              setUploadingFile(false);
            });
        })
        .catch(error => {

          console.log(error.message)

          setUploading(false);
          setUploadingFile(false);
        });

         return;
       }

       console.log("USER DOES NOT HAVE REQUIRED TOKEN.");
       return;

    } catch (e) {
      console.dir(e);
      setUploading(false);
      setUploadingFile(false);
      return;
    }


  }

  const handleFileInput = (id,file) => {

    const reader = new FileReader();
    let fileName = file.name;

    if(file) {

        reader.onloadend = function () {

          console.log("Reader OnLoadEnd");

          setUploading(false);
          setUploadingFile(false);

          uploadImageAndGetUrl(id,reader.result,fileName, file.type);

        }

        setUploading(true);
        setUploadingFile(true);

        reader.readAsDataURL(file);

        return;

      }

      console.log("No file...");

      return;
  }

  switch (elem.type) {

    case 'address':
      return (
        <AddressHandler
          elem={elem}
          handleResponse={handleResponse}
          toggleTyping={toggleTyping}
        />
      )

    case 'date':
      return (
        <DateHandler elem={elem} handleResponse={handleResponse} />
      )

    case 'expense':
      return (
        <ExpenseHandler
          elem={elem}
          handleResponse={handleResponse}
          toggleTyping={toggleTyping}
        />
      )

    case 'file':
      return (
        <div className={classes.divCont}>
          <FormLabel className={classes.label}>
            {elem.title}
          </FormLabel>

          <TextField
            type="file"
            variant='outlined'
            className={classes.item}
            onChange={e => {
              let file = e.target.files[0];
              if(!checkFileType(file.type)) {
                alert("File must be PNG, JPEG or PDF.");
                e.target.value = "";
                return;
              }
              handleFileInput(elem._id,file)
            }}
          />

          <FormLabel className={classes.processingLabel}>
            {
              uploading ?
                "Processing file..."
              : null
            }
          </FormLabel>
        </div>
      )

    case "textarea":
      return (
        <div className={classes.divCont}>

          <FormLabel className={classes.label}>
            {elem.title}
          </FormLabel>

          <TextField
            label="Respond Here"
            multiline
            rows={5}
            defaultValue={elem.response}
            variant="outlined"
            required={elem.required}
            className={classes.item}
            onChange={e => {
              toggleTyping();
              handleTextField('textarea',elem._id,e.target.value)
            }}
          />

        </div>
      );

    case "text":
      return (
        <div className={classes.divCont}>

          <FormLabel className={classes.label}>{elem.title}</FormLabel>

          { elem.required ? "* Required" : null }

          <TextField
            label="Respond Here"
            id={elem._id}
            variant="outlined"
            defaultValue={elem.response}
            required={elem.required}
            className={classes.item}
            onChange={e => {
              toggleTyping();
              handleTextField('text',elem._id,e.target.value)
            }}
          />
        </div>
      );

    case "number":
      return (
        <div className={classes.divCont}>

          <FormLabel className={classes.label}>{elem.title}</FormLabel>

          <TextField
            label="Respond Here"
            id={elem._id}
            variant="outlined"
            type="number"
            required={elem.required}
            className={classes.item}
            defaultValue={elem.response}
            onChange={e => {
              toggleTyping();
              handleTextField('number',elem._id,e.target.value)
            }}
          />
        </div>
      );

    case "radio":
      return (
        <div  className={classes.divCont}>

          <FormLabel className={classes.label}>
            {elem.title}
            { elem.required ? " *" : null }
          </FormLabel>

          <FormControl component='fieldset'
            className={classes.item}>
            <RadioGroup

              name={elem._id}
              onChange={e => handleResponse('radio',elem._id,e.target.value)}
              value={elem.response}
              required={elem.required}
            >
              {
                elem.options.length > 0 && (
                  elem.options.map(option =>
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={
                        <Radio

                        />
                      }
                      label={option}
                    />
                  )
                )
              }
            </RadioGroup>
          </FormControl>

        </div>
      );

    case "checkbox":
      return (
        <div  className={classes.divCont}>

          <FormLabel className={classes.label}>
            {elem.title}
            { elem.required ? " *" : null }
          </FormLabel>

          {
            elem.options.length > 0 && (

              elem.options.map(option =>
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        name={option}
                        checked={elem.response[option]}
                        onChange={e => handleResponse('checkbox',elem._id,{key: e.target.name, val: e.target.checked})}
                      />
                    }
                    label={option}
                  />
                )

            )
          }

        </div>
      );

    case "select":
      return (
        <div  className={classes.divCont}>

          <FormLabel className={classes.label}>
            {elem.title}
            { elem.required ? " *" : null }
          </FormLabel>

          <FormControl
            className={classes.item}
            required={elem.required}
          >
            <InputLabel>Select Option</InputLabel>
            <Select
              onChange={e => handleResponse('select',elem._id,e.target.value)}
              value={elem.response}
              required={elem.required}
            >
              {
                elem.options.length > 0 && (
                  elem.options.map(option =>
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  )
                )
              }
            </Select>
          </FormControl>
        </div>
      );

    default:
      return null;
  }
};

export default InputsHandler;
