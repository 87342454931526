import React, { useState, useEffect, useContext } from 'react';
import {
  Calendar,
  momentLocalizer
} from 'react-big-calendar';
import moment from 'moment';
import MaterialTable from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Button,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography
} from '@material-ui/core';

import MainContext from '../state/main.context';
import { firebaseApp } from '../../firebase';
import BackDrop from '../backdrop.component';
import ViewRequestComponent from '../redirects/view_request.component';

const localizer = momentLocalizer(moment)

const useStyles = makeStyles(theme => ({

  root: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

 modal: {
   backgroundColor: 'white',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   padding: theme.spacing(5),
 },
 modelCont: {
   overflow: 'auto',
   backgroundColor: 'white',
   boxShadow: '5px 5px 5px 5px lightgrey',
   margin: '0 auto',
   marginTop: theme.spacing(5),
   paddingTop: theme.spacing(10)
 },
 closeBtn: {
   marginLeft: theme.spacing(1),
   position: 'fixed',
   alignSelf: 'flex-end'
 },
 detailPanel: {
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'flex-start',
   padding: theme.spacing(2)
 },
 detailItem: {
   margin: theme.spacing(1)
 }

}))

const DomainRequestsComponent = ({ groups }) => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(true);
  const [ domain, setDomain ] = useState(null);
  const [ uid, setUid ] = useState(null);
  const [ requests, setRequests ] = useState([]);
  const [ groupSelected, setGroup ] = useState('');
  const [ viewRequest, setViewRequest ] = useState(null);
  const [ showRequest, setShowRequest ] = useState(false);

  useEffect(() => {
    if(mainState.authUser){
      setDomain(mainState.authUser.email.split('@')[1]);
      setUid(mainState.authUser.uid);
      setLoading(false);
    }
  },[ mainState ])

  const handleSelection = (rowData) => {

    let approvalUrl = `/approvalPage/${rowData.domain}/${rowData._id}/${uid}`;
    let requestStatus = `/approvalPage/${rowData.domain}/${rowData._id}/${uid}`;

    window.open(approvalUrl);

  }

  const handleChange = e => {

    setLoading(true);

    let tempGroup = e.target.value;
    setGroup(tempGroup);

    //  GET FORMS FOR THE GROUP
    firebaseApp.firestore().collection('domains').doc(domain)
      .collection('formRequests').where('group','==',tempGroup.toLowerCase()).get()
      .then(querySnap => {
       if(querySnap.size > 0) {
         let arr = [];

         querySnap.forEach(snap => {
           let tempData = snap.data();
           tempData._id = snap.id;
           arr.push(tempData);

         });

         console.dir(arr);


         setRequests([...arr]);

         setLoading(false);

         return;
       }

       setRequests([]);

       setLoading(false);
    })
    .catch(err => {
      console.log('Error',err);
      setLoading(false);
    })


  }

  const columns = [
    {
      title: 'Request Date',
      field: 'requestDate',
      render: rowData => <p>{ moment(new Date(rowData.requestDate)).format('M-D-YY h:mm a') } </p>
    },
    {
      title: 'Form',
      field: 'formTitle'
    },
    {
      title: 'Requester',
      field: 'requester',
      render: rowData => <p>{ rowData.requester.displayName.toUpperCase() }</p>
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData => {
        let approvalResults = rowData.approvers.map(approver => approver.status);
        let status = '';

        console.log(approvalResults);

        if(approvalResults.includes('deny')){
          status="Denied"
        } else if(approvalResults.includes('action required')) {
          status = 'Action Required'
        } else {
          status = 'Approved';
        }

        return (
            <Button
              variant='contained'
              color={
                status === 'Denied' ?
                  'secondary'
                : status === 'Approved' ?
                  'primary'
                : 'default'
              }
              onClick={() => handleSelection(rowData)}
            >{ status }</Button>
        )
      }
    }

  ]

  return (
    <div className={classes.root}>

      <FormControl className={classes.formControl}>
        <InputLabel>Select Group</InputLabel>
        <Select
          value={groupSelected}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {
            groups.length > 0 && (
              groups.map(group =>
                <MenuItem value={group.groupName} key={group.groupName.toUpperCase()}>{group.groupName.toUpperCase()}</MenuItem>
              )
            )
          }
        </Select>
      </FormControl>

      <MaterialTable
        title="All Requests"
        data={requests}
        columns={columns}
        style={{
          width: window.innerWidth * 0.8
        }}
        detailPanel={rowData => {
          if(!rowData.administrativeInfo) {
            return(
              <div className={classes.detailPanel}>
                <Typography variant="body1" component="h1">No Additional Info</Typography>
              </div>
            )
          }

          return (
            <div className={classes.detailPanel}>
              <Typography variant="body1" component="h1">PO: {rowData.administrativeInfo.po}</Typography>
              <Typography variant="body1" component="h1">Account #: {rowData.administrativeInfo.account_number}</Typography>
              <Typography variant="body1" component="h1">Notes: {rowData.administrativeInfo.notes}</Typography>
            </div>
          )
        }}
      />

      <Modal
        className={classes.modal}
        open={showRequest}
        onClick={() => console.log('close with button') }
        BackdropProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: 'white',
            padding: '10vw'
          }
        }}
      >

        <div className={classes.modelCont}>

        <Button
          color='primary'
          variant='contained'
          onClick={() => setShowRequest(false) }
          className={classes.closeBtn}
        >
          Close
        </Button>

        {
          viewRequest && (
              <ViewRequestComponent
                requestData={viewRequest}
              />
          )
        }

        </div>

      </Modal>


      <BackDrop loading={loading} />
    </div>
  )
}

export default DomainRequestsComponent
