import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography
}  from '@material-ui/core/';
import HeaderComponent from './header.component';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5vw',
    marginTop: '10vh',
    alignItems: 'center',
    padding: '20px'
  },
  img: {
    width: 250,
    borderRadius: 5
  },
  message: {
    margin: theme.spacing(5)
  }
}));

const NoPageComponent = props => {
  const classes = useStyles();

  return (
    <Paper container className={classes.root} spacing={2}>
      <HeaderComponent title="Page Not Found" />

      <Typography variant="h3" component="h1" className={classes.message} align='center' >
        Well, this is embarrassing!
      </Typography>

      <img
        src="https://firebasestorage.googleapis.com/v0/b/manage-it-extension.appspot.com/o/manny_sad_face.jpg?alt=media&token=ace5ebce-d32b-4bc7-a425-ae8649c5e893"
        className={classes.img}
      />

      <Typography variant="subtitle" component="h1" className={classes.message} align='center' >
        Please contact support@approveit.app for assistance.
      </Typography>
    </Paper>
  )
}

export default NoPageComponent
