import React, { useEffect, useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuList,
  Popper,
  Paper,
  ClickAwayListener,
  MenuItem,
  Grow
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import MainContext from '../state/main.context';
import { SignOut } from '../utilities/firebase.actions';

const useStyles = makeStyles( theme => ({
  toolbar: {
    display: 'flex',
  },
  headerTitles: {
    flexGrow: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  mainTitle: {
    marginRight: theme.spacing(5),
    cursor: 'pointer'
  },
  loginBtn: {
    flexGrow: 0.5,
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2)
  },
  pageLocation: {
    flexGrow: '6',
    fontWeight: 'bolder'
  },
  menuButton: {
    justifyContent: 'flex-end',
    display: 'flex',
    cursor: 'pointer',
    margin: theme.spacing(2)
  }
}))


const CustomHeader = ({ title, domain }) => {
  const history = useHistory();
  const classes = useStyles();
  const context = useContext(MainContext);

  const [ showBtn, setShowBtn ] = useState(true);
  const [ pageTitle, setPageTitle ] = useState(title);
  const [ showMenu, setShowMenu ] = useState(true);
  const [ open, setOpen ] = useState(false);
  const anchorRef = React.useRef(null);

  let { mainState, mainDispatch } = context;

  const handleClick = () => {
    if(!mainState.authUser || title === 'Home') {
      history.push('/login');
      return;
    }
    mainDispatch({ type: 'reset' });
    //  LOGOUT METHOD
    history.push('/home');
    SignOut();
  }

    return (
      <AppBar position='fixed'>
        <Toolbar className={classes.toolbar}>

          <div className={classes.headerTitles}>
            <Typography
              variant='h6'
              noWrap
              className={classes.mainTitle}
              onClick={() => history.push('/home')}
            >
            { domain.toUpperCase() }  |
            </Typography>
            <Typography
              className={classes.pageLocation}
            >
              { title }
            </Typography>
          </div>

        </Toolbar>
      </AppBar>
    )
}

export default CustomHeader;
