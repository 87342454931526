import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Typography,
  Paper,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';

import MainContext from '../state/main.context';
import CustomHeader from '../header.component';
import BackDrop from '../backdrop.component';
import { SignOut } from '../utilities/firebase.actions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10vh',
    paddingBottom: '15vh'
  },
  text: {
    marginTop: '10vh'
  }
}))


const InactiveDomainComponent = props => {

  const context = useContext(MainContext);
  let { mainState } = context;
  const params = useParams();
  const classes = useStyles();

  const [ loading, setLoading ] = useState(false);
  const [ domain, setDomain ] = useState(null);
  const [ counter, setCounter ] = useState(10)


  useEffect(() => {

    if(mainState.authUser) {
      setDomain(mainState.authUser.email.split('@')[1]);
      setTimeout(() => {
        SignOut();
      },3000)
    }

  },[ mainState ]);


  return (
    <div className={classes.root} >
      <CustomHeader title='Inactive Domain' />

      <Typography className={classes.text} variant="h3">Your domain { domain ? domain : '' } is not active.</Typography>

      <Typography variant="h4">Please speak to an administrator.</Typography>

      <BackDrop loading={loading} />

    </div>
  )
}

export default InactiveDomainComponent
