import React from "react";
import {
  Button,
  Select,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  InputLabel,
  Switch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpensePreview from './expense.preview.component';
import DatePreviewComponent from './date.preview.component';
import AddressPreviewComponent from './address.preview.component';

const useStyles = makeStyles(theme => ({
  divCont: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
    width: '100%'
  },
  item: {
    marginTop: theme.spacing(1)
  },
  removeBtn: {
    marginLeft: theme.spacing(1)
  },
  label: {
    color: 'black'
  },
  file: {
    margin: theme.spacing(1)
  },
  fileNote: {
    color: 'black',
    marginTop: theme.spacing(1)
  }

}))

const InputsPreviews = ({elem}) => {
  const classes = useStyles();

  switch (elem.type) {

    case 'address':
      return (
        <AddressPreviewComponent elem={elem} />
      )

    case 'date':
      return (
        <DatePreviewComponent elem={elem} />
      )

    case 'expense':
      return (
        <ExpensePreview elem={elem} />
      )

    case "textarea":
      return (
        <div className={classes.divCont}>

          <FormLabel className={classes.label}>
            {elem.title}
          </FormLabel>



          <TextField
            label="Respond Here"
            multiline
            rows={5}
            variant="outlined"
            required={elem.required}
            className={classes.item}
          />

        </div>
      );

    case "text":
      return (
        <div className={classes.divCont}>

          <FormLabel className={classes.label}>{elem.title}</FormLabel>

          { elem.required ? "* Required" : null }

          <TextField
            label="Respond Here"
            variant="outlined"
            required={elem.required}
            className={classes.item}
          />
        </div>
      );

    case "number":
      return (
        <div className={classes.divCont}>

          <FormLabel className={classes.label}>{elem.title}</FormLabel>

          <TextField
            label="Respond Here"
            variant="outlined"
            type="number"
            required={elem.required}
            className={classes.item}
          />
        </div>
      );

    case "radio":
      return (
        <div  className={classes.divCont}>

          <FormLabel className={classes.label}>
            {elem.title}
            { elem.required ? " *" : null }
          </FormLabel>

          <FormControl component='fieldset'
            className={classes.item}>
            <RadioGroup
              name={elem._id}
            >
              {
                elem.options.length > 0 && (
                  elem.options.map(option =>
                    <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                  )
                )
              }
            </RadioGroup>
          </FormControl>

        </div>
      );

    case "checkbox":
      return (
        <div  className={classes.divCont}>

          <FormLabel className={classes.label}>
            {elem.title}
            { elem.required ? " *" : null }
          </FormLabel>

          {
            elem.options.length > 0 && (

              elem.options.map(option =>
                <FormControlLabel
                  key={option}
                  control={<Checkbox />}
                  label={option}
                />
              )

            )
          }

        </div>
      );

    case "select":
      return (
        <div  className={classes.divCont}>

          <FormLabel className={classes.label}>
            {elem.title}
            { elem.required ? " *" : null }
          </FormLabel>

          <FormControl
            className={classes.item}>
            <InputLabel>Select Option</InputLabel>
            <Select>
              {
                elem.options.length > 0 && (
                  elem.options.map(option =>
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  )
                )
              }
            </Select>
          </FormControl>
        </div>
      );

      case 'file':
        return (
          <div  className={classes.divCont}>

            <FormLabel className={classes.label}>
              {elem.title}
              { elem.required ? " *" : null }
            </FormLabel>

            <TextField
              variant="outlined"
              type="file"
              required={elem.required}
              className={classes.item}
            />

            <FormLabel className={classes.fileNote}>
              (PDF,JPEG,PNG ONLY)
            </FormLabel>

          </div>
        )

    default:
      return null;
  }
};

export default InputsPreviews;
