import React, {
  useEffect,
  useContext,
  useReducer,
  forwardRef
} from 'react';

import MainContext from './main.context';
import userReducer from '../reducers/user.reducer';
import { mainReducer, initialState } from '../reducers/main.reducer';


import { firebaseApp } from '../../firebase';
import { SignOut } from '../utilities/firebase.actions';

import { useHistory } from 'react-router-dom';

import '../../index.css';

const GlobalState = props => {

  const [ userState, userDispatch ] = useReducer(userReducer,{
    authUser: null
  });
  const [ mainState, mainDispatch ] = useReducer(mainReducer,initialState);

  //  AUTH LISTENER
  useEffect(() => {

    const authListener = firebaseApp.auth().onAuthStateChanged( user => {

      if (user) {

        mainDispatch({ type: 'setAuthUser', payload: user });

        verifyUser(user);

      } else {

        mainDispatch({ type:'setLoading', payload: false })
        mainDispatch({ type: 'setAuthUser', payload: null })
        console.log('THERE IS NO USER.');

      }

    });


      return () => authListener();
  }, [])

  const verifyUser = async (user) => {

    console.log('Verifying user...');
    let options = {
      method: 'Post',
      body: JSON.stringify(user)
    }

    let url = "https://us-central1-approve-it-5c4fc.cloudfunctions.net/checkUserClaim";

    try {

      let initCheckUser = await fetch(url,options);
      let textRes = await initCheckUser.text();

      if(initCheckUser.ok) {
        let userType = textRes.split('/')[0];
        let subscription = textRes.split('/')[1];

        mainDispatch({ type: 'setUserType', payload: userType });
        mainDispatch({ type: 'setSubscription', payload: subscription });
        mainDispatch( {type: 'setLoading', payload: false });

        return;
      }

      console.log(textRes);
      //  Sign user out
      SignOut();

    } catch (e) {

      console.log('THERE WAS AN ERROR',e);
      mainDispatch({ type: 'setLoading', payload: false })

    }

  }

  return (
    <MainContext.Provider
      value={{
        userState:userState,
        userDispatch: userDispatch,
        mainState: mainState,
        mainDispatch: mainDispatch
      }}
    >
      { props.children }
    </MainContext.Provider>
  )
}

export default GlobalState
