import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Typography,
  Paper,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import { firebaseApp } from '../../firebase';
import MainContext from '../state/main.context';
import LoginNoRedirect from '../login_no_redirect.component';

import moment from 'moment';

import CustomHeader from '../header.component';
import BackDrop from '../backdrop.component';
import ConfirmDialog from '../shared_components/confirm.dialog.component';
import ErrorDialog from '../shared_components/error.dialog.component';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10vh',
    paddingBottom: '15vh'
  },
  printRef: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    border: '1px solid grey',
    padding: theme.spacing(2)
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(5),
    width: '90vw',
    padding: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing(1),
    width: '90%',
    padding: theme.spacing(1)
  },
  formItem: {
    margin: theme.spacing(2)
  },
  approveBtn: {
    marginBottom: theme.spacing(1)
  },
  formResponse: {
    paddingLeft: theme.spacing(2)
  },
  formLabel: {
    margin: theme.spacing(1)
  },
  approver: {
    margin: theme.spacing(2)
  },
  denied: {
    color: 'red'
  },
  approved: {
    color: 'green'
  },
  default: {
    color: 'black'
  },
  hr: {
    marginRight: '60vw'
  },
  adminInfo: {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  }
}))

const RequestStatusComponent = props => {
  const printRef = useRef();
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState } = context;

  let { domain, formRequest, uid } = params;

  const [ loading, setLoading ] = useState(true);
  const [ requestData, setRequestData ] = useState(null);
  const [ confirmMessage, setConfirmMessage] = useState('');
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ showError, setShowError ] = useState(false);
  const [ approved, setApproved ] = useState(true);
  const [ comments, setComments ] = useState('');
  const [ overallStatus, setStatus ] = useState('ACTION(s) REQUIRED');


  useEffect(() => {

    if(!mainState.authUser) {
      setLoading(false);
      return;
    }

    firebaseApp.firestore()
      .collection('domains').doc(mainState.domain)
      .collection('formRequests').doc(formRequest)
      .get()
      .then(snapshot => {
        if(!snapshot.exists) {
          setLoading(false);
          alert('Form Request was not found.');
          return;
        }
        let data = snapshot.data();
        setRequestData(data);
        setLoading(false);
      }, error => {
        console.dir(error);
        setLoading(false);
        alert('Request not found.');
      })

  },[ mainState.authUser ]);

  const initShowConfirm = () => {
    setShowConfirm(true);
    setTimeout(() => setShowConfirm(false),1000);
  }

  const initShowError = () => {
    setShowError(true);
    setTimeout(() => setShowError(false),1000);
  }

  const RenderRequestData = () => (
    <div className={classes.paper}>

      {
        (requestData.hasOwnProperty('administrativeInfo') &&
          Object.keys(requestData.administrativeInfo).length > 0) && (
            <div className={classes.adminInfo}>
              <Typography variant='h6' align='center'>Administrative</Typography>
            {
              Object.keys(requestData.administrativeInfo).map(key => {

                return (
                  <Typography key={key}>
                    <b>{ key.toUpperCase()}:</b> {requestData.administrativeInfo[key] }
                  </Typography>
                )

              })
            }
            </div>

          )
      }

      <Typography variant='h4' style={{ textAlign: 'center' }}>
        {requestData.formTitle}
      </Typography>

      <Typography variant='h5' style={{ textAlign: 'center' }}>
        by {requestData.requester.displayName}
      </Typography>

      <div
        className={classes.formItem}
      >
        <Typography variant="h6">Request Date</Typography>
        <Typography
          className={classes.formResponse}
        >{moment(new Date(requestData.requestDate)).format("dddd, MMMM Do YYYY, h:mm a")}</Typography>

      </div>

      {
        requestData.form.map(item => {
          switch(item.type) {
            case 'textarea':
            case 'text':
            case 'number':
            case 'radio':
            case 'select': {
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >{item.response}</Typography>

                </div>
              )
            }

            case 'file':
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <a href={item.response.url} target ="_blank">
                    <Typography
                      className={classes.formResponse}
                    >
                    FILE
                    </Typography>
                  </a>

                </div>
              )

            case 'checkbox': {
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >
                    {
                      Object.keys(item.response).filter(key => item.response[key]).join(', ')
                    }
                  </Typography>

                </div>
              )
            }

            case 'date':{
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >Start: {moment(new Date(item.start)).format("dddd, MMM Do YYYY, h:mm a")}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >End: {moment(new Date(item.end)).format("dddd, MMM Do YYYY, h:mm a")}</Typography>

                </div>
              )
            }

            case 'address':{
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >{item.response.location}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >{item.response.street}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >{item.response.city}, {item.response.state} {item.response.zip}</Typography>

                </div>
              )
            }

            case 'expense':{
              return (
                <div key={item._id}
                  className={classes.formItem}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography
                    className={classes.formResponse}
                  >Quantity: {item.response.quantity}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >Cost: ${item.response.cost}</Typography>

                  <Typography
                    className={classes.formResponse}
                  >Total: ${item.response.total}</Typography>

                </div>
              )
            }
          }

        })

      }
    </div>
  )

  const columns = [
    {
      title: 'Admin',
      field: 'displayName'
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData => <p>{ rowData.status.toUpperCase() }</p>
    },
    {
      title: 'Comments',
      field: 'comment',
      render: rowData => <p>{

        (rowData.hasOwnProperty('comment')) ?
          rowData.comment.toUpperCase()
        : ''

      }</p>
    },
    {
      title: 'Action Date',
      field: 'actionDate',
      render: rowData => <p>{
        (rowData.hasOwnProperty('actionDate') && rowData.actionDate) ?
            moment(rowData.actionDate).format("M-D-YY h:mm a")
          : ''
      }</p>
    }

  ];

  if(!mainState.authUser) {
    return(
      <LoginNoRedirect />
    )
  }

  return (
    <div className={classes.root} ref={printRef}>
      <CustomHeader title='Request Approval' />

    <div
      className={classes.printRef}

    >

    <ReactToPrint
      trigger={() =>
        <PrintIcon
          style={{
            marginTop: '25px',
            marginBottom: '25px',
            right: '25px',
            cursor: 'pointer'
          }}
        />
      }
      content={ () => printRef.current }
    />

    <MaterialTable
      title="Request Status"
      data={requestData ? requestData.approvers : []}
      columns={columns}
      style={{
        width: '90%'
      }}
      pageSize={requestData ? requestData.approvers.length : 5}
      options={{
        search: false,
        showFirstLastPageButtons: false,
        paging: false
      }}
    />

    {
      requestData && (
        <RenderRequestData />
      )
    }

    <BackDrop loading={loading} />
    </div>

    </div>
  )

}

export default RequestStatusComponent
